import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { additionalUrlParams, getUrlParam } from "../../utils";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { openBankingHandShakeInvoke, updateCase } from "../../api/crm";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { SmallWidthColumnContainer } from "../../components/Containers";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";

const OpenBanking2 = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const { CustomerDetails = {} } = useCaseInfoContext();

  const handleSubmit = (values: any) => {
    var windowReference: any = window.open();
    if (!CustomerDetails.ApplicantID && getUrlParam("ApplicantID")) {
      CustomerDetails.ApplicantID = getUrlParam("ApplicantID");
      CustomerDetails.CustomerReference = getUrlParam("CustomerReference");
    }

    openBankingHandShakeInvoke({
      pRequest: {
        CustomerCode: CustomerDetails?.CustomerReference,
        // CustomerCode: "C000016", //! For Testing if no any CustomerReference
        BankCode: "",
        //! contstant added in ApplicantNumber
        ApplicantNumber: "0",
      },
    })
      .then(async (responseData: any) => {
        if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          const updatedCase = await updateCase({
            ApplicantID: CustomerDetails?.ApplicantID,
            CustomerReference: CustomerDetails?.CustomerReference,
            Payload: {
              OpenBankingVisionId:
                responseData?.data?.d?.Records[0]?.OpenBankingSearchID,
              CaseStatus: "ADP Checks",
              DynamicFieldDetail: [
                {
                  FieldNo: 22,
                  FieldValue: responseData?.data?.d?.Records[0]?.OpenBankingId,
                },
              ],
            },
          });

          if (!updatedCase.data) return;

          const URL = responseData?.data?.d?.Records[0]?.ClientUrl;
          if (URL) {
            // window.open(URL, "_blank");
            windowReference.location = URL;
            // return navigate("/home");
            return navigate("/open-banking3" + additionalUrlParams(), {
              state: {
                OpenBankingID: responseData?.data?.d?.Records[0]?.OpenBankingId,
              },
            });
          }
        } else {
          return setToastMessage(
            responseData?.data?.d?.EnquiryResult?.Message ||
              "Something went wrong"
          );
        }
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        )
      );
  };

  return (
    <ImageLayoutContainer
      noPadding
      title="OPEN BANKING"
      handleClick={handleSubmit}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!w-5/6" sx={{ fontSize: "12px", mt: 6 }}>
          Connect securely to your primary current account. We can access
          hundreds of providers
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default OpenBanking2;
