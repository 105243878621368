import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomChart from "../../components/CustomChart/CustomChart";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { useFormik } from "formik";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import Stack from "@mui/material/Stack";
import { getADPData } from "../../api/crm";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";

const data = [
  {
    label: "Series 1",
    data: [
      {
        primary: "Jan",
        months: 13,
      },
      {
        primary: "Feb",
        months: 24,
      },
      {
        primary: "Mar",
        months: 28,
      },
      {
        primary: "Apr",
        months: 27,
      },
      {
        primary: "May",
        months: 24,
      },
      {
        primary: "Jun",
        months: 20,
      },
      {
        primary: "Jul",
        months: 22,
      },
      {
        primary: "Aug",
        months: 30,
      },
      {
        primary: "Sep",
        months: 37,
      },
      {
        primary: "Oct",
        months: 39,
      },
      {
        primary: "Nov",
        months: 37,
      },
      {
        primary: "Dec",
        months: 33,
      },
    ],
  },
  {
    label: "Series 2",
    data: [
      {
        primary: "Jan",
        months: 15,
      },
      {
        primary: "Feb",
        months: 26,
      },
      {
        primary: "Mar",
        months: 30,
      },
      {
        primary: "Apr",
        months: 29,
      },
      {
        primary: "May",
        months: 26,
      },
      {
        primary: "Jun",
        months: 22,
      },
      {
        primary: "Jul",
        months: 24,
      },
      {
        primary: "Aug",
        months: 32,
      },
      {
        primary: "Sep",
        months: 39,
      },
      {
        primary: "Oct",
        months: 41,
      },
      {
        primary: "Nov",
        months: 39,
      },
      {
        primary: "Dec",
        months: 37,
      },
    ],
  },
  {
    label: "Series 3",
    data: [
      {
        primary: "Jan",
        months: 17,
      },
      {
        primary: "Feb",
        months: 28,
      },
      {
        primary: "Mar",
        months: 32,
      },
      {
        primary: "Apr",
        months: 31,
      },
      {
        primary: "May",
        months: 28,
      },
      {
        primary: "Jun",
        months: 24,
      },
      {
        primary: "Jul",
        months: 26,
      },
      {
        primary: "Aug",
        months: 34,
      },
      {
        primary: "Sep",
        months: 41,
      },
      {
        primary: "Oct",
        months: 43,
      },
      {
        primary: "Nov",
        months: 41,
      },
      {
        primary: "Dec",
        months: 39,
      },
    ],
  },
];

const FlexExplainedGraph = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const { CaseInfo = {}, ...caseStateInfo } = useCaseInfoContext();
  const formik = useFormik({
    initialValues: {
      amount: 177.1,
    },
    onSubmit: (values) => {},
  });
  const handleSubmit = (values: any) => {
    navigate("/important-info");
  };

  const fetchADPData = React.useCallback(
    () =>
      getADPData({
        CaseReference: caseStateInfo?.CustomerDetails?.ApplicantID,
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
            console.log("ADPDataList", responseData?.data?.d?.ADPDataList);
          } else {
            setToastMessage(
              responseData?.data?.d?.EnquiryResult?.Message ||
                "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        ),
    [caseStateInfo?.CustomerDetails?.ApplicantID]
  );

  React.useEffect(() => {
    fetchADPData();
  }, [CaseInfo.ApplicantID, fetchADPData]);

  return (
    <ImageLayoutContainer
      noPadding
      title="FLEX EXPLAINED"
      handleClick={handleSubmit}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />

      <Stack
        direction="column"
        flex="1"
        justifyContent="center"
        width="100%"
        sx={{ padding: "40px 22px 0 22px" }}
      >
        <Stack
          direction="row"
          className="!mt-12"
          justifyContent="space-between"
        >
          <Typography color={(theme) => theme.palette.primary.main}>
            Loan Repayments
          </Typography>
          <Typography color={(theme) => theme.palette.secondary.main}>
            36 Months
          </Typography>
        </Stack>
        <CustomChart data={data} />
        <CustomSlider
          className="mt-9 !self-center !justify-self-center"
          marks={[
            { value: 100, label: "£100" },
            { value: 180, label: "£180" },
          ]}
          min={100}
          max={180}
          step={0.1}
          defaultValue={formik.values.amount}
          valueLabelFormat={(value: number) => `£${value}`}
          id="amount"
          name="amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Stack>
    </ImageLayoutContainer>
  );
};

export default FlexExplainedGraph;
