import React from "react";
import { Typography } from "@mui/material";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import { useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import IsEmptyOrValueUpdated, {additionalUrlParams, getUrlParam} from "../../utils";
import { updateCase } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { FormikHelpers } from "formik";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";

const Finances1 = () => {
  const navigate = useNavigate();
  const { Finances1Fields = {}, ...caseInfoState } = useCaseInfoContext();
  // const {
  //   AnnualIncome = "",
  //   DoAnnualIncomeWillChange = "",
  //   HouseIncome = "",
  //   LoanReason = "",
  //   FurtherDetails = "",
  //   Consolidation = "",
  // } = Finances1Fields;
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [AnnualIncome, setAnnualIncome] = React.useState("");
  const [DoAnnualIncomeWillChange, setDoAnnualIncomeWillChange] = React.useState("");
  const [HouseIncome, setHouseIncome] = React.useState("");
  const [LoanReason, setLoanReason] = React.useState("");
  const [FurtherDetails, setFurtherDetails] = React.useState("");
  const [Consolidation, setConsolidation] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");
  const [showOtherField, setShowOtherField] = React.useState(false);
  const [showConsolidationField, setShowConsolidationField] =
    React.useState(false);

  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => {
    const { setErrors } = formikHelpers;
    if (values?.LoanReason === "Debt Consolidation" && !values?.Consolidation) {
      return setErrors({
        Consolidation: "This field is required",
      });
    }
    if (values?.LoanReason === "Other" && !values?.FurtherDetails) {
      return setErrors({
        FurtherDetails: "This field is required",
      });
    }
    if (IsEmptyOrValueUpdated(Finances1Fields, values))
      updateCase({
        ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
        CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
        Payload: {
          WorkflowStep: "2.6",
          CaseStatus: "Application in Progress",
          DynamicFieldDetail: [
            {
              FieldNo: 19,
              FieldValue: values?.LoanReason,
            },
            {
              FieldNo: 20,
              FieldValue:
                values?.LoanReason !== "Debt Consolidation"
                  ? ""
                  : values?.FurtherDetails,
            },
            {
              FieldNo: 21,
              FieldValue:
                values?.LoanReason !== "Other" ? "" : values?.Consolidation,
            },
          ],
          MonthlyIncome: [
            {
              Code: null,
              TypeCode: "Income",
              BasisCode: null,
              Amount: values?.HouseIncome.toString(),
              Description: values?.DoAnnualIncomeWillChange,
              Client: "1st Client",
              MonthlyAmount: values?.AnnualIncome.toString(),
              CurrentValue: null,
              OutstandingBalance: null,
            },
          ],
        },
      })
        .then((responseData: any) => {
          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.ADD_FINANCES1,
              payload: {
                ...values,
                Consolidation:
                  values?.LoanReason !== "Other" ? "" : values?.Consolidation,
                FurtherDetails:
                  values?.LoanReason !== "Debt Consolidation"
                    ? ""
                    : values?.FurtherDetails,
              },
            });
            navigate("/accept-initial-data-terms" + additionalUrlParams());
          } else {
            setToastMessage(
              responseData?.data?.d?.UpdateResult?.Message ||
                "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.UpdateResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
    navigate("/accept-initial-data-terms" + additionalUrlParams());
  };
  const textFields = [
    {
      fieldName: "AnnualIncome",
      defaultValue: AnnualIncome,
      label: "Annual Income",
      type: "amount-input",
      additionalElement: (
        <>
          <Typography
            className="!w-3/4 md:!w-4/5"
            sx={{ fontSize: "12px", mb: 2 }}
          >
            What is your current personal annual income (before tax)?
          </Typography>
        </>
      ),
      customValidation: getYup
        .string()
        .required("This field is required")
        .test(
          "AnnualIncome",
          "The Annual Income must be 0 or greater than 0.",
          (value: any) => Number(value) >= 0
        )
        .test(
          "AnnualIncome",
          "The Annual Income can not start with 0 unless the income is exactly £0.",
          (value: any) =>
            Number(value) === 0 || (Number(value) > 0 && value[0] !== "0")
        )
        .test(
          "AnnualIncome",
          "Please check the amount entered. If you are still experiencing problems, please contact our service team at: 0800 8021466",
          (value: any) => Number(value) <= 200000
        ),
    },
    {
      fieldName: "DoAnnualIncomeWillChange",
      defaultValue: DoAnnualIncomeWillChange,
      label: "Yes/No",
      type: "select",
      options: [
        {
          value: "Yes",
          title: "Yes",
        },
        {
          value: "No",
          title: "No",
        },
      ],
      additionalElement: (
        <>
          <Typography
            className="!w-3/4 md:!w-4/5"
            sx={{ fontSize: "12px", mb: 2 }}
          >
            Do you have any reason to believe there will be a reduction in your income over the life of your loan?
          </Typography>
        </>
      ),
      customValidation: getYup.string().required("This field is required"),
    },
    {
      fieldName: "HouseIncome",
      defaultValue: HouseIncome,
      label: "Household Income",
      type: "amount-input",
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            What is your current household annual income (before tax)?
          </Typography>
        </>
      ),
      customValidation: getYup
        .string()
        .required("This field is required")
        .test(
          "HouseIncome",
          "The House Income must be 0 or greater than 0.",
          (value: any) => Number(value) >= 0
        )
        .test(
          "HouseIncome",
          "The House Income can not start with 0 unless the income is exactly £0.",
          (value: any) =>
            Number(value) === 0 || (Number(value) > 0 && value[0] !== "0")
        )
        .test(
          "HouseIncome",
          "Please check the amount entered. If you are still experiencing problems, please contact our service team at: 0800 8021466",
          (value: any) => Number(value) <= 300000
        ),
    },
    {
      fieldName: "LoanReason",
      defaultValue: LoanReason,
      label: "Loan Reason",
      type: "select",
      options: [
        {
          value: "Car Purchase",
          title: "Car Purchase",
        },
        {
          value: "Home Improvements",
          title: "Home Improvements",
        },
        {
          value: "Holiday",
          title: "Holiday",
        },
        {
          value: "Wedding",
          title: "Wedding",
        },
        {
          value: "Debt Consolidation",
          title: "Debt Consolidation",
        },
        {
          value: "Other",
          title: "Other",
        },
      ],
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            We know there are lots of reasons to take out a loan. Can you
            describe what you are looking to do
          </Typography>
        </>
      ),
      customChangeHandler: (e: any) => {
        if (e?.target?.value === "Debt Consolidation") {
          if (showOtherField) setShowOtherField(false);
          return setShowConsolidationField(true);
        } else if (e?.target?.value === "Other") {
          if (showConsolidationField) setShowConsolidationField(false);
          return setShowOtherField(true);
        } else {
          if (showOtherField) return setShowOtherField(false);
          if (showConsolidationField) return setShowConsolidationField(false);
        }
      },
    },
    {
      fieldName: "FurtherDetails",
      defaultValue: FurtherDetails,
      label: "Further Details",
      type: "textarea",
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Please provide further details
          </Typography>
        </>
      ),
      notValidate: true,
      visible: showOtherField,
    },
    {
      fieldName: "Consolidation",
      label: "Debt Consolidation",
      defaultValue: Consolidation,
      type: "select",
      options: [
        {
          value: "A high cost / short term loan",
          title: "A high cost / short term loan",
        },
        {
          value: "General Credit Card Debt",
          title: "General Credit Card Debt",
        },
        {
          value: "Other Unsecured",
          title: "Other Unsecured",
        },
      ],
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Please select the main type of debt you wish to consolidate
          </Typography>
        </>
      ),
      notValidate: true,
      visible: showConsolidationField,
    },
  ];


  const { isLoading } = useFetchingInitialData();

  React.useEffect(() => {
      console.log(caseInfoState);
        //   AnnualIncome = "",
        //   DoAnnualIncomeWillChange = "",
        //   HouseIncome = "",
        //   LoanReason = "",
        //   FurtherDetails = "",
        //   Consolidation = "",

      // determine annual income
      let _AnnualIncome = "";
      if(typeof caseInfoState?.CaseList !== 'undefined') {
          for (let i = 0; i < caseInfoState?.CaseList[0]?.MonthlyIncome.length; i++) {
              if (caseInfoState?.CaseList[0]?.MonthlyIncome[i]['TypeCode']==="Income") {
                _AnnualIncome = caseInfoState?.CaseList[0]?.MonthlyIncome[i]['MonthlyAmount'];
                  // break;
              }
          }
      }

      // determine income change
            let _DoAnnualIncomeWillChange = "";
            if(typeof caseInfoState?.CaseList !== 'undefined') {
                for (let i = 0; i < caseInfoState?.CaseList[0]?.MonthlyIncome.length; i++) {
                    if (caseInfoState?.CaseList[0]?.MonthlyIncome[i]['TypeCode']==="Income") {
                      _DoAnnualIncomeWillChange = caseInfoState?.CaseList[0]?.MonthlyIncome[i]['Description'];
                        // break;
                    }
                }
            }

      // determine house income
      let _HouseIncome = "";
      if(typeof caseInfoState.CaseList !== 'undefined') {
          for (let i = 0; i < caseInfoState?.CaseList[0]?.MonthlyIncome.length; i++) {
              if (caseInfoState?.CaseList[0]?.MonthlyIncome[i]['TypeCode']==="Income") {
                  _HouseIncome = caseInfoState?.CaseList[0]?.MonthlyIncome[i]['Amount'];
                //  break;
              }
          }
      }

      // determine loan reason
      let _LoanReason = "";
      if(typeof caseInfoState.CaseList !== 'undefined') {
          for (let i = 0; i < caseInfoState?.CaseList[0]?.DynamicFieldDetail.length; i++) {
              if (caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldNo']==="19") {
                  _LoanReason = caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldValue'];
                  break;
              }
          }
      }

      // determine furtherdetails reason
            let _FurtherDetails = "";
            if(typeof caseInfoState?.CaseList !== 'undefined') {
                for (let i = 0; i < caseInfoState?.CaseList[0]?.DynamicFieldDetail.length; i++) {
                    if (caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldNo']==="20") {
                      _FurtherDetails = caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldValue'];
                        break;
                    }
                }
            }

            // determine consolidation reason
            let _Consolidation = "";
            if(typeof caseInfoState?.CaseList !== 'undefined') {
                for (let i = 0; i < caseInfoState?.CaseList[0]?.DynamicFieldDetail.length; i++) {
                    if (caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldNo']==="23") {
                      _Consolidation = caseInfoState?.CaseList[0]?.DynamicFieldDetail[i]['FieldValue'];
                        break;
                    }
                }
            }

      setAnnualIncome(_AnnualIncome || "");
      setDoAnnualIncomeWillChange(_DoAnnualIncomeWillChange|| "");
      setHouseIncome(_HouseIncome || "");
      setLoanReason(_LoanReason || "");
      setFurtherDetails(_FurtherDetails || "");
      setConsolidation(_Consolidation || "");
  }, [caseInfoState]);


  return (
    <ImageLayoutContainer title="Your Finances" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
    </ImageLayoutContainer>
  );
};

export default Finances1;
