import React from "react";
import { Typography, Button } from "@mui/material";
import DirectDebit from "../../assets/images/Direct Debit.png";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import CenterColumnContainer, {
  SmallWidthColumnContainer,
} from "../../components/Containers";
import Stack from "@mui/material/Stack/Stack";

const UpdateDetails2 = () => {
  return (
    <CenterLayoutContainer pageTitle="PAYMENT DETAILS">
      <CenterColumnContainer flex="1">
        <SmallWidthColumnContainer flex="1">
          <Typography className="!text-xs !mb-6 !mt-6">
            PAYMENT REQUESTED: £109,00
          </Typography>
          <Typography className="!text-xs">
            Statement Balance: £6,400.00
            <br />
            Payment Date: 29th Sepetember 2022
          </Typography>
          <Stack
            direction="column"
            flex="1"
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="Direct Debit"
              src={DirectDebit}
              style={{ objectFit: "contain", width: "164px", height: "164px" }}
            />
          </Stack>
        </SmallWidthColumnContainer>
      </CenterColumnContainer>
      <Button
        className="!mb-3"
        variant="contained"
        sx={{
          width: { sm: 248, xs: "100%" },
          justifySelf: "flex-end",
          alignSelf: "center",
        }}
      >
        MAKE A PAYMENT
      </Button>
      <Button
        variant="contained"
        sx={{
          width: { sm: 248, xs: "100%" },
          justifySelf: "flex-end",
          alignSelf: "center",
        }}
      >
        PAYMENT CHANGES
      </Button>
      <Typography className="!text-xs !mt-6 !text-center">
        PRIVACY POLICY
      </Typography>
    </CenterLayoutContainer>
  );
};

export default UpdateDetails2;
