import React from "react";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import { useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import {
  updateCase,
  updateCustomer,
  openBankingHandShakeInvoke,
} from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import IsEmptyOrValueUpdated, {
  additionalUrlParams,
  getUrlParam,
} from "../../utils";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { Typography } from "@mui/material";
import moment from "moment";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";

const BankDetails2 = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const { CustomerDetails = {} } = useCaseInfoContext();
  const { BankDetailsFields = {}, ...caseInfoState } = useCaseInfoContext();
  // const {
  //   AccountName = "",
  //   AccountNumber = "",
  //   SortCode = "",
  // } = BankDetailsFields;
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [AccountName, setAccountName] = React.useState("");
  const [AccountNumber, setAccountNumber] = React.useState("");
  const [SortCode, setSortCode] = React.useState("");

  const handleSubmit = async (values: any) => {
    var windowReference: any = window.open();
    if (IsEmptyOrValueUpdated(BankDetailsFields, values)) {
      // Update Customer details before updating the case.
      const updatedCustomer = await updateCustomer({
        IsUpdateCaseCustomer: true,
        CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference,
        BankDetails: [
          {
            ...values,
            BankName: "Bank",
          },
        ],
        Email: "",
        Mobile: "",
      });

      // If no user data updated return error
      if (!updatedCustomer.data) return;
      updateCase({
        ApplicantID:
          caseInfoState?.CustomerDetails?.ApplicantID ??
          getUrlParam("ApplicantID"),
        CustomerReference:
          caseInfoState?.CustomerDetails?.CustomerReference ??
          getUrlParam("CustomerReference"),
        Payload: {
          MainApplicant: {
            BankDetail: [
              {
                ...values,
                BankName: null,
              },
            ],
          },
        },
      })
        .then((responseData: any) => {
          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.ADD_BANK_DETAILS,
              payload: values,
            });
            openBankingHandShakeInvoke({
              pRequest: {
                CustomerCode: CustomerDetails?.CustomerReference,
                // CustomerCode: "C000016", //! For Testing if no any CustomerReference
                BankCode: "",
                //! contstant added in ApplicantNumber
                ApplicantNumber: "0",
              },
            })
              .then(async (responseData: any) => {
                if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
                  const updatedCase = await updateCase({
                    ApplicantID:
                      CustomerDetails?.ApplicantID ??
                      getUrlParam("ApplicantID"),
                    CustomerReference:
                      CustomerDetails?.CustomerReference ??
                      getUrlParam("CustomerReference"),
                    Payload: {
                      OpenBankingVisionId:
                        responseData?.data?.d?.Records[0]?.OpenBankingSearchID,
                      CaseStatus: "ADP Checks",
                      DynamicFieldDetail: [
                        {
                          FieldNo: 22,
                          FieldValue:
                            responseData?.data?.d?.Records[0]?.OpenBankingId,
                        },
                      ],
                    },
                  });

                  if (!updatedCase.data) return;

                  const URL = responseData?.data?.d?.Records[0]?.ClientUrl;
                  if (URL) {
                    // window.open(URL);
                    windowReference.location = URL;
                    // return navigate("/home");
                    return navigate("/open-banking3" + additionalUrlParams(), {
                      state: {
                        OpenBankingID:
                          responseData?.data?.d?.Records[0]?.OpenBankingId,
                      },
                    });
                  }
                } else {
                  return setToastMessage(
                    responseData?.data?.d?.EnquiryResult?.Message ||
                      "Something went wrong"
                  );
                }
              })
              .catch((err) =>
                setToastMessage(
                  err?.data?.d?.EnquiryResult?.Message ||
                    err?.message ||
                    "Something went wrong"
                )
              );
          } else {
            setToastMessage(
              responseData?.data?.d?.UpdateResult?.Message ||
                "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.UpdateResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
    } else navigate("/open-banking3" + additionalUrlParams());
  };

  const textFields = [
    {
      fieldName: "AccountName",
      label: "Account Name",
      type: "text",
      defaultValue: AccountName,
      customValidation: getYup
        .string()
        .matches(/^[a-zA-Z& \-]+$/, "Please enter valid account name")
        .required("This field is required"),
    },
    {
      fieldName: "AccountNumber",
      label: "Account Number",
      type: "number",
      defaultValue: AccountNumber,
      customValidation: getYup
        .string()
        .required("This field is required")
        .matches(/^[0-9]+$/, "Please enter valid account number")
        .min(8, "Account number must have 8 digits")
        .max(8, "Account number must have 8 digits"),
    },
    {
      fieldName: "SortCode",
      label: "Sort Code",
      type: "number",
      defaultValue: SortCode,
      customValidation: getYup
        .string()
        .required("This field is required")
        .matches(/^[0-9]+$/, "Please enter valid sort code")
        .min(6, "Sort code must have exactly 6 digits")
        .max(6, "Sort code must have exactly 6 digits"),
    },
  ];

  const { isLoading } = useFetchingInitialData();

  React.useEffect(() => {
    getUrlParam("a");
  }, []);

  React.useEffect(() => {
    console.log(caseInfoState);

    setAccountName(
      caseInfoState?.CaseList?.[0]?.MainApplicant?.BankDetail?.[0]
        ?.AccountName || ""
    );
    setAccountNumber(
      caseInfoState?.CaseList?.[0]?.MainApplicant?.BankDetail?.[0]
        ?.AccountNumber || ""
    );
    setSortCode(
      caseInfoState?.CaseList?.[0]?.MainApplicant?.BankDetail?.[0]?.SortCode ||
        ""
    );
  }, [caseInfoState]);

  return (
    <ImageLayoutContainer noPadding addLogoIcon>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
        hideNeedHelpBtn
        additionalText={
          // <>
          //   <Typography className="w-4/5" sx={{ fontSize: "12px", mb: 2 }}>
          //     Please authorise open banking connection to this account.
          //   </Typography>
          //   <Typography sx={{ fontSize: "12px", mb: 2 }}>
          //     This is the account into which you typically get paid and your
          //     principle bills are paid out of.
          //   </Typography>
          //   <Typography sx={{ fontSize: "12px", mb: 2 }}>
          //     Open Banking regulations require connections to be authorised
          //     every 90 Days.
          //   </Typography>
          //   <Typography sx={{ fontSize: "12px", mb: 2 }}>
          //     Continuing from this page will take you to your provider to
          //     complete authorisation.
          //   </Typography>
          //   <Typography sx={{ fontSize: "12px", mb: 2 }}>
          //     If you are successful, this is the account we will pay the loan
          //     amount in to.
          //   </Typography>
          // </>
          <>
            <Typography
              className="w-4/5"
              sx={{ fontSize: "14px", fontWeight: "800", mb: 1 }}
            >
              Great News!
            </Typography>
            <Typography sx={{ fontSize: "12px", mb: 2 }}>
              Custom Credit is here to help get the loan you've been looking
              for.
            </Typography>
            <Typography sx={{ fontSize: "12px", mb: 2 }}>
              To ensure you receive our best price and to complete our
              affordability checks swiftly, we just need your Open Banking data.
            </Typography>
          </>
        }
      />
    </ImageLayoutContainer>
  );
};

export default BankDetails2;
