import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { additionalUrlParams } from "../../utils";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const PreLoanText = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/loan-details" + additionalUrlParams());
  };
  return (
    <ImageLayoutContainer noPadding title="Pre Loan" handleClick={handleSubmit}>
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!text-xs !w-5/6 !mt-12">
          We’ve used the information provided and your open banking data to
          calculate the cost of your loan and the range of your flex payments
        </Typography>
        <Typography className="!text-xs !w-5/6 !mt-4">
          Over the next few steps we’ll show you how we’ve calculated this and
          give you some options to ensure your Custom Credit
          loan is set up to your individual needs
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default PreLoanText;
