import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MonthTabs from "../../components/MonthTabs/MonthTabs";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { Stack } from "@mui/system";
import BackButton from "../../components/BackButton/BackButton";
import { getCase, getFinancialTransactions } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import {
  currencyFormat,
  findNextPaymentDate,
  getEligibilityForHolidays,
  getStartDateAndEndDate,
} from "../../utils";

const CaseScreen = () => {
  const navigate = useNavigate();
  const { id: caseId } = useParams();
  const [toastMessage, setToastMessage] = React.useState("");
  const { CustomerDetails = {} } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const fetchCaseData = React.useCallback(
    (caseId: string) => {
      if (!CustomerDetails?.[CustomerDetails?.ApplicantID] && caseId)
        getCase({
          CaseReference: caseId,
        })
          .then((response) => {
            if (Object.keys(response?.data?.d?.CaseApplicant).length)
              getFinancialTransactions({
                Reference: caseId,
                TransactionType: "Sales-Payment",
              })
                .then((responseData) => {
                  if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0) {
                    const NextPaymentDate = findNextPaymentDate(
                      response?.data?.d?.CaseApplicant?.PaymentPlanDetail
                        ?.PaymentPlanDetails
                    );
                    return caseInfoDispatch({
                      type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                      payload: {
                        ApplicantID:
                          response?.data?.d?.CaseApplicant?.ApplicantID,
                        CustomerReference:
                          response?.data?.d?.CaseApplicant?.CustomerReference,
                        [response?.data?.d?.CaseApplicant?.ApplicantID]: {
                          ...response?.data?.d?.CaseApplicant,
                          IsEligibleForHolidays: getEligibilityForHolidays(
                            response?.data?.d?.CaseApplicant
                          ),
                          NextPaymentDate,
                          Transactions:
                            responseData?.data?.d?.Records[0]?.Transactions,
                        },
                      },
                    });
                  }
                })
                .catch((err) => {
                  setToastMessage(
                    err?.data?.d?.EnquiryResult?.Message ||
                      err?.message ||
                      "Something went wrong"
                  );
                });
          })
          .catch((err) => {
            setToastMessage(
              err?.data?.d?.EnquiryResult?.Message ||
                err?.message ||
                "Something went wrong"
            );
          });
      else {
        if (CustomerDetails?.ApplicantID)
          getFinancialTransactions({
            Reference: caseId,
            TransactionType: "Sales-Payment",
          })
            .then((responseData) => {
              if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
                return caseInfoDispatch({
                  type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                  payload: {
                    [CustomerDetails?.ApplicantID]: {
                      ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                      Transactions:
                        responseData?.data?.d?.Records[0]?.Transactions,
                    },
                  },
                });
            })
            .catch((err) => {
              setToastMessage(
                err?.data?.d?.EnquiryResult?.Message ||
                  err?.message ||
                  "Something went wrong"
              );
            });
      }
    },
    [CustomerDetails, caseInfoDispatch]
  );

  React.useEffect(() => {
    if (caseId) fetchCaseData(caseId);
  }, [caseId]);

  const getDataForSelectedMonth = (month: string) => {
    const { startDate = "", endDate = "" } = getStartDateAndEndDate(month);
    if (caseId)
      getFinancialTransactions({
        Reference: caseId,
        TransactionType: "Sales-Payment",
        DateFrom: startDate || "",
        DateTo: endDate || "",
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
            return caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
              payload: {
                [CustomerDetails?.ApplicantID]: {
                  ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  Transactions: responseData?.data?.d?.Records[0]?.Transactions,
                },
              },
            });
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };

  return (
    <Stack direction="column" flex="1">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <Stack direction="row" gap={2}>
        <BackButton className="!mb-4 " />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap="8px"
        >
          <Typography
            variant="h6"
            color={(theme) => theme.palette.primary.main}
          >
            {caseId}
          </Typography>
        </Box>
      </Stack>
      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        Balance
      </Typography>
      <Typography
        sx={{ fontSize: "32px" }}
        color={(theme) => theme.palette.primary.light}
      >
        {currencyFormat(
          CustomerDetails?.[CustomerDetails?.ApplicantID]?.TotalRepaymentAmount
        )}
      </Typography>
      {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate ? (
        <Stack gap={1} direction="row" alignItems="center" className="!mt-6">
          <Typography className="!text-sm">Next Payment Date</Typography>
          <Typography
            className="!text-sm"
            color={(theme) => theme.palette.secondary.main}
          >
            {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate}
          </Typography>
        </Stack>
      ) : (
        " "
      )}
      <Typography
        className="!text-sm !mt-6"
        // sx={{ fontFamily: "Earth Orbiter Bold" }}
      >
        Past Payments
      </Typography>
      {/* <Box display="flex"> */}
      <MonthTabs
        transactions={
          CustomerDetails?.[CustomerDetails?.ApplicantID]?.Transactions
        }
        getDataForSelectedMonth={getDataForSelectedMonth}
      />
      {/* </Box> */}
      <Stack
        direction="column"
        flex="1"
        justifyContent="flex-end"
        flexWrap="wrap"
        alignItems={{ sm: "flex-start", xs: "center" }}
      >
        <Stack
          direction={{ sm: "row", xs: "column" }}
          flex="1"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="!mt-6"
          flexWrap="wrap"
          gap={1}
        >
          <Button
            onClick={() =>
              navigate("/make-payment-options", {
                state: {
                  caseDetails: {
                    ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  },
                },
              })
            }
            variant="contained"
            sx={{ width: { sm: 248, xs: "100%" } }}
          >
            Payment via Smart Pay
          </Button>
          <Button
            onClick={() =>
              navigate("/cancel-current-payment", {
                state: {
                  caseDetails: {
                    ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  },
                },
              })
            }
            variant="contained"
            sx={{ width: { sm: 248, xs: "100%" } }}
          >
            Change Payment Method
          </Button>
          <Button
            onClick={() =>
              navigate("/payment-changes", {
                state: {
                  caseDetails: {
                    ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  },
                },
              })
            }
            variant="contained"
            sx={{ width: { sm: 248, xs: "100%" } }}
            disabled={
              !CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate
            }
          >
            Flex Payment Options
          </Button>
          <Button
            onClick={() =>
              navigate("/payment-holiday", {
                state: {
                  caseDetails: {
                    ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  },
                },
              })
            }
            variant="contained"
            sx={{ width: { sm: 248, xs: "100%" } }}
            disabled={
              !CustomerDetails?.[CustomerDetails?.ApplicantID]
                ?.IsEligibleForHolidays &&
              !CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate
            }
          >
            Payment Holiday
          </Button>
        </Stack>
        <NeedHelpLink
          style={{ alignSelf: "center", justifySelf: "flex-end" }}
        />
      </Stack>
    </Stack>
  );
};

export default CaseScreen;
