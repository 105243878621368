import { Typography } from "@mui/material";
import React from "react";
import GradiantButton from "../GradiantButton/GradiantButton";
import NeedHelpLink from "../NeedHelpLink/NeedHelpLink";
import { formContainerStyle } from "../../utils";
import { Box, Stack } from "@mui/system";
import BackButton from "../BackButton/BackButton";
import CCLogo from "../../assets/images/CCLogo.png";

type ImageLayoutContainerProps = {
  children: React.ReactNode;
  title?: string;
  handleClick?: (values: any) => void;
  btnText?: string;
  noPadding?: boolean;
  goBackRoute?: string;
  addLogoIcon?: boolean;
};
const ImageLayoutContainer = (props: ImageLayoutContainerProps) => {
  const {
    children,
    handleClick,
    btnText = "",
    noPadding,
    goBackRoute = "",
    addLogoIcon,
  } = props;

  return (
    <Box
      sx={{
        ...formContainerStyle,
        padding: "0px",
        justifyContent: "flex-start",
        maxHeight: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        minWidth: "357.5px",
      }}
      flexDirection="column"
    >
      <Stack
        direction="column"
        flex="1"
        alignItems="center"
        width="100%"
        sx={{ padding: noPadding ? "0px" : formContainerStyle.padding }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          position="sticky"
          top={0}
          zIndex={2}
          sx={{
            padding: noPadding
              ? addLogoIcon
                ? "20px 22px 10px 22px"
                : "50px 22px 10px 22px"
              : "0px 22px 0 22px",
            background: ["/initial-details", "/get-started"].includes(
              window.location.pathname
            )
              ? "transparent"
              : "#fff",
          }}
          className={addLogoIcon ? "addLogoIcon" : ""}
        >
          {!addLogoIcon ? (
            <BackButton goBackRoute={goBackRoute} />
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="spa"
                alignItems="center"
                gap="27px"
              >
                <img
                  src={CCLogo}
                  alt="cc-logo"
                  className="w-16 h-16"
                  style={{
                    filter:
                      "drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.5))",
                  }}
                />

                <Typography variant="h1" color="black">
                  Custom Credit
                </Typography>
              </Stack>
            </>
          )}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            flex="1"
          >
            <Typography variant="h6" color="primary">
              {props.title}
            </Typography>
          </Stack>
        </Stack>
        {children}
      </Stack>
      {handleClick ? (
        <>
          <NeedHelpLink />
          <GradiantButton handleClick={handleClick} btnText={btnText} />
        </>
      ) : (
        ""
      )}
      <div />
    </Box>
  );
};

export default ImageLayoutContainer;
