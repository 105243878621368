import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Grid from "@mui/material/Grid/Grid";
import React from "react";
import _ from "lodash";
import { addressFormat } from "../../../utils";

const CustomAutoComplete = (props) => {
  const { item } = props;
  const {
    fieldName,
    label,
    value,
    onChange,
    error,
    helperText,
    onOptionClick = (values) => {},
    fetchOptions = (input) => {},
    touched,
  } = item;
  const [options, setOptions] = React.useState([]);

  const fetchProduct = React.useCallback(async (input) => {
    try {
      if (input) {
        let active = true;
        const fetchedOptions = await fetchOptions(input);
        if (active) {
          let newOptions = [];

          if (fetchedOptions.length) {
            newOptions = [...newOptions, ...fetchedOptions].filter(
              (item) => typeof item !== "string"
            );
          }

          setOptions(newOptions);
          return newOptions;
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const delayFetch = React.useMemo(
    () => _.debounce((input) => fetchProduct(input), 100),
    []
  );
  return (
    <FormControl className="!w-full" error={error && touched ? true : false}>
      <Autocomplete
        id={fieldName}
        name={fieldName}
        freeSolo
        placeholder={label ? label : fieldName.toUpperCase()}
        value={value}
        getOptionLabel={(option) => {
          // onOptionClick(option);
          return typeof option === "string" ? option : option.PostCode;
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          onOptionClick(newValue);

          onChange({ target: { name: fieldName, value: newValue?.PostCode } });
        }}
        onInputChange={(event, newInputValue) => {
          setOptions([]);
          onChange({ target: { name: fieldName, value: newInputValue } });
          if (newInputValue && !item?.defaultValue) delayFetch(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : fieldName.toUpperCase()}
            fullWidth
            name={fieldName}
            error={error && touched ? true : false}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Box component="span" sx={{ fontWeight: "regular" }}>
                    {addressFormat([option?.HouseNumber, option?.HouseName])}
                  </Box>
                  {/* ))} */}
                  <Typography variant="body2" color="text.secondary">
                    {addressFormat([
                      option?.Address1,
                      option?.Address2,
                      option?.Address3,
                      option?.Address4,
                    ])}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        fullWidth
      />
      <FormHelperText
        className="!text-red-600"
        error={error && touched ? true : false}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomAutoComplete;
