import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CenterColumnContainer from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
const OOMOHOOOLA = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/home");
  };
  return (
    <ImageLayoutContainer handleClick={handleSubmit}>
      <CenterColumnContainer flex="1">
        <Typography className="!text-xs !font-bold">OOMOOLA</Typography>
      </CenterColumnContainer>
    </ImageLayoutContainer>
  );
};

export default OOMOHOOOLA;
