import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";

const useFormHook = (
  textFields: {
    fieldName: string;
    type: string;
    label?: string;
    defaultValue?: string | number;
    notValidate?: boolean;
    options?: { value: string; title: string }[];
    customValidation?: any;
  }[],
  handleSubmit: (
    values: { [x: string]: string },
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => void | Promise<any>
) => {
  const validationSchema = yup.object(
    textFields
      .filter(
        (item) => item?.notValidate === undefined || item?.notValidate === false
      )
      .map((item) => ({
        [item.fieldName]: item.customValidation
          ? item.customValidation
          : yup.string().required(`This field is required`),
      }))
      .reduce((previousValue, currentValue) => ({
        ...previousValue,
        ...currentValue,
      }))
  );
  const formik = useFormik({
    initialValues: textFields
      .map((item) => ({
        [item.fieldName]: item.defaultValue ? item.defaultValue.toString() : "",
      }))
      .reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          ...currentValue,
        }),
        {}
      ),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return [formik];
};
export default useFormHook;
