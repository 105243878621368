import { TextField } from "@mui/material";
import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="£"
      />
    );
  }
);

type TextFieldProps = {
  item: {
    fieldName: string;
    label?: string;
    type: string;
    value: string;
    onChange:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    onBlur:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    error: boolean | undefined;
    helperText: string;
    handleBlur?:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    isVerified?: string;
    options?: { value: string; title: string }[];
    extraProps?: any;
  };
};

const CustomNumberInput = (props: TextFieldProps) => {
  const { item } = props;
  const {
    fieldName,
    label,
    value,
    onChange,
    error,
    helperText,
    extraProps = {},
  } = item;
  return (
    <TextField
      inputProps={{
        className: `!w-full`,
        ...extraProps,
      }}
      fullWidth
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
      label={label ? label : fieldName.toUpperCase()}
      placeholder={label ? label : fieldName.toUpperCase()}
      variant="outlined"
      id={fieldName}
      name={fieldName}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      onKeyPress={(e: any) =>
        ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
      }
    />
  );
};

export default CustomNumberInput;
