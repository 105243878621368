import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

type CustomSelectInputProps = {
  item: {
    fieldName: string;
    label?: string;
    type: string;
    value: string;
    onChange:
      | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
      | undefined;
    onBlur:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    error: boolean | undefined;
    helperText: string;
    options?: { value: string; title: string }[];
  };
};
const CustomSelectInput = (props: CustomSelectInputProps) => {
  const { item } = props;

  const {
    fieldName,
    label,
    options,
    value,
    onChange,
    onBlur,
    error,
    helperText,
  } = item;
  return (
    <FormControl className="!w-full">
      <InputLabel htmlFor={fieldName}>
        {label ? label : fieldName.toUpperCase()}
      </InputLabel>
      <Select
        inputProps={{
          className: " !w-full",
        }}
        label={label ? label : fieldName}
        id={fieldName}
        name={fieldName}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        variant="outlined"
        fullWidth
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText className="!text-red-600">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelectInput;
