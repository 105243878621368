import React from "react";
import { useNavigate } from "react-router-dom";
import { requestToResetPassword } from "../../api/oauth";

import ImageContainerWithBtns from "../../components/ImageContainer/ImageContainerWithBtns";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { Stack } from "@mui/system";
import { Button, CircularProgress, Typography } from "@mui/material";
import { formStyle } from "../../utils";

const RequestToResetPassword = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const textFields = [
    {
      fieldName: "email",
      defaultValue: "",
      label: "Email",
      type: "email",
      customValidation: getYup
        .string()
        .email("Invalid email address")
        .required("This field is required"),
      additionalElement: (
        <Typography className="!mb-2 !w-5/6">
          Enter email to get reset password link
        </Typography>
      ),
    },
  ];
  const handleSubmit = (values: any) => {
    setLoading(true);
    requestToResetPassword({
      email: values.email,
    })
      .then((response) => {
        if (response?.data?.status) {
          setMsg(
            "If provided email address is found an email with reset password link will be sent."
          );
          setLoading(false);
        } else {
          setLoading(false);
          setToastMessage(response?.data?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
        return setToastMessage(err?.message || "Something went wrong");
      });
  };
  return (
    <ImageContainerWithBtns title="RESET PASSWORD" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      {!loading ? (
        msg ? (
          <Stack
            direction="column"
            sx={{
              ...formStyle,
              padding: "30px 22px 0 22px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography className="!w-5/6">{msg}</Typography>
            <Button
              variant="contained"
              sx={{ mt: 3, cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Go to Login
            </Button>
          </Stack>
        ) : (
          <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
        )
      ) : (
        <Stack direction="column" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </ImageContainerWithBtns>
  );
};

export default RequestToResetPassword;
