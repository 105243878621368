import React, { useCallback, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { additionalUrlParams, getUrlParam } from "../../utils";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { getOpenBankingStatus, invokeADPCall, updateCase } from "../../api/crm";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import CenterColumnContainer from "../../components/Containers";
import { SmallWidthColumnContainer } from "../../components/Containers";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { ErrorOutline } from "@mui/icons-material";

const OpenBanking3 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [toastMessage, setToastMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const { CustomerDetails = {} } = useCaseInfoContext();

  const OpenBankingId =
    location?.state?.OpenBankingId ||
    location?.state?.caseDetails?.DynamicFieldDetail?.find(
      (field: any) => field?.FieldNo === "22"
    )?.FieldValue;
  const caseInfoDispatch = useUpdateCaseInfoContext();

  const checkStatus = useCallback(() => {
    setIsLoading(true);

    if (!CustomerDetails.ApplicantID && getUrlParam("ApplicantID")) {
      CustomerDetails.ApplicantID = getUrlParam("ApplicantID");
      CustomerDetails.CustomerReference = getUrlParam("CustomerReference");
    }

    if (!CustomerDetails?.CustomerReference || !CustomerDetails?.ApplicantID)
      return setIsLoading(false);

    getOpenBankingStatus({
      pRequest: {
        CustomerCode: CustomerDetails?.CustomerReference,
        OpenBankingId,
        IsCheckWithThirdPartyRequired: true,
      },
    })
      .then((response) => {
        const isStatusCompleted =
          response?.data?.d?.Records?.[response?.data?.d?.Records.length - 1]
            ?.Status === "Complete" || false;

        if (isStatusCompleted) {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.FETCHED_OPEN_BANKING_STATUS,
            payload: response?.data?.d?.Records,
          });
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              OpenBankingCurrentStatus:
                response?.data?.d?.Records?.[
                  response?.data?.d?.Records.length - 1
                ]?.Status,
            },
          });

          const adpCallNames = ["CAT 11", "CAT 12", "CAT 13", "CAT 14"];
          const asuncUpdatedAdpCalls = async (index: number) => {
            const adpCallName = adpCallNames[index];

            if (index === adpCallNames.length || !adpCallName) {
              return navigate(
                "/welcome-after-open-banking" + additionalUrlParams()
              );
            }

            invokeADPCall({
              Payload: {
                AdpCallName: adpCallName,
                CaseRef: CustomerDetails?.ApplicantID,
              },
            }).then(({ data }) => {
              const recordValue = data?.d?.Records?.find((record: any) =>
                record?.FieldName?.includes(adpCallName)
              )?.Value;

              if (recordValue === "Decline Live") {
                // update API to change Cases Status
                updateCase({
                  ApplicantID: CustomerDetails?.ApplicantID,
                  CustomerReference: CustomerDetails?.CustomerReference,
                  Payload: {
                    WorkflowStep: "9",
                    CaseStatus: "Declined",
                  },
                })
                  .then((responseData) => {
                    if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
                      return navigate(
                        "/failed-credit-checks" + additionalUrlParams(),
                        {
                          state: {
                            submitBtnURL: "/home",
                            submitBtnText: "Go to Dashboard",
                          },
                        }
                      );
                  })
                  .catch((err) => {
                    setToastMessage(
                      err?.data?.d?.EnquiryResult?.Message ||
                        err?.message ||
                        "Something went wrong"
                    );
                  });
              }

              const nextIndex = index + 1;
              return asuncUpdatedAdpCalls(nextIndex);
            });
          };

          asuncUpdatedAdpCalls(0);
        }
        // else {
        //   setToastMessage("Open banking not connected.");
        //   return navigate("/failed-credit-checks", {
        //     state: {
        //       submitBtnURL: "/home",
        //       submitBtnText: "Go to Dashboard",
        //     },
        //   });
        // }
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        )
      )
      .finally(() => setIsLoading(false));
  }, [
    CustomerDetails?.ApplicantID,
    CustomerDetails?.CustomerReference,
    OpenBankingId,
    caseInfoDispatch,
    navigate,
  ]);

  const handleSubmit = (values: any) => {};

  useEffect(() => {
    const interval = setInterval(checkStatus, 50000);
    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ImageLayoutContainer
      noPadding
      title="OPEN BANKING"
      handleClick={handleSubmit}
      btnText="Refresh" // Text for the Button placed at the bottom of the page. (Usually displayed as Next Button)
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <CenterColumnContainer>
        <SmallWidthColumnContainer
          flex="1"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {isLoading ? (
            <CircularProgress color="primary" size="45px" />
          ) : (
            <ErrorOutline color="primary" sx={{ fontSize: "45px" }} />
          )}
          <Typography className="!mt-4 !text-xs !w-5/6">
            If your bank confirmed the connection was successful, it may take a
            minute or two to update us - we appreciate your patience. If after
            this time, you are not taken to the next page, please press
            "Refresh" below.
          </Typography>
        </SmallWidthColumnContainer>
      </CenterColumnContainer>
    </ImageLayoutContainer>
  );
};

export default OpenBanking3;
