import { Navigate } from "react-router-dom";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";

const ProtectedCaseRoutes = ({ element: Component, ...rest }) => {
  const { CustomerDetails = {} } = useCaseInfoContext();
  const ApplicantID = CustomerDetails?.ApplicantID;

  //if (!ApplicantID && !rest.skipCaseChecking) {
  //  return <Navigate to="/home" />;
  //}
  return <Component {...rest} />;
};

export default ProtectedCaseRoutes;
