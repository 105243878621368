import React, { FocusEvent } from "react";
import * as yup from "yup";
import { FormikHelpers } from "formik";
import {
  CASEINFO_ACTIONS_TYPES,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../api/oauth";
import {
  AUTH_ACTIONS,
  useUpdateAuthContext,
} from "../../context/AuthContext/AuthContext";
import ImageContainerWithBtns from "../../components/ImageContainer/ImageContainerWithBtns";
import FormComponent, {
  fieldStatuses,
  getYup,
} from "../../components/Forms/FormComponent";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { verifyEmail, verifyMobile } from "../../api/crm";

const InitialDetials = () => {
  const navigate = useNavigate();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const authDispatch = useUpdateAuthContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const [isMobileNoVerified, setIsMobileNoVerified] = React.useState(
    fieldStatuses.empty
  );
  const [isEmailVerified, setIsEmailVerified] = React.useState(
    fieldStatuses.empty
  );

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const textFields = [
    {
      fieldName: "FirstName",
      label: "First Name",
      defaultValue: "",
      type: "text",
    },
    {
      fieldName: "Surname",
      defaultValue: "",
      label: "Surname",
      type: "text",
    },
    {
      fieldName: "Mobile",
      defaultValue: "",
      label: "Mobile Number",
      type: "string",
      customValidation: getYup
        .string()
        .required("This field is required")
        .matches(phoneRegExp, "Please enter a valid mobile phone number")
        .test(
          "Mobile",
          "Mobile phone number should be maximum 11 digits long",
          (value: any) => value && value.toString().length <= 11
        ),
      handleBlur: (e: FocusEvent<HTMLInputElement>) => {
        verifyMobile({ value: e.target.value.toString() })
          .then((response) => {
            if (
              response?.data?.d?.EnquiryResult?.SuccessCode === 0 &&
              response?.data?.d?.Records?.[0]?.IsValid
            ) {
              setIsMobileNoVerified(fieldStatuses.verified);
            } else setIsMobileNoVerified(fieldStatuses.notVerified);
          })
          .catch((err) =>
            setToastMessage(err?.data?.d?.EnquiryResult?.Message)
          );
      },
      isVerified: isMobileNoVerified,
    },
    {
      fieldName: "Email",
      defaultValue: "",
      label: "Email",
      type: "text",
      handleBlur: (e: FocusEvent<HTMLInputElement>) => {
        verifyEmail({ value: e.target.value })
          .then((response) => {
            if (
              response?.data?.d?.EnquiryResult?.SuccessCode === 0 &&
              response?.data?.d?.Records?.[0]?.IsValid
            ) {
              setIsEmailVerified(fieldStatuses.verified);
            } else setIsEmailVerified(fieldStatuses.notVerified);
          })
          .catch((err) =>
            setToastMessage(err?.data?.d?.EnquiryResult?.Message)
          );
      },
      isVerified: isEmailVerified,
    },
    {
      fieldName: "password",
      defaultValue: "",
      label: "Password",
      type: "password",
      customValidation: getYup
        .string()
        .min(8, "Password should be more than 8 characters")
        .required("This field is required"),
    },
    {
      fieldName: "confirmPassword",
      defaultValue: "",
      label: "Confirm Password",
      type: "password",
      customValidation: getYup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("This field is required"),
    },
  ];
  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => {
    const { setErrors } = formikHelpers;
    if (
      isMobileNoVerified === fieldStatuses.verified &&
      isEmailVerified === fieldStatuses.verified
    )
      signUp({
        FirstName: values.FirstName,
        Surname: values.Surname,
        Mobile: values.Mobile,
        Email: values.Email,
        Password: values.password,
      })
        .then((response) => {
          if (response?.data?.accessToken) {
            authDispatch({
              type: AUTH_ACTIONS.LOGIN,
              payload: response?.data,
            });
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.ADD_INITIAL_DETAILS,
              payload: {
                FirstName: values.FirstName,
                Surname: values.Surname,
                Mobile: values.Mobile,
                Email: values.Email,
              },
            });
            navigate("/loan-initial-details");
          } else {
            setToastMessage(response?.data?.message || "Something went wrong");
          }
        })
        .catch((err) =>
          setToastMessage(err?.message || "Something went wrong")
        );
    else {
      if (
        isEmailVerified !== fieldStatuses.verified &&
        isMobileNoVerified !== fieldStatuses.verified
      ) {
        setErrors({
          Email:
            isEmailVerified === fieldStatuses.notVerified
              ? "Provided email is invalid"
              : "Please verify your email",
          Mobile:
            isMobileNoVerified === fieldStatuses.notVerified
              ? "Provided mobile number is invalid"
              : "Please verify your mobile number",
        });
      } else if (isMobileNoVerified !== fieldStatuses.verified) {
        setErrors({
          Mobile:
            isMobileNoVerified === fieldStatuses.notVerified
              ? "Provided mobile number is invalid"
              : "Please verify your mobile number",
        });
      } else if (isEmailVerified !== fieldStatuses.verified) {
        setErrors({
          Email:
            isEmailVerified === fieldStatuses.notVerified
              ? "Provided email is invalid"
              : "Please verify your email",
        });
      }
    }
  };
  return (
    <ImageContainerWithBtns title="START TODAY" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
    </ImageContainerWithBtns>
  );
};

export default InitialDetials;
