import React from "react";
import { Button, Stack, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import {
  ordoCollectPayment,
  ordoVRPCancelMandate,
  accessPaysuiteCancelContract,
  accessPaysuiteCreateContract,
  accessPaysuiteCreateCustomer,
  accessPaysuiteIsStartDateValidatForContract,
  isAccessPaysuiteContractCreated,
  isAccessPaysuiteCustomerCreated,
  ordoVRPCreateMandate,
} from "../../api/crm";
import useADPData from "../../hooks/useADPData";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { currencyFormat } from "../../utils";
import moment from "moment";

const CancelCurrentPaymentOption = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const { ApplicantID } = CustomerDetails;
  const currentMonthly =
    CustomerDetails?.[CustomerDetails?.ApplicantID]?.MonthlyPayment;
  const selectedCase = CustomerDetails?.[CustomerDetails?.ApplicantID];
  const { getADPDataValueByDescription } = useADPData(
    CustomerDetails?.ApplicantID
  );
  const flexHighPayment = getADPDataValueByDescription("CAT 14", "FlexMax");
  const [toastMessage, setToastMessage] = React.useState("");
  const handleSubmit = () => {
    if (CustomerDetails?.[CustomerDetails?.ApplicantID])
      ordoVRPCancelMandate({
        CaseReference: CustomerDetails?.ApplicantID,
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
            return navigate("/home");
          else
            setToastMessage(
              responseData?.data?.d?.EnquiryResult?.Message ||
                "Something went wrong"
            );
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };
  const handleSubmit2 = () => {
    if (CustomerDetails?.[CustomerDetails?.ApplicantID])
      accessPaysuiteCancelContract({
        ApplicantID: CustomerDetails?.ApplicantID,
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
            return navigate("/home");
          else
            setToastMessage(
              responseData?.data?.d?.EnquiryResult?.Message ||
                "Something went wrong"
            );
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };

  // Initiate VRP
  const handleSubmit3 = async () => {
    var windowReference: any = window.open();
    try {
      const mandateData = await ordoVRPCreateMandate({
        MandateData: {
          CaseReference: ApplicantID,
          MaximumIndividualAmount: flexHighPayment, //flexHighPayment
          PeriodTypeLimitAmount: flexHighPayment, //flexHighPayment
        },
      }).then((res) => res.data);

      const isMandateCreated = mandateData?.d?.EnquiryResult.SuccessCode === 0;

      if (isMandateCreated && mandateData.d.AuthUrl) {
        const URL = mandateData.d.AuthUrl;
        if (URL) {
          // window.open(URL, "_blank");
          windowReference.location = URL;
          return navigate("/home");
        }
      }

      throw new Error(
        mandateData?.d?.Message || mandateData?.d?.EnquiryResult?.Message
      );
    } catch (err: any) {
      setToastMessage(
        err?.data?.d?.EnquiryResult?.Message ||
          err?.message ||
          "Something went wrong"
      );
    }
  };

  // Initiate DD
  const handleSubmit4 = async () => {
    try {
      // Checks if start date is valid for creating a contract
      const isStartDateValid =
        await accessPaysuiteIsStartDateValidatForContract({
          StartDate: moment(
            selectedCase.PaymentPlanDetail.FirstPaymentDate
          ).format("DD-MM-YYYY"),
        });

      if (!isStartDateValid.data.d.Valid)
        throw new Error(isStartDateValid?.data?.d?.ErrorMessage);

      // Checks if customer was already created
      const customerData = await isAccessPaysuiteCustomerCreated({
        ApplicantID,
      }).then((res) => res.data.d);

      const isCustomerExist = customerData.IsCreated;

      // Create a customer if not exists
      if (!isCustomerExist) {
        const createCustomer = await accessPaysuiteCreateCustomer({
          ApplicantID,
          Customer: customerData.Customer,
        }).then((res) => res.data.d);

        if (!createCustomer) throw new Error(createCustomer?.Message);
      }

      // Checks if a contract was already created
      const contractData = await isAccessPaysuiteContractCreated({
        ApplicantID,
      }).then((res) => res.data.d);

      const isContractExist = contractData.IsCreated;

      // Create a contract, it not exists
      if (isContractExist) {
        return navigate("/home");
      } else {
        const createContract = await accessPaysuiteCreateContract({
          ApplicantID,
          ScheduleId: contractData?.Contract?.ScheduleId,
        }).then((res) => res.data.d);

        if (createContract.IsCreated) {
          return navigate("/home");
        }

        throw new Error(createContract?.Message);
      }
    } catch (err: any) {
      setToastMessage(
        err?.data?.d?.EnquiryResult?.Message ||
          err?.message ||
          "Something went wrong"
      );
    }
  };

  React.useEffect(() => {
    if (!location?.state?.caseDetails) return navigate("/home");
  }, [location?.state?.caseDetails, navigate]);
  return (
    <CenterLayoutContainer pageTitle="AMEND PAYMENT METHOD">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        Balance
      </Typography>
      <Typography
        sx={{ fontSize: "32px" }}
        color={(theme) => theme.palette.primary.light}
      >
        {currencyFormat(location?.state?.caseDetails?.TotalRepaymentAmount)}
      </Typography>
      {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate ? (
        <Stack gap={1} direction="row" alignItems="center" className="!mt-6">
          <Typography className="!text-sm">
            Your next payment date is
          </Typography>
          <Typography
            color={(theme) => theme.palette.secondary.main}
            className="!text-sm"
          >
            {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate}
          </Typography>
          <Typography className="!text-sm">
            but you can also amend your existing payment method below.
          </Typography>
        </Stack>
      ) : (
        ""
      )}
      <Typography className="!text-sm">{`Your current monthly payment is £${currentMonthly}`}</Typography>

      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        VRP Amendments
      </Typography>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Cancel VRP Payments
      </Button>
      <Button
        onClick={handleSubmit3}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Set up VRP
      </Button>
      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        Direct Debit Amendments
      </Typography>
      <Button
        onClick={handleSubmit2}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Cancel Direct Debit Payments
      </Button>
      <Button
        onClick={handleSubmit4}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Set up Direct Debit
      </Button>
      {/* <NeedHelpLink style={{ alignSelf: "center", justifySelf: "flex-end" }} /> */}
    </CenterLayoutContainer>
  );
};

export default CancelCurrentPaymentOption;
