import React from "react";
import axios from "axios";
import * as CryptoJS from "crypto-js";

const requests = {
  createCase: "/CreateCaseJSON",
  updateCase: "/UpdateCase",
  invokeADPCall: "/InvokeADPCall",
  openBankingHandShakeInvoke: "/OpenBankingHandShakeInvoke",
  getCase: "/GetCase",
  getOpenBankingStatus: "/GetOpenBankingStatus",
  getADPData: "/GetADPData",
  searchCustomers: "/SearchCustomers",
  fetchifyEmailVerification: "/FetchifyEmailVerification",
  fetchifyMobileVerification: "/FetchifyMobileVerification",
  createDocumentToPdf: "/CreateDocumentToPdf",
  updateCaseLockPaymentPlan: "/UpdateCaseLockPaymentPlan",
  updateCustomer: "/UpdateCustomer",
  getFinancialTransactions: "/GetFinancialTransactions",
  ordoCollectPayment: "/OrdoCollectPayment",
  getInvoiceTerms: "/GetInvoiceTerms",
  updatePaymentPlanHolidays: "/UpdatePaymentPlanHolidays",
  caseRecalculatePaymentPlan: "/CaseRecalculatePaymentPlan",
  getPostcodeLookup: "/GetPostcodeLookup",
  accessPaysuiteIsStartDateValidatForContract:
    "/AccessPaysuiteIsStartDateValidatForContract",
  isAccessPaysuiteCustomerCreated: "/IsAccessPaysuiteCustomerCreated",
  accessPaysuiteCreateCustomer: "/AccessPaysuiteCreateCustomer",
  isAccessPaysuiteContractCreated: "/IsAccessPaysuiteContractCreated",
  accessPaysuiteCreateContract: "/AccessPaysuiteCreateContract",
  accessPaysuiteCancelContract: "/AccessPaysuiteCancelContract",
  ordoVRPCreateMandate: "/OrdoVRPCreateMandate",
  ordoVRPCancelMandate: "/OrdoVRPCancelMandate",
};

export const crmInstance = axios.create({
  baseURL: process.env.REACT_APP_API_CRM_BASE_URL,
});
export const crmInstanceMiddleServer = axios.create({
  baseURL: "https://demoserver-qna9.onrender.com/api",
});

type CreateCasePropsType = {
  FirstName: string;
  Surname: string;
  Mobile: string;
  Email: string;
};

const encryptionKey: string = process.env
  .REACT_APP_API_ENCRYPTION_KEY as string;

// export const createCase = (params: CreateCasePropsType) => {
//   const { FirstName, Surname, Mobile, Email } = params;
//   return crmInstance
//     .post(requests.createCase, {
//       UserInfo,
//       CaseType: "LoansMort",
//       CaseParams: {
//         Params: {
//           MatterType: "Unsecured-Loan",
//           Provider: "Custom Credit",
//           ProviderProduct: "Unsecured Loan.",
//           TotalLoan: "0",
//           Term: "36",
//           TermPeriod: "M",
//           InterestYearly: "24.9",
//           Source: "Online Application",
//           CaseStatus: "Initial Enquiry",
//           CalculateFinancialField: true,
//           CustomerDetails: {
//             CustomerCode: "",
//             B2B: "false",
//             Title: "",
//             FirstName,
//             Surname,
//             Source: "Online Application",
//             Telephone: Mobile,
//             Mobile,
//             Email,
//             ClientStatus: "Prospect",
//             IsDeduplicationReq: true,
//             DeduplicationCheckReq: true,
//           },
//         },
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const createCase = (params: CreateCasePropsType) => {
  const { FirstName, Surname, Mobile, Email } = params;

  // Encrypt the data
  //  const UserInfoNew = CryptoJS.AES.encrypt(
  //     JSON.stringify(UserInfo),
  //     encryptionKey
  //   ).toString();

  return crmInstanceMiddleServer
    .post(requests.createCase, {
      // UserInfoNew,
      CaseType: "LoansMort",
      CaseParams: {
        Params: {
          MatterType: "Unsecured-Loan",
          Provider: "Custom Credit",
          ProviderProduct: "Unsecured Loan.",
          TotalLoan: "0",
          Term: "36",
          TermPeriod: "M",
          InterestYearly: "24.9",
          Source: "Online Application",
          CaseStatus: "Initial Enquiry",
          CalculateFinancialField: true,
          CustomerDetails: {
            CustomerCode: "",
            B2B: "false",
            Title: "",
            FirstName,
            Surname,
            Source: "Online Application",
            Telephone: Mobile,
            Mobile,
            Email,
            ClientStatus: "Prospect",
            IsDeduplicationReq: true,
            DeduplicationCheckReq: true,
          },
        },
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCasePropsType = {
  ApplicantID: string;
  CustomerReference: string;
  Payload: any;
};

// export const updateCase = (params: UpdateCasePropsType) => {
//   const { ApplicantID, CustomerReference, Payload } = params;
//   return crmInstance
//     .post(requests.updateCase, {
//       UserInfo,
//       CaseApp: {
//         IsUpdateCaseCustomer: true,
//         ApplicantID,
//         CustomerReference,
//         // CaseStatus: "Prospect",
//         CalculateFinancialField: true,
//         AvoidUpdatingBlankData: true,
//         ...Payload,
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const updateCase = (params: UpdateCasePropsType) => {
  const { ApplicantID, CustomerReference, Payload } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.updateCase, {
      // UserInfoNew,
      CaseApp: {
        IsUpdateCaseCustomer: true,
        ApplicantID,
        CustomerReference,
        // CaseStatus: "Prospect",
        CalculateFinancialField: true,
        AvoidUpdatingBlankData: true,
        ...Payload,
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCaseCancellablePropsType = {
  ApplicantID: string;
  CustomerReference: string;
  Payload: any;
};

let cancelTokenForUpdateCaseCancellable: any;

export const updateCaseCancellable = (
  params: UpdateCaseCancellablePropsType
) => {
  const { ApplicantID, CustomerReference, Payload } = params;

  //Check if there are any previous pending requests
  if (typeof cancelTokenForUpdateCaseCancellable != typeof undefined) {
    cancelTokenForUpdateCaseCancellable.cancel(
      "Operation canceled due to new request."
    );
  }

  //Save the cancel token for the current request
  cancelTokenForUpdateCaseCancellable = axios.CancelToken.source();

  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(
      "updateCaseCancellable",
      {
        // UserInfoNew,
        CaseApp: {
          IsUpdateCaseCustomer: true,
          ApplicantID,
          CustomerReference,
          // CaseStatus: "Prospect",
          CalculateFinancialField: true,
          AvoidUpdatingBlankData: true,
          ...Payload,
        },
      },
      {
        cancelToken: cancelTokenForUpdateCaseCancellable.token,
      }
    )
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type InvokeADPCallPropsType = {
  Payload: any;
};

// export const invokeADPCall = (params: InvokeADPCallPropsType) => {
//   const { Payload } = params;
//   return crmInstance
//     .post(requests.invokeADPCall, {
//       UserInfo,
//       pRequest: {
//         ...Payload,
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const invokeADPCall = (params: InvokeADPCallPropsType) => {
  const { Payload } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.invokeADPCall, {
      // UserInfoNew,
      pRequest: {
        ...Payload,
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type OpenBankingHandShakeInvokePropsType = {
  pRequest: any;
};

// export const openBankingHandShakeInvoke = (
//   params: OpenBankingHandShakeInvokePropsType
// ) => {
//   const { pRequest } = params;
//   return crmInstance
//     .post(requests.openBankingHandShakeInvoke, {
//       UserInfo,
//       pRequest,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const openBankingHandShakeInvoke = (
  params: OpenBankingHandShakeInvokePropsType
) => {
  const { pRequest } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.openBankingHandShakeInvoke, {
      // UserInfoNew,
      pRequest,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetCasePropsType = {
  CaseReference: string;
};

// export const getCase = (params: GetCasePropsType) => {
//   const { CaseReference } = params;
//   return crmInstance
//     .post(requests.getCase, {
//       UserInfo,
//       CaseReference,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getCase = (params: GetCasePropsType) => {
  const { CaseReference } = params;
  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  const CaseReferenceEncypt = CryptoJS.AES.encrypt(
    JSON.stringify(CaseReference),
    encryptionKey
  ).toString();
  return crmInstanceMiddleServer
    .post(requests.getCase, {
      // UserInfoNew,
      CaseReference: CaseReferenceEncypt,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetOpenBankingStatusPropsType = {
  pRequest: any;
};

// export const getOpenBankingStatus = (params: GetOpenBankingStatusPropsType) => {
//   const { pRequest } = params;
//   return crmInstance
//     .post(requests.getOpenBankingStatus, {
//       UserInfo,
//       pRequest,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getOpenBankingStatus = (params: GetOpenBankingStatusPropsType) => {
  const { pRequest } = params;
  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.getOpenBankingStatus, {
      // UserInfoNew,
      pRequest,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

// export const getADPData = (params: GetCasePropsType) => {
//   const { CaseReference } = params;
//   console.log(CaseReference, "CaseReference");
//   return crmInstance
//     .post(requests.getADPData, {
//       UserInfo,
//       CaseReference,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getADPData = (params: GetCasePropsType) => {
  const { CaseReference } = params;
  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  const CaseReferenceEncypt = CryptoJS.AES.encrypt(
    JSON.stringify(CaseReference),
    encryptionKey
  ).toString();
  return crmInstanceMiddleServer
    .post(requests.getADPData, {
      // UserInfoNew,
      CaseReference: CaseReferenceEncypt,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type SearchCustomerPropsType = { CustomerReference: string };

export const searchCustomers = (params: SearchCustomerPropsType) => {
  const { CustomerReference } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  const CustomerReferencEncypt = CryptoJS.AES.encrypt(
    JSON.stringify(CustomerReference),
    encryptionKey
  ).toString();

  return crmInstanceMiddleServer
    .post(requests.searchCustomers, {
      // UserInfoNew,
      SearchParam: CustomerReferencEncypt,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type VerifyEmailPropsType = { value: string };

// export const verifyEmail = (params: VerifyEmailPropsType) => {
//   const { value } = params;
//   return crmInstance
//     .post(requests.fetchifyEmailVerification, {
//       UserInfo,
//       pRequest: { Mode: "email", value },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const verifyEmail = (params: VerifyEmailPropsType) => {
  const { value } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.fetchifyEmailVerification, {
      // UserInfoNew,
      pRequest: { Mode: "email", value },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type VerifyMobilePropsType = { value: string };

// export const verifyMobile = (params: VerifyMobilePropsType) => {
//   const { value } = params;
//   return crmInstance
//     .post(requests.fetchifyMobileVerification, {
//       UserInfo,
//       pRequest: {
//         Mode: "mobile",
//         value,
//         ISOCountryCode2: "GB",
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const verifyMobile = (params: VerifyMobilePropsType) => {
  const { value } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.fetchifyMobileVerification, {
      // UserInfoNew,
      pRequest: {
        Mode: "mobile",
        value,
        ISOCountryCode2: "GB",
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type CreateDocumentToPdfPropsType = { ApplicantID: string; LetterCode: string };

// export const createDocumentToPdf = (params: CreateDocumentToPdfPropsType) => {
//   const { ApplicantID, LetterCode } = params;
//   return crmInstance
//     .post(requests.createDocumentToPdf, {
//       UserInfo,
//       pRequest: {
//         Mode: "Case",
//         Reference: ApplicantID,
//         LetterCode,
//         LetterGroupCode: "",
//         ReturnDocType: "PDF",
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const createDocumentToPdf = (params: CreateDocumentToPdfPropsType) => {
  const { ApplicantID, LetterCode } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.createDocumentToPdf, {
      // UserInfoNew,
      pRequest: {
        Mode: "Case",
        Reference: ApplicantID,
        LetterCode,
        LetterGroupCode: "",
        ReturnDocType: "PDF",
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCaseLockPaymentPlanType = {
  ApplicantID: string;
};

// export const updateCaseLockPaymentPlan = (
//   params: UpdateCaseLockPaymentPlanType
// ) => {
//   const { ApplicantID } = params;
//   return crmInstance
//     .post(requests.updateCaseLockPaymentPlan, {
//       UserInfo,
//       CaseReference: ApplicantID,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const updateCaseLockPaymentPlan = (
  params: UpdateCaseLockPaymentPlanType
) => {
  const { ApplicantID } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.updateCaseLockPaymentPlan, {
      // UserInfoNew,
      CaseReference: ApplicantID,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdateCustomerPropsType = {
  CustomerReference: string;
  Email: string;
  Mobile: string;
  IsUpdateCaseCustomer?: boolean;
  DateOfBirth?: string;
  CustomerAddress?: object;
  BankDetail?: object;
  BankDetails?: object;
};

// export const updateCustomer = (params: UpdateCustomerPropsType) => {
//   const { CustomerReference, Email, Mobile, ...restProps } = params;
//   return crmInstance
//     .post(requests.updateCustomer, {
//       UserInfo,
//       Customer: {
//         CustomerReference,
//         Email,
//         Mobile: Mobile.toString(),
//         AvoidUpdatingBlankData: true,
//         ...restProps,
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const updateCustomer = (params: UpdateCustomerPropsType) => {
  const { CustomerReference, Email, Mobile, ...restProps } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.updateCustomer, {
      // UserInfoNew,
      Customer: {
        CustomerReference,
        Email,
        Mobile: Mobile.toString(),
        AvoidUpdatingBlankData: true,
        ...restProps,
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetFinancialTransactionsPropsType = {
  Reference: string;
  TransactionType: string;
  DateFrom?: string;
  DateTo?: string;
};

// export const getFinancialTransactions = (
//   params: GetFinancialTransactionsPropsType
// ) => {
//   const {
//     Reference = "",
//     TransactionType = "",
//     DateFrom = "",
//     DateTo = "",
//   } = params;
//   return crmInstance
//     .post(requests.getFinancialTransactions, {
//       UserInfo,
//       Reference,
//       TransactionType,
//       MaxRecords: "5000",
//       DateFrom: DateFrom ? DateFrom : "",
//       DateTo: DateTo ? DateTo : "",
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getFinancialTransactions = (
  params: GetFinancialTransactionsPropsType
) => {
  const {
    Reference = "",
    TransactionType = "",
    DateFrom = "",
    DateTo = "",
  } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.getFinancialTransactions, {
      // UserInfoNew,
      Reference,
      TransactionType,
      MaxRecords: "5000",
      DateFrom: DateFrom ? DateFrom : "",
      DateTo: DateTo ? DateTo : "",
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

// APIs for Direct Debit Payment Option
type AccessPaysuiteIsStartDateValidatForContractType = {
  StartDate: string;
};

// export const accessPaysuiteIsStartDateValidatForContract = (
//   params: AccessPaysuiteIsStartDateValidatForContractType
// ) => {
//   const { StartDate } = params;
//   return crmInstance
//     .post(requests.accessPaysuiteIsStartDateValidatForContract, {
//       UserInfo,
//       StartDate,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const accessPaysuiteIsStartDateValidatForContract = (
  params: AccessPaysuiteIsStartDateValidatForContractType
) => {
  const { StartDate } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteIsStartDateValidatForContract, {
      // UserInfoNew,
      StartDate,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type IsAccessPaysuiteCustomerCreatedType = {
  ApplicantID: string;
};

// export const isAccessPaysuiteCustomerCreated = (
//   params: IsAccessPaysuiteCustomerCreatedType
// ) => {
//   const { ApplicantID } = params;
//   return crmInstance
//     .post(requests.isAccessPaysuiteCustomerCreated, {
//       UserInfo,
//       ApplicantID,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const isAccessPaysuiteCustomerCreated = (
  params: IsAccessPaysuiteCustomerCreatedType
) => {
  const { ApplicantID } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.isAccessPaysuiteCustomerCreated, {
      // UserInfoNew,
      ApplicantID,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCreateCustomerType = {
  ApplicantID: string;
  Customer: {
    Title: string;
    FirstName: string;
    Surname?: string;
    DateOfBirth: string | null;
    Email: string;
    HomePhoneNumber?: string;
    WorkPhoneNumber?: string;
    MobilePhoneNumber?: string;
    AddressLine1: string;
    AddressLine2?: string;
    AddressLine3?: string;
    AddressLine4?: string;
    PostCode: string;
    AccountHolderName: string;
    AccountNumber: string;
    SortCode: string;
  };
};

// export const accessPaysuiteCreateCustomer = (
//   params: AccessPaysuiteCreateCustomerType
// ) => {
//   const { ApplicantID, Customer } = params;
//   return crmInstance
//     .post(requests.accessPaysuiteCreateCustomer, {
//       UserInfo,
//       ApplicantID,
//       Customer,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const accessPaysuiteCreateCustomer = (
  params: AccessPaysuiteCreateCustomerType
) => {
  const { ApplicantID, Customer } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCreateCustomer, {
      // UserInfoNew,
      ApplicantID,
      Customer,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type IsAccessPaysuiteContractCreatedType = {
  ApplicantID: string;
};

// export const isAccessPaysuiteContractCreated = (
//   params: IsAccessPaysuiteContractCreatedType
// ) => {
//   const { ApplicantID } = params;
//   return crmInstance
//     .post(requests.isAccessPaysuiteContractCreated, {
//       UserInfo,
//       ApplicantID,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const isAccessPaysuiteContractCreated = (
  params: IsAccessPaysuiteContractCreatedType
) => {
  const { ApplicantID } = params;
  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();
  return crmInstanceMiddleServer
    .post(requests.isAccessPaysuiteContractCreated, {
      // UserInfoNew,
      ApplicantID,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCreateContractType = {
  ApplicantID: string;
  ScheduleId: string;
};

// export const accessPaysuiteCreateContract = (
//   params: AccessPaysuiteCreateContractType
// ) => {
//   const { ApplicantID, ScheduleId } = params;
//   return crmInstance
//     .post(requests.accessPaysuiteCreateContract, {
//       UserInfo,
//       ApplicantID,
//       ScheduleId,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const accessPaysuiteCreateContract = (
  params: AccessPaysuiteCreateContractType
) => {
  const { ApplicantID, ScheduleId } = params;
  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCreateContract, {
      // UserInfoNew,
      ApplicantID,
      ScheduleId,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

// APIs for VRP Payment Option
type OrdoVRPCreateMandateType = {
  MandateData: {
    CaseReference: string;
    PeriodTypeLimitAmount: number;
    MaximumIndividualAmount: number;
  };
};

// export const ordoVRPCreateMandate = (params: OrdoVRPCreateMandateType) => {
//   const { MandateData } = params;
//   return crmInstance
//     .post(requests.ordoVRPCreateMandate, {
//       UserInfo,
//       MandateData,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const ordoVRPCreateMandate = (params: OrdoVRPCreateMandateType) => {
  const { MandateData } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.ordoVRPCreateMandate, {
      // UserInfoNew,
      MandateData,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type OrdoCollectPaymentPropsType = {
  CaseReference: string;
  Amount: string;
};

// export const ordoCollectPayment = (params: OrdoCollectPaymentPropsType) => {
//   const { CaseReference, Amount } = params;
//   return crmInstance
//     .post(requests.ordoCollectPayment, {
//       UserInfo,
//       PaymentInfo: {
//         CaseReference,
//         Amount,
//         DueDate: "",
//         ExpiryDate: "",
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const ordoCollectPayment = (params: OrdoCollectPaymentPropsType) => {
  const { CaseReference, Amount } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.ordoCollectPayment, {
      // UserInfoNew,
      PaymentInfo: {
        CaseReference,
        Amount,
        DueDate: "",
        ExpiryDate: "",
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type OrdoVRPCancelMandatePropsType = {
  CaseReference: string;
};

// export const ordoVRPCancelMandate = (params: OrdoVRPCancelMandatePropsType) => {
//   const { CaseReference } = params;
//   return crmInstance
//     .post(requests.ordoVRPCancelMandate, {
//       UserInfo,
//       CaseReference,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const ordoVRPCancelMandate = (params: OrdoVRPCancelMandatePropsType) => {
  const { CaseReference } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.ordoVRPCancelMandate, {
      // UserInfoNew,
      CaseReference,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type AccessPaysuiteCancelContractPropsType = {
  ApplicantID: string;
};

// export const accessPaysuiteCancelContract = (
//   params: AccessPaysuiteCancelContractPropsType
// ) => {
//   const { ApplicantID } = params;
//   return crmInstance
//     .post(requests.accessPaysuiteCancelContract, {
//       UserInfo,
//       ApplicantID,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const accessPaysuiteCancelContract = (
  params: AccessPaysuiteCancelContractPropsType
) => {
  const { ApplicantID } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.accessPaysuiteCancelContract, {
      // UserInfoNew,
      ApplicantID,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetInvoiceTermsPropsType = {
  Reference: string;
};

// export const getInvoiceTerms = (params: GetInvoiceTermsPropsType) => {
//   const { Reference } = params;
//   return crmInstance
//     .post(requests.getInvoiceTerms, {
//       UserInfo,
//       IncludeRefunds: true,
//       Reference,
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getInvoiceTerms = (params: GetInvoiceTermsPropsType) => {
  const { Reference } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.getInvoiceTerms, {
      // UserInfoNew,
      IncludeRefunds: true,
      Reference,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type UpdatePaymentPlanHolidaysPropsType = {
  CaseReference: string;
  ActualDate: string;
  HolidayPeriod: string;
};

// export const updatePaymentPlanHolidays = (
//   params: UpdatePaymentPlanHolidaysPropsType
// ) => {
//   const { CaseReference, ActualDate, HolidayPeriod } = params;
//   return crmInstance
//     .post(requests.updatePaymentPlanHolidays, {
//       UserInfo,
//       pRequest: {
//         CaseReference,
//         ActualDate,
//         HolidayPeriod,
//         OnlyCapitalHoliday: "false",
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const updatePaymentPlanHolidays = (
  params: UpdatePaymentPlanHolidaysPropsType
) => {
  const { CaseReference, ActualDate, HolidayPeriod } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.updatePaymentPlanHolidays, {
      // UserInfoNew,
      pRequest: {
        CaseReference,
        ActualDate,
        HolidayPeriod,
        OnlyCapitalHoliday: "false",
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type CaseRecalculatePaymentPlanPropsType = {
  CaseRef: string;
  ActualDate: string;
  Amount: string;
};

// export const caseRecalculatePaymentPlan = (
//   params: CaseRecalculatePaymentPlanPropsType
// ) => {
//   const { CaseRef, ActualDate, Amount } = params;
//   return crmInstance
//     .post(requests.caseRecalculatePaymentPlan, {
//       UserInfo,
//       pRequest: {
//         CaseRef,
//         RecalculatePaymentPlanBasedOn: "Amount",
//         ExpectedDate: ActualDate,
//         Amount,
//         RepaymentType: null,
//         RepaymentTypeTermUnit: "Monthly",
//         RepaymentMethod: null,
//         ActualDate,
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const caseRecalculatePaymentPlan = (
  params: CaseRecalculatePaymentPlanPropsType
) => {
  const { CaseRef, ActualDate, Amount } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.caseRecalculatePaymentPlan, {
      // UserInfoNew,
      pRequest: {
        CaseRef,
        RecalculatePaymentPlanBasedOn: "Amount",
        ExpectedDate: ActualDate,
        Amount,
        RepaymentType: null,
        RepaymentTypeTermUnit: "Monthly",
        RepaymentMethod: null,
        ActualDate,
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

type GetPostcodeLookupPropsType = { PostCode: string };

// export const getPostcodeLookup = (params: GetPostcodeLookupPropsType) => {
//   const { PostCode } = params;
//   return crmInstance
//     .post(requests.getPostcodeLookup, {
//       UserInfo,
//       pRequest: {
//         PostCode,
//         CountryCode: "GBP",
//       },
//     })
//     .then((response) => response)
//     .catch((err) => {
//       throw new Error(err?.response?.data?.message || err);
//     });
// };

export const getPostcodeLookup = (params: GetPostcodeLookupPropsType) => {
  const { PostCode } = params;

  // Encrypt the data
  // const UserInfoNew = CryptoJS.AES.encrypt(
  //   JSON.stringify(UserInfo),
  //   encryptionKey
  // ).toString();

  return crmInstanceMiddleServer
    .post(requests.getPostcodeLookup, {
      // UserInfoNew,
      pRequest: {
        PostCode,
        CountryCode: "GBP",
      },
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};
