import React from "react";
import { ToastContainer, toast } from "react-toastify";

type ToastMessagePropsType = {
  message: string;
  setMessage: (msg: string) => void;
  toastType?: string;
};

const ToastMessage = (props: ToastMessagePropsType) => {
  const { message, setMessage, toastType } = props;
  React.useEffect(() => {
    if (!message) {
      return;
    }
    toastType
      ? toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      : toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
    setTimeout(() => setMessage(""), 5000);
  }, [message, setMessage, toastType]);
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  );
};

export default ToastMessage;
