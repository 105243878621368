import React, { useCallback, useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { getNewAccessToken, login, loginThroughToken } from "../../api/oauth";
import {
  AUTH_ACTIONS,
  useUpdateAuthContext,
} from "../../context/AuthContext/AuthContext";
import {
  CASEINFO_ACTIONS_TYPES,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { formContainerStyle, formStyle } from "../../utils";
import { Stack } from "@mui/system";
import CCLogo from "../../assets/images/CCLogo.png";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from 'react-ga';

const Login = () => {
  const [toastMessage, setToastMessage] = React.useState("");
  const navigate = useNavigate();
  const authDispatch = useUpdateAuthContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const location = useLocation();
  const from = location.state?.from || "/";
  const [displayFlashScreen, setDisplayFlashScreen] = React.useState(
    from?.pathname ? true : false
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter valid email.")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password should be more than 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login({
        email: values.email,
        password: values.password,
      })
        .then((response) => {
          if (response?.status === 200 && response?.data?.accessToken) {
            authDispatch({ type: AUTH_ACTIONS.LOGIN, payload: response.data });
            return navigate("/home");
          } else {
            setToastMessage(response?.data?.message || "Something went wrong");
          }
        })
        .catch((err) =>
          setToastMessage(err?.message || "Something went wrong")
        );
    },
  });

  const getToken = useCallback(() => {
    const userData = JSON.parse(localStorage?.getItem("uAuth") || "{}");
    getNewAccessToken(userData?.refreshToken)
      .then((response) => {
        if (response?.data?.accessToken) {
          authDispatch({
            type: AUTH_ACTIONS.GOTTOKEN,
            payload: response?.data,
          });
          return navigate(
            from?.pathname
              ? from?.pathname
              : `${
                  location.pathname !== "/login"
                    ? location.pathname !== "/signup"
                      ? location.pathname
                      : "/home"
                    : "/home"
                }`
          );
        }
      })
      .catch((err) => {
        authDispatch({ type: AUTH_ACTIONS.LOGOUT });
        caseInfoDispatch({ type: CASEINFO_ACTIONS_TYPES.REMOVE_DETAILS });
        setDisplayFlashScreen(false);
        return setToastMessage(err?.message || "Something went wrong");
      });
  }, [
    authDispatch,
    caseInfoDispatch,
    from?.pathname,
    location.pathname,
    navigate,
  ]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    from?.pathname && setDisplayFlashScreen(true);
    const userData = JSON.parse(localStorage?.getItem("uAuth") || "{}");
    if (localStorage?.getItem("uAuth")) {
      loginThroughToken(userData?.accessToken)
        .then((response) => {
          if (response?.data?.status) {
            authDispatch({
              type: AUTH_ACTIONS.LOGINTHROUGHTOKEN,
              payload: userData,
            });

            return navigate(
              from?.pathname
                ? from?.pathname
                : `${
                    location.pathname !== "/login"
                      ? location.pathname !== "/signup"
                        ? location.pathname
                        : "/home"
                      : "/home"
                  }`
            );
          }
        })
        .catch((err) => {
          if (err?.message === "Token is expired.") {
            getToken();
            return;
          }
          authDispatch({ type: AUTH_ACTIONS.LOGOUT });
          caseInfoDispatch({ type: CASEINFO_ACTIONS_TYPES.REMOVE_DETAILS });
          setDisplayFlashScreen(false);
          return setToastMessage(err?.message || "Something went wrong");
        });
    } else {
      setDisplayFlashScreen(false);
    }
  }, [
    navigate,
    authDispatch,
    from?.pathname,
    location.pathname,
    getToken,
    formik.values,
    caseInfoDispatch,
  ]);

  return (
    <>
      {!displayFlashScreen ? (
        <ImageContainer>
          <ToastMessage message={toastMessage} setMessage={setToastMessage} />
          <Box
            sx={{
              ...formContainerStyle,
              padding: "50px 0 25px 0",
              minWidth: "357.5px",
            }}
            flexDirection="column"
          >
            <Typography variant="h6" color="primary">
              LOGIN
            </Typography>
            <Box sx={{ ...formStyle }}>
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-1 justify-center items-center flex-1"
              >
                <TextField
                  placeholder="Email"
                  variant="outlined"
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    (formik.touched.email && formik.errors.email) || " "
                  }
                />
                <TextField
                  placeholder="Password"
                  variant="outlined"
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    (formik.touched.password && formik.errors.password) || " "
                  }
                  sx={{ maxWidth: 279 }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  color="secondary"
                >
                  Login
                </Button>
                <Link
                  to="/request-for-reset-password"
                  className="!mt-8  !self-start !text-xs"
                >
                  <Typography>Reset Password</Typography>
                </Link>
              </form>
              <Typography sx={{ fontSize: "10px", alignSelf: "flex-start" }}>
                Don’t currently have an account?
              </Typography>
              <Link to="/initial-details" className="w-full">
                <Button
                  sx={{ mt: 1 }}
                  color="primary"
                  variant="contained"
                  type="button"
                  fullWidth
                  // onClick={() => navigate("/get-started")}
                >
                  Sign up
                </Button>
              </Link>
            </Box>
          </Box>
        </ImageContainer>
      ) : (
        <Stack
          direction="column"
          width="100vw"
          height="100vh"
          alignItems="center"
          justifyContent="center"
          padding="15px 0"
        >
          <Box
            className="!mb-5"
            component="img"
            src={CCLogo}
            sx={{
              width: { sm: "100px", xs: "100px" },
              height: { sm: "100px", xs: "100px" },
            }}
          />
          <CircularProgress size={20} />
        </Stack>
      )}
    </>
  );
};

export default Login;
