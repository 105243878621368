import React from "react";
import { Button, Typography, FormHelperText } from "@mui/material";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { useFormik } from "formik";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import CenterColumnContainer from "../../components/Containers";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack/Stack";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import { updatePaymentPlanHolidays } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { calculateLoanCost, currencyFormat } from "../../utils";

const PaymentHoliday = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const monthlyRep = CustomerDetails?.[CustomerDetails?.ApplicantID]?.MonthlyPayment
  const Term = CustomerDetails?.[CustomerDetails?.ApplicantID]?.Term
  const loanCost = calculateLoanCost(
    location?.state?.caseDetails?.NetClaim,
    location?.state?.caseDetails?.Term,
    location?.state?.caseDetails?.InterestYearly
  );
  const holidayPeriodMarks = [
    {
      value: 1,
      label: "1 Month",
    },

    {
      value: 3,
      label: "3 Months",
    },
  ];

  const formik = useFormik({
    initialValues: {
      holidayPeriod: 1,
    },
    onSubmit: (values) => {
      if (CustomerDetails?.[CustomerDetails?.ApplicantID])
        updatePaymentPlanHolidays({
          CaseReference: CustomerDetails?.ApplicantID,
          ActualDate:
            CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate,
          HolidayPeriod: values?.holidayPeriod.toString(),
        })
          .then((responseData) => {
            if (responseData?.data?.d?.SuccessCode === 0)
              return navigate("/home");
            else
              return setToastMessage(
                responseData?.data?.d?.Message || "Something went wrong"
              );
          })
          .catch((err) => {
            setToastMessage(
              err?.data?.d?.Message || err?.message || "Something went wrong"
            );
          });
    },
  });
  React.useEffect(() => {
    if (!location?.state?.caseDetails) return navigate("/home");
  }, [location?.state?.caseDetails, navigate]);
  return (
    <CenterLayoutContainer pageTitle="PAYMENT HOLIDAY">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      {/* <CenterColumnContainer flex="1"> */}
        <Stack
          direction="column"
          flex="1"
          justifyContent="Left"
          width={{ sm: "auto", xs: "100%" }}
        >
          <Typography className="!text-sm !mt-10">
          {`You are allowed to a 1 month payment holiday to your loan and the effects of this on your current loan would mean a increase of £${monthlyRep} to your monthly amount and the new loan term would be ${Term} months.`}
          </Typography>
          <Button
        variant="contained"
        sx={{
          width: { sm: 248, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
          alignSelf: "left",
        }}
        onClick={() => formik.handleSubmit()}
      >
        Apply Holiday
      </Button>
          <form
            className="flex flex-col flex-1 justify-center p-5"
            onSubmit={formik.handleSubmit}
          >
            {/* <CustomSlider
              className="!mt-10"
              min={1}
              max={1}
              step={1}
              marks={holidayPeriodMarks}
              defaultValue={formik.values.holidayPeriod}
              valueLabelFormat={(value: number) =>
                value > 1 ? `${value} Months` : `${value} Month`
              }
              id="holidayPeriod"
              name="holidayPeriod"
              value={formik.values.holidayPeriod}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            /> */}
            <FormHelperText className="!text-red-600">
              {(formik.touched.holidayPeriod && formik.errors.holidayPeriod) ||
                " "}
            </FormHelperText>
          </form>
          {/* <Typography className="!text-xs !mt-2">
            Monthly Repayment: {currencyFormat(Number(loanCost?.monthlyRep))}
          </Typography> */}
          {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">MONTHLY REPAYMENT</Typography>
          <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.monthlyRep))}
          </Typography>
          </Stack> */}
          {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL INTEREST</Typography>
          <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.interestRate))}
          </Typography>
          </Stack> */}
          {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL COST OF LOAN</Typography>
          <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.totalCost))}
          </Typography>
          </Stack> */}
          {/* <Typography className="!text-xs !mt-2">
            Total Interest: {currencyFormat(Number(loanCost?.interestRate))}
          </Typography>
          <Stack direction="column" flex="1">
            <Typography sx={{ fontSize: "12px", mt: 8 }}>
              New Total Cost of Loan
            </Typography>
            <Typography
              className="!text-2xl !mt-2 !font-bold"
              color={(theme) => theme.palette.primary.light}
            >
              {currencyFormat(Number(loanCost?.totalCost))}
            </Typography>
          </Stack> */}
        </Stack>
        {/* <NeedHelpLink /> */}
      {/* </CenterColumnContainer> */}
    </CenterLayoutContainer>
  );
};

export default PaymentHoliday;
