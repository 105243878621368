import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import Stack from "@mui/material/Stack";

const ImportantInfo = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/pre-agreement-doc");
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="IMPORTANT"
      handleClick={handleSubmit}
    >
      <Stack
        direction="column"
        className="!mt-12"
        display="flex"
        flex="1"
        justifyContent="center"
        sx={{ p: " 0 22px 0 22px" }}
      >
        <Typography className="!text-sm">Custom Credit Limited</Typography>
        <Typography className="!text-sm !mt-3">
          An explanation about your loan
        </Typography>
        <Typography className="!text-sm !mt-3">
          The information below sets out what you need to know about the
          features of the Custom Credit loan and the terms of
          the agreement you are considering entering into to help you make an
          informed decision
        </Typography>
        <Typography className="!text-sm !mt-3">
          If you have any questions about the agreement, or whether it is
          appropriate to your circumstances, please e-mail us on
          hello@customredit.co.uk and we'll get right back to you; we'd be happy
          to talk and provide any further information or explanation you
          require.
        </Typography>
        <Typography className="!text-sm !mt-3">
          Pre Contract Information This document contains a lot of important
          information about the key features of your credit agreement with us.
        </Typography>
      </Stack>
    </ImageLayoutContainer>
  );
};

export default ImportantInfo;
