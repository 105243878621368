import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type BackButtonProps = {
  className?: string;
  pageName?: string;
  goBackRoute?: string;
  style?: any;
};
const BackButton = (props: BackButtonProps) => {
  const navigate = useNavigate();
  const handleClick = () =>
    props?.goBackRoute
      ? navigate(props?.goBackRoute)
      : window.location.pathname === "/employment" &&
        window?.history?.state?.usr?.from === "/previous-address"
      ? navigate(-2)
      : navigate(-1);

  return (
    <Typography
      onClick={handleClick}
      sx={{
        padding: "4px",
        color: "#fff",
        borderRadius: "4px",
        // fontSize: "14px",
        backgroundColor: (theme) => theme.palette.primary.main,
        opacity: "0.7",
        cursor: "pointer",
        width: "23px",
      }}
      style={{ ...props?.style }}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: 15, display: "block" }} />
    </Typography>
  );
};

export default BackButton;
