import React, {useEffect} from "react";
import { Typography } from "@mui/material";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import { useNavigate } from "react-router-dom";
import CenterColumnContainer, {
  SmallWidthColumnContainer,
} from "../../components/Containers";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";
import IsEmptyOrValueUpdated, {additionalUrlParams, getUrlParam} from "../../utils";
import { updateCase } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { FormikHelpers } from "formik";

const Employment = () => {
  const { EmploymentFields = {}, ...caseInfoState } = useCaseInfoContext();
  // const {
  //   EmploymentType = "",
  //   TypeOfContract = "",
  //   HoursWorked = "",
  // } = EmploymentFields;

  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [EmploymentType, setEmploymentType] = React.useState("");
  const [TypeOfContract, setTypeOfContract] = React.useState("");
  const [HoursWorked, setHoursWorked] = React.useState("");
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const [showPartTimeFields, setShowPartTimeFields] = React.useState(false);

  const handleSubmit = (
    values: any,
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => {
    const { setErrors } = formikHelpers;
    if (values?.EmploymentType === "Part time") {
      if (!values?.TypeOfContract && !values?.HoursWorked) {
        return setErrors({
          TypeOfContract: "This field is required",
          HoursWorked: "This field is required",
        });
      }
      if (!values?.TypeOfContract) {
        return setErrors({
          TypeOfContract: "This field is required",
        });
      }
      if (!values?.HoursWorked) {
        return setErrors({
          HoursWorked: "This field is required",
        });
      }
    }
    if (IsEmptyOrValueUpdated(EmploymentFields, values)) {
        updateCase({
            ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
            CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
            Payload: {
                MainApplicant: {
                    EmploymentDetails: {
                        OccupationType: values?.TypeOfContract,
                        EmploymentStatus: values?.EmploymentType,
                        PartTimeHoursWorked: values?.HoursWorked,
                        // EmploymentStatus:
                        //   values?.EmploymentType !== "Part time"
                        //     ? ""
                        //     : values?.EmploymentType,
                        // PartTimeHoursWorked:
                        //     values?.EmploymentType !== "Part time"
                        //         ? ""
                        //         : values?.HoursWorked,
                    },
                },
            },
        })
            .then((responseData: any) => {
                console.log(values);
                if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
                    caseInfoDispatch({
                        type: CASEINFO_ACTIONS_TYPES.ADD_EMPLOYMENT,
                        payload: {
                            ...values,
                            EmploymentType:
                                values?.EmploymentType !== "Part time"
                                    ? ""
                                    : values?.EmploymentType,
                            HoursWorked:
                                values?.EmploymentType !== "Part time"
                                    ? ""
                                    : values?.HoursWorked,
                        },
                    });
                    navigate("/finances1" + additionalUrlParams());
                } else {
                    setToastMessage(
                        responseData?.data?.d?.UpdateResult?.Message ||
                        "Something went wrong"
                    );
                }
            })
            .catch((err) =>
                setToastMessage(
                    err?.data?.d?.UpdateResult?.Message ||
                    err?.message ||
                    "Something went wrong"
                )
            );
    }
  };

    const textFields = [
    {
      fieldName: "EmploymentType",
      defaultValue: EmploymentType,
      label: "Employment",
      type: "select",
      options: [
        {
          value: "56017",
          title: "Full time",
        },
        {
          value: "56018",
          title: "Part time",
        },
        {
          value: "56022",
          title: "Self employed",
        },
        {
          value: "56023",
          title: "Unemployed",
        },
        {
          value: "56021",
          title: "Retired",
        },
        {
          value: "56011",
          title: "Homemaker",
        },
        {
          value: "56012",
          title: "Student",
        },
      ],
      additionalElement: (
        <>
          <Typography className="!w-5/6 !text-xs !mb-4">
            What is your current employment status?
          </Typography>
        </>
      ),
      customChangeHandler: (e: any) => {
        // * Display additional inputs only if the employment type selected has a value from "56017", "56018".
        if (["56017", "56018", "Full time", "Part time" ].includes(e?.target?.value)) {
          if (!showPartTimeFields) return setShowPartTimeFields(true);
        } else {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_EMPLOYMENT,
            payload: {
              TypeOfContract: "",
              HoursWorked: "",
            },
          });
          if (showPartTimeFields) return setShowPartTimeFields(false);
        }
      },
    },
    {
      fieldName: "TypeOfContract",
      defaultValue: TypeOfContract,
      label: "Type of Contract",
      type: "select",
      options: [
        {
          value: "Permanent contract",
          title: "Permanent contract",
        },
        {
          value: "Fixed term contract",
          title: "Fixed term contract",
        },
        {
          value: "Zero hours contract",
          title: "Zero hours contract",
        },
      ],
      additionalElement: (
        <>
          <Typography className="!w-5/6 !text-xs !mb-4">
            What type of contract are you on?
          </Typography>
        </>
      ),
      visible: showPartTimeFields,
      notValidate: true,
    },
    {
      fieldName: "HoursWorked",
      defaultValue: HoursWorked,
      label: "Hours Worked",
      type: "number",
      additionalElement: (
        <>
          <Typography className="!w-5/6 !text-xs !mb-4">
            How many hours a week do you work?
          </Typography>
        </>
      ),
      customValidation: getYup
        .number()
        .min(0)
        .required(`This field is required`),
      visible: showPartTimeFields,
      notValidate: true,
    }
];


  const { isLoading } = useFetchingInitialData();

  React.useEffect(() => {
      //   EmploymentType = "",
      //   TypeOfContract = "",
      //   HoursWorked = "",
    setEmploymentType(caseInfoState?.CaseList?.[0]?.MainApplicant?.EmploymentDetails?.EmploymentStatus || "");
    setTypeOfContract(caseInfoState?.CaseList?.[0]?.MainApplicant?.EmploymentDetails?.OccupationType || "");
    setHoursWorked(caseInfoState?.CaseList?.[0]?.MainApplicant?.EmploymentDetails?.PartTimeHoursWorked || "");
  }, [caseInfoState]);


  return (
    <ImageLayoutContainer title="EMPLOYMENT" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <CenterColumnContainer>
        <SmallWidthColumnContainer
          flex="1"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* <Typography sx={{ fontSize: "12px", mt: 3 }}>
            Great to meet you!
          </Typography> */}
          <Typography className="!w-3/4 !text-xs !mt-4">
            We have some questions regarding your job and income.
          </Typography>
          <Typography className="!w-5/6 !text-xs !mt-4">
            These will help us better understand your personal circumstance and
            help us set the right 'Flex' options.
          </Typography>
        </SmallWidthColumnContainer>
      </CenterColumnContainer>
      <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
    </ImageLayoutContainer>
  );
};

export default Employment;
