import InitialDetials from "./pages/InitialDetials/InitialDetials";
import CurrentAddress from "./pages/CurrentAddress/CurrentAddress";
import Dashboard from "./pages/Dashboard/Dashboard";
import Employment from "./pages/Employment/Employment";
import LoanInitialDetails from "./pages/LoanInitialDetails/LoanInitialDetails";
import MarketingOptIns from "./pages/MarketingOptIns/MarketingOptIns";
import PreviousAddress from "./pages/PreviousAddress/PreviousAddress";
import Verification from "./pages/Verification/Verification";
import Finances1 from "./pages/Finances1/Finances1";
import Finances2 from "./pages/Finances2/Finances2";
import BankDetails from "./pages/BankDetails/BankDetails";
import BankDetails2 from "./pages/BankDetails2/BankDetails2";
import AcceptInitialDataTerms from "./pages/AcceptInitialDataTerms/AcceptInitialDataTerms";
import OpenBanking from "./pages/OpenBanking/OpenBanking";
import OpenBanking2 from "./pages/OpenBanking2/OpenBanking2";
import OpenBanking3 from "./pages/OpenBanking3/OpenBanking3";
import OOMOHOOOLA from "./pages/OOMOHOOOLA/OOMOHOOOLA";
import WelcomAfterOpenBanking from "./pages/WelcomAfterOpenBanking/WelcomAfterOpenBanking";
import PreLoanText from "./pages/PreLoanText/PreLoanText";
import LoanDetails from "./pages/LoanDetails/LoanDetails";
import FlexExplained from "./pages/FlexExplained/FlexExplained";
import FlexExplainedGraph from "./pages/FlexExplainedGraph/FlexExplainedGraph";
import PreAgreementDoc from "./pages/PreAgreementDoc/PreAgreementDoc";
import ImportantInfo from "./pages/ImportantInfo/ImportantInfo";
import LoanAgreementDoc from "./pages/LoanAgreementDoc/LoanAgreementDoc";
import Congratulations from "./pages/Congratulations/Congratulations";
import PaymentVerify from "./pages/PaymentVerify/PaymentVerify";
import DisbursePayment from "./pages/DisbursePayment/DisbursePayment";
import PaymentOption from "./pages/PaymentOption/PaymentOption";
// import ORDOProcess from "./pages/ORDOProcess/ORDOProcess";
import FailedCreditChecks from "./pages/FailedCreditChecks/FailedCreditChecks";
import CaseScreen from "./pages/CaseScreen/CaseScreen";
import PaymentChanges from "./pages/PaymentChanges/PaymentChanges";
import PaymentHoliday from "./pages/PaymentHoliday/PaymentHoliday";
import MakePaymentOptions from "./pages/MakePaymentOptions/MakePaymentOptions";
import CancelCurrentPaymentOption from "./pages/CancelCurrentPaymentOption/CancelCurrentPaymentOption";
import UpdateDetails from "./pages/UpdateDetails/UpdateDetails";
import UpdateDetails2 from "./pages/UpdateDetails2/UpdateDetails2";
import OpenBanking1 from "./pages/OpenBanking1/OpenBanking1";
import PaymentOptions from "./pages/PaymentOptions/PaymentOptions";

const routes = [
  {
    path: "/initial-details",
    element: InitialDetials,
    message:
      "We just need to take a few details to get your loan application underway. \n\nBy adding a password you will be able to save your progress if you need to come back to the application.",
    skipCaseChecking: true,
  },
  {
    path: "/loan-initial-details",
    element: LoanInitialDetails,
    message:
      "Please tell us how much you’d like to borrow and for how long. We'll then provide an example of the total cost of the loan and the normal monthly repayments. \n\nWe’ll tell you about your individual personal flexible payment options a little later in the application process.",
    skipCaseChecking: true,
  },
  {
    path: "/current-address",
    element: CurrentAddress,
    message:
      "Please provide your personal details which we'll use to perform a soft credit search once you have given us permission.",
  },
  {
    path: "/previous-address",
    element: PreviousAddress,
    message:
      "We need at least three years of address history. \n\nPlease provide the additional information.",
  },
  {
    path: "/employment",
    element: Employment,
    message:
      "We need to know about your current employment status. \n\nPlease confirm the type of contract and your typical hours per week if requested.",
  },
  // {
  //   path: "/verification",
  //   element: Verification,
  //   message: "",
  // },
  {
    path: "/finances1",
    element: Finances1,
    message:
      "Please give us some information about your income to help us with some affordability checks. \n\nLet us also know how you’d like to spend your Custom Credit loan!",
  },
  // {
  //   path: "/finances2",
  //   element: Finances2,
  //   message: "",
  // },
  {
    path: "/bank-details",
    element: BankDetails,
    message:
      "Good news! \n\nWe can offer you the loan that you have requested but we need some more information to get you the best price and to do our affordability checks. \n\nAfter you have entered these details you will be connected directly with your bank to authorise the open banking connection. \n\nOnce connected we'll continue progressing the application. \n\nFor more information please visit our Open Banking FAQ's.",
  },
  {
    path: "/bank-details2",
    element: BankDetails2,
    message:
      "We’ve completed our initial checks and we need more information to make a decision. \n\nAfter you have entered these details you will be connected directly with your bank to authorise the open banking connection. \n\nOnce connected we'll continue progressing the application. \n\nFor more information please visit our Open Banking FAQ's.",
  },
  {
    path: "/accept-initial-data-terms",
    element: AcceptInitialDataTerms,
    message: ".",
  },
  {
    path: "/marketing-opt-ins",
    element: MarketingOptIns,
    message:
      "From time to time, we may wish to send you exclusive offers, promotions and other exciting updates so please take a moment to choose your preferred marketing options. You are welcome to select as many or as few of these options as you would like, or none at all if you prefer not to receive any marketing information from us. \n\nPlease make your choices, if any, and then proceed by clicking the Next button below.",
  },
  {
    path: "/open-banking",
    element: OpenBanking,
    message:
      "Connecting securely to your bank. \n\nPlease proceed through your banks security verification. \n\nOnce authorised we’ll progress the application. \n\nThank you!",
  },
  {
    path: "/open-banking2",
    element: OpenBanking2,
    message: ".",
  },
  {
    path: "/open-banking3",
    element: OpenBanking3,
    message:
      "We’re checking to make sure you and your bank have authorised the open banking connection. \n\nWe’ll check but if you’re confident that the connection has been approved, please click refresh.",
  },
  {
    path: "/oomohoola",
    element: OOMOHOOOLA,
    message: ".",
  },
  {
    path: "/welcome-after-open-banking",
    element: WelcomAfterOpenBanking,
    message: ".",
  },
  {
    path: "/pre-loan-text",
    element: PreLoanText,
    message: ".",
  },
  // {
  //   path: "/loan-details",
  //   element: LoanDetails,
  //   message: "Completed initial checks and we think we can provide you with the following loan. \nWe now need your open banking details to run our full affordability checks and get the best personal rate for you.",
  // },
  {
    path: "/loan-details",
    element: LoanDetails,
    message:
      "Custom Credit wants to deliver a loan that is tailored to you. Let us know if you’d like to change anything before we finalise your loan. \n\nIf you always make your Normal Monthly Payment (NMP) then your term and total cost of credit will be as displayed on the right. \n\nIf you pay at least the Flex Low but under the NMP you won’t immediately go into arrears but you may extend the term and increase the cost of the credit. \n\nIf you pay more than the NMP you may reduce the term and decrease the cost of credit. If you were behind schedule on your loan, we would look to take more than the NMP to help you catch up but we'll never take more than the Flex Max or more than we think you can afford.",
  },
  {
    path: "/flex-explained",
    element: FlexExplained,
    message: ".",
  },
  {
    path: "/flex-explained-graph",
    element: FlexExplainedGraph,
    message: ".",
  },
  {
    path: "/important-info",
    element: ImportantInfo,
    message: ".",
  },
  {
    path: "/pre-agreement-doc",
    element: PreAgreementDoc,
    message:
      "Pease read the Pre Contract Information It is Important that you understand the loan terms you are about to enter into. \n\nIf you have any questions press ‘NEED HELP?’",
  },
  {
    path: "/loan-agreement-doc",
    element: LoanAgreementDoc,
    message:
      "We have completed all of our checks. \n\nHere is your credit agreement, if you agree to all the terms please click 'next' and we'll register your loan with the Credit Reference Agencies.",
  },
  {
    path: "/congratulations",
    element: Congratulations,
    message: "GIF",
    imageURL:
      "https://mycustomcredit.co.uk/Temp/party-popper.gif",
    skipCaseChecking: true,
  },
  // {
  //   path: "/payment-option",
  //   element: PaymentOption,
  //   message: "Time to set up your ongoing payment.",
  // },
  // { path: "/ordo-process", element: ORDOProcess },
  {
    path: "/payment-options",
    element: PaymentOptions,
    message: "Time to set up your ongoing payment.",
  },
  {
    path: "/payment-verify",
    element: PaymentVerify,
    message: "Please confirm you have set up your payment method",
  },
  {
    path: "/disburse-payment",
    element: DisbursePayment,
    message: ".",
  },
  {
    path: "/failed-credit-checks",
    element: FailedCreditChecks,
    message: ".",
  },
];
export const dashboardRoutes = [
  { path: "/home", element: Dashboard },
  { path: "/case/:id", element: CaseScreen },
  { path: "/payment-changes", element: PaymentChanges },
  { path: "/payment-holiday", element: PaymentHoliday },
  { path: "/open-banking1", element: OpenBanking1 },
  { path: "/make-payment-options", element: MakePaymentOptions },
  { path: "/cancel-current-payment", element: CancelCurrentPaymentOption },
  { path: "/update-details", element: UpdateDetails },
  { path: "/update-details2", element: UpdateDetails2 },
];

export default routes;
