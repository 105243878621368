import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

const CustomizedSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 41,
    width: 41,
    background: "linear-gradient(149.66deg, #35ACD2 17.86%, #01C6D1 60.21%)",
    border: "5px solid #FFFFFF",
    boxShadow: "0px 0px 10px #A156BC",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 60,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-markLabel": {
    top: "-30px",
    fontSize: "11px",
    letterSpacing: "0.23em",
  },
  "& .MuiSlider-mark": {
    height: 1,
    width: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
  },
}));

type CustomSlideProp = {
  className?: string;
  marks: { value: number; label: string }[];
  defaultValue: number;
  getAriaLabel?: (value: number) => string;
  getAriaValueText?: (value: number) => string;
  step: number;
  min: number;
  max: number;
  id: string;
  name: string;
  value: number;
  onChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;
  onBlur: React.FocusEventHandler<HTMLSpanElement> | undefined;
  valueLabelFormat?: (value: number) => React.ReactNode;
};

export default function CustomSlider(props: CustomSlideProp) {
  const {
    marks,
    defaultValue,
    getAriaLabel,
    getAriaValueText,
    step,
    max,
    min,
    id,
    name,
    value,
    onChange,
    onBlur,
    className,
    valueLabelFormat,
  } = props;
  return (
    <Stack direction="row" flex="1" justifyContent="center">
      <CustomizedSlider
        sx={{ width: 275 }}
        className={className}
        size="small"
        aria-label="slider"
        defaultValue={defaultValue}
        getAriaLabel={getAriaLabel}
        getAriaValueText={getAriaValueText}
        valueLabelFormat={valueLabelFormat}
        marks={marks}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay={value === min || value === max ? "auto" : "on"}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Stack>
  );
}
