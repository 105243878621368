import { FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const CustomDatePicker = (props) => {
  const { item } = props;
  const { fieldName, label, value, onChange, error, helperText, touched, ref } =
    item;
  return (
    <FormControl className="!w-full" error={error && touched ? true : false}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputRef={ref}
          value={value}
          onChange={(newValue) => {
            onChange &&
              newValue &&
              onChange({
                target: { name: fieldName, value: newValue },
              });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error && touched ? true : false}
              fullWidth
              inputProps={{ ...params.inputProps, className: "!w-full" }}
            />
          )}
          label={label ? label : fieldName.toUpperCase()}
          inputFormat="dd-MM-yyyy"
        />
      </LocalizationProvider>
      <FormHelperText error={error && touched ? true : false}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomDatePicker;
