import _ from "lodash";
import moment from "moment";
import routes from "../routes";
import CryptoJS from 'crypto-js';

const IsEmptyOrValueUpdated = (previousValue: any, currentValue: any) => {
  return (
    !Object.keys(previousValue).length ||
    !_.isEqual(previousValue, currentValue)
  );
};

export default IsEmptyOrValueUpdated;

export const calculateLoanCost = (
  amount: number,
  term: number,
  interestYearly: number
) => {
  const totalLoan = amount,
    loanRate = interestYearly / 100,
    periodsInAYear = 12,
    loanTerm = term;

  const monthlyRep =
    (totalLoan * Math.pow(loanRate / periodsInAYear + 1, loanTerm) * loanRate) /
    periodsInAYear /
    (Math.pow(loanRate / periodsInAYear + 1, loanTerm) - 1);

  const interestRate = (monthlyRep * loanTerm - totalLoan).toFixed(2);

  const totalLoanCost = (Number(amount) + Number(interestRate)).toFixed(2);

  return {
    monthlyRep: monthlyRep.toFixed(2),
    interestRate,
    totalCost: totalLoanCost,
  };
};

export const calculateLoanCostFromMonthly = (
  amount: number,
  term: number,
  interestYearly: number
) => {
  const totalLoan = amount,
    loanRate = interestYearly / 100,
    periodsInAYear = 12,
    loanTerm = term;

  const monthlyRep =
    (totalLoan * Math.pow(loanRate / periodsInAYear + 1, loanTerm) * loanRate) /
    periodsInAYear /
    (Math.pow(loanRate / periodsInAYear + 1, loanTerm) - 1);

  const interestRate = (monthlyRep * loanTerm - totalLoan).toFixed(2);

  const totalLoanCost = (Number(amount) + Number(interestRate)).toFixed(2);

  return {
    monthlyRep: monthlyRep.toFixed(2),
    interestRate,
    totalCost: totalLoanCost,
  };
};

export const formContainerStyle = {
    display: "flex",
    flex: { xl: "0.6", lg: "0.6", sm: "0.6", xs: "1" },
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "50px 22px 25px 22px",
  },
  formStyle = {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: { xl: "30%", lg: "30%", sm: "30%", xs: "70%" },
  };

export const getRandomItem = (items: string[] | number[] | any[]) => {
  return items[Math.floor(Math.random() * items.length)];
};

export const getStartDateAndEndDate = (month: string) => {
  var monthNumber =
    [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ].indexOf(month.toLowerCase()) + 1;
  if (monthNumber !== 0)
    return {
      startDate: moment(new Date(new Date().getFullYear(), monthNumber, 0))
        .startOf("month")
        .format("DD-MM-YYYY"),
      endDate: moment(new Date(new Date().getFullYear(), monthNumber, 0))
        .endOf("month")
        .format("DD-MM-YYYY"),
    };
  else
    return {
      startDate: "",
      endDate: "",
    };
};

export const findNextPaymentDate = (PaymentPlanDetail: any) => {
  var NextPaymentDate: Date | string = "";
  const convertedDates = PaymentPlanDetail?.map((item: any) => {
    const date = new Date(+item?.DueDate.replace(/\D/g, ""));
    return date;
  }).map((i: any) => {
    if (i > new Date()) {
      return i;
    }
    return "";
  });
  convertedDates.map((i: any) => {
    convertedDates.map((j: any) => {
      if (!NextPaymentDate && i && i <= j) {
        NextPaymentDate = i;
      }
      return j;
    });
    return i;
  });
  return NextPaymentDate && moment(NextPaymentDate).format("DD-MM-YYYY");
};

export const getEligibilityForHolidays = (CaseDetails: any) => {
  return CaseDetails?.DynamicFieldDetail?.find(
    (item: any) => item?.FieldNo === "16"
  )?.FieldValue === "Yes"
    ? true
    : false;
};

export const currencyFormat = (num: number = 0) => {
  return "£" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getUrlParam = (param: string = "") => {

    console.log('getUrlParam=' + param);
    const queryParameters = new URLSearchParams(window.location.search);
    const r = queryParameters.get(param);

    if(r) {
        console.log('early exit ' + param + '=' + r);
        return r;
    } else {
        // try to decode
        console.log('decoding');
        const url = window.location.search;
        if(!param || !url || url[0]!=='?') return "";

        let secretKey = "dshruxanqkxaldeicnsana";
        let bytes  = CryptoJS.AES.decrypt(url.substring(1), secretKey);
        let inputString = bytes.toString(CryptoJS.enc.Utf8);

        const regex = /ApplicantID=([^&]+)&CustomerReference=([^&]+)/;

        const matches = inputString.match(regex);
        if (matches) {
            const applicantID = matches[1];
            const customerReference = matches[2];
            if(param==='ApplicantID') {
                console.log("Applicant ID:", applicantID);
                return applicantID;
            }
            if(param==='CustomerReference') {
                console.log("CustomerReference:", customerReference);
                return customerReference;
            }
        } else {
            return "";
        }

        return "";
    }

    /*
    console.log('in function');
    return "";
     */
};

export const additionalUrlParams = () => {
    const ApplicantID = getUrlParam('ApplicantID');
    const CustomerReference = getUrlParam('CustomerReference');
    // console.log(ApplicantID, CustomerReference);
    return (ApplicantID && CustomerReference) ? "?ApplicantID=" + ApplicantID + "&CustomerReference=" + CustomerReference : "";
};

export const addressFormat = (props: any = []) => {
  const filterUndefinedValue = props.filter(
    (item: any) => item !== undefined && item !== ""
  );
  return filterUndefinedValue.join(", ");
};

export const getLeftSideData = (route = "") => {
  const defaultMessage = "Great to see you!";
  const defaultImageURL = "";

  try {
    const foundRoute = routes?.find((element) => element.path === route);
    if (foundRoute) {
      const { message = "", imageURL = "" } = foundRoute;
      return {
        message: message || defaultMessage,
        imageURL: imageURL || defaultImageURL,
      };
    }
  } catch (error) {
    return {
      message: defaultMessage,
      imageURL: defaultImageURL,
    };
  }
};
