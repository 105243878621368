import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CenterColumnContainer, {
  SmallWidthColumnContainer,
} from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const DisbursePayment = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    // navigate("/payment-option");
    navigate("/home");
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="Processing Payment"
      handleClick={handleSubmit}
    >
      <CenterColumnContainer>
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start" alignItems="flex-start">
        <Typography className="!mt-4 !text-xs !w-3/4">
        We are currently processing your <br />loan and we intend to pay out the money in the next few days. If you would like to get an update on progress, please use the "Need Help?" button to see our contact details.
        </Typography>
      </SmallWidthColumnContainer>
      </CenterColumnContainer>
    </ImageLayoutContainer>
  );
};

export default DisbursePayment;
