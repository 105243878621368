import React from "react";
import {
  Typography,
  FormHelperText,
  Checkbox,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SmallWidthColumnContainer } from "../../components/Containers";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import {
  createCase,
  getCase,
  updateCase,
  updateCaseCancellable,
} from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import IsEmptyOrValueUpdated, {
    calculateLoanCost,
    currencyFormat,
    additionalUrlParams, getUrlParam,
} from "../../utils";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import useADPData from "../../hooks/useADPData";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const LoanDetails = () => {
  const navigate = useNavigate();
  const {
    CaseInfo = {},
    LoanInitialDetailsFields = {},
    CustomerDetails = {},
  } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [isCalculating, setIsCalculating] = React.useState(true);

  const [MonthlyPayment, setMonthlyPayment] = React.useState("");
  const [RolledUpInterestForTerm, setRolledUpInterestForTerm] =
    React.useState("");
  const [TotalRepaymentAmount, setTotalRepaymentAmount] = React.useState("");

  const {
    isLoading,
    // ADPData,
    // getADPDataValueByID,
    getADPDataValueByDescription,
  } = useADPData(CustomerDetails?.ApplicantID);
  const HIGHEST_AFFORDABLE_AMOUNT = getADPDataValueByDescription(
      "CAT 14",
      "FlexMax"
    ),
    LOWEST_AFFORDABLE_AMOUNT = getADPDataValueByDescription(
      "CAT 14",
      "FlexPayment"
    );

  const [toastMessage, setToastMessage] = React.useState("");
  const loanAmountMarks = [
    {
      value: 1000,
      label: "£1000",
    },

    {
      value: 10000,
      label: "£10000",
    },
  ];
  const termMarks = [
    {
      value: 12,
      label: "12",
    },

    {
      value: 60,
      label: "60",
    },
  ];

  const validationSchema = yup.object({
    NetClaim: yup
      .number()
      .min(1000, "Minimum amount is 1000.")
      .max(10000, "Maximum amount is 10000."),
  });

  const formik = useFormik({
    initialValues: {
      isSubscribed: false,
      NetClaim:
        Number(CustomerDetails?.[CustomerDetails?.ApplicantID]?.NetClaim) ||
        Number(LoanInitialDetailsFields?.NetClaim),
      Term:
        Number(CustomerDetails?.[CustomerDetails?.ApplicantID]?.Term) ||
        Number(LoanInitialDetailsFields?.Term),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        Number(MonthlyPayment) < Number(LOWEST_AFFORDABLE_AMOUNT) ||
        Number(MonthlyPayment) > Number(HIGHEST_AFFORDABLE_AMOUNT)
      ) {
        return setToastMessage(
          "Please ensure monthly payment is between Flex Low and Flex Max."
        );
      }

      if (IsEmptyOrValueUpdated(CaseInfo, values))
        updateCase({
          ApplicantID: CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
          CustomerReference: CustomerDetails?.CustomerReference  ?? getUrlParam('CustomerReference'),
          Payload: {
            ApplicantID: CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
            NetClaim: values.NetClaim.toString(),
            Term: values.Term.toString(),
            InterestYearly: FINAL_INTEREST_RATE,
            WorkflowStep: "4.2",
            CaseStatus: "Awaiting Customer Acceptance",
          },
        })
          .then((responseData) => {
            if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.UPDATE_CASEINFO,
                payload: {
                  NetClaim: values.NetClaim.toString(),
                  Term: values.Term.toString(),
                },
              });
              return navigate("/pre-agreement-doc" + additionalUrlParams());
            } else {
              setToastMessage(
                responseData?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      else return navigate("/pre-agreement-doc" + additionalUrlParams());
    },
  });
  const [loanCost, setLoanCost] = React.useState(
    calculateLoanCost(
      formik?.values?.NetClaim,
      formik?.values?.Term,
      CustomerDetails?.[CustomerDetails?.ApplicantID]?.InterestYearly
    )
  );

  const FINAL_INTEREST_RATE = formik?.values?.isSubscribed
    ? +getADPDataValueByDescription("CAT 11", "APRValue") - 0.5
    : +getADPDataValueByDescription("CAT 11", "APRValue");

  React.useEffect(() => {

      console.log(formik);
      console.log(CustomerDetails);

    setMonthlyPayment("");
    setRolledUpInterestForTerm("");
    setTotalRepaymentAmount("");

    let InterestYearly = FINAL_INTEREST_RATE;

    // if (formik?.values?.isSubscribed) {
    //   InterestYearly = InterestYearly - 0.5;
    // }

    if (!formik.isValid || (!CustomerDetails?.ApplicantID && !getUrlParam('ApplicantID'))) return;

    setIsCalculating(true);

    updateCaseCancellable({
      ApplicantID: CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
      CustomerReference: CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
      Payload: {
        NetClaim: formik?.values?.NetClaim.toString(),
        Term: formik?.values?.Term.toString(),
        InterestYearly: InterestYearly,
      },
    })
      .then((res) => {
        getCase({
          CaseReference: CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
        }).then((getCaseResponse) => {
          if (Object.keys(getCaseResponse?.data?.d?.CaseApplicant).length) {
            const MonthlyPayment =
              getCaseResponse?.data?.d?.CaseApplicant?.MonthlyPayment;
            const RolledUpInterestForTerm =
              getCaseResponse?.data?.d?.CaseApplicant?.RolledUpInterestForTerm;
            const TotalRepaymentAmount =
              getCaseResponse?.data?.d?.CaseApplicant?.TotalRepaymentAmount;

              const updateState = () => {
                  setMonthlyPayment(MonthlyPayment);
                  setRolledUpInterestForTerm(RolledUpInterestForTerm);
                  setTotalRepaymentAmount(TotalRepaymentAmount);
                  setIsCalculating(false);
              }

              updateState();
            // setMonthlyPayment(MonthlyPayment);
            // setRolledUpInterestForTerm(RolledUpInterestForTerm);
            // setTotalRepaymentAmount(TotalRepaymentAmount);
            // setIsCalculating(false);
          }
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // do something
        } else {
          // setIsCalculating(false);
        }
      });
  }, [
    CustomerDetails,
    formik?.values?.NetClaim,
    formik?.values?.Term,
    formik?.isValid,
    setIsCalculating,
    formik?.values?.isSubscribed,
    FINAL_INTEREST_RATE,
  ]);

  return (
    <ImageLayoutContainer
      noPadding
      title="LOAN DETAILS"
      handleClick={() => formik.handleSubmit()}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!mt-4 !text-xs !w-3/4">
          {`We think you can afford to pay between £${LOWEST_AFFORDABLE_AMOUNT} (Flex Low) and £${HIGHEST_AFFORDABLE_AMOUNT} (Flex Max) per month`}
        </Typography>
        <form
          className="flex flex-col flex-1 justify-center p-5"
          onSubmit={formik.handleSubmit}
        >
          <CustomSlider
            className="!mt-5"
            min={1000}
            max={10000}
            step={10}
            getAriaLabel={(value: number) => `£${value}`}
            getAriaValueText={(value: number) => `£${value}`}
            marks={loanAmountMarks}
            defaultValue={formik.values.NetClaim}
            id="NetClaim"
            name="NetClaim"
            value={formik.values.NetClaim}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormHelperText className="!text-red-600">
            {(formik.touched.NetClaim && formik.errors.NetClaim) || " "}
          </FormHelperText>

          <CustomSlider
            className="!mt-5"
            marks={termMarks}
            min={12}
            max={60}
            step={1}
            defaultValue={formik.values.Term}
            getAriaLabel={(value: number) => `${value}`}
            getAriaValueText={(value: number) => `${value}`}
            id="Term"
            name="Term"
            value={formik.values.Term}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormHelperText className="!text-red-600">
            {(formik.touched.Term && formik.errors.Term) || " "}
          </FormHelperText>

          <Box display="flex" alignItems="flex-start">
            <Checkbox
              color="secondary"
              aria-label="checkbox"
              icon={<RadioButtonUncheckedIcon color="secondary" />}
              checkedIcon={<CheckCircleIcon />}
              id="isSubscribed"
              name="isSubscribed"
              checked={Boolean(formik.values.isSubscribed)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(event.target.id, event.target.checked);
              }}
            />
            <Typography className="!text-xs !p-2">{`Custom Credit is committed to helping our customers understand how our loans work.
            By ticking this box you consent to engaging with information that we'll send to you. If you agree, we'll give you a discount of 0.5% on the APR of your loan.
            `}</Typography>
          </Box>
        </form>

        {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">MONTHLY REPAYMENT</Typography>
          <Typography
            className="!text-2xl !mt-2"
            color={(theme) => theme.palette.primary.main}
          >
            {currencyFormat(Number(loanCost?.monthlyRep))}
          </Typography>
        </Stack> */}

        <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">MONTHLY REPAYMENT</Typography>
          { false ? (
            <CircularProgress className="!mt-3" size={20} />
          ) : (
            <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
              {currencyFormat(Number(MonthlyPayment))}
              {/* {currencyFormat(Number(loanCost?.monthlyRep))} */}
            </Typography>
          )}
        </Stack>

        <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL INTEREST</Typography>
          {false ? (
            <CircularProgress className="!mt-3" size={20} />
          ) : (
            <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
              {currencyFormat(Number(RolledUpInterestForTerm))}
              {/* {currencyFormat(Number(loanCost?.interestRate))} */}
            </Typography>
          )}
        </Stack>

        {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">LOAN AMOUNT</Typography>
          <Typography
            className="!text-2xl !mt-2"
            color={(theme) => theme.palette.primary.main}
          >
            {currencyFormat(formik.values.NetClaim)}
          </Typography>
        </Stack> */}

        <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">INTEREST RATE</Typography>
          <Typography
            className="!text-2xl !mt-2"
            color={(theme) => theme.palette.primary.main}
          >
            {`${FINAL_INTEREST_RATE}%`}
          </Typography>
        </Stack>

        {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL COST OF LOAN</Typography>
          <Typography
            className="!text-2xl !mt-2"
            color={(theme) => theme.palette.primary.main}
          >
            {currencyFormat(Number(loanCost?.totalCost))}
          </Typography>
        </Stack> */}

        <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL COST OF LOAN</Typography>
          {false ? (
            <CircularProgress className="!mt-3" size={20} />
          ) : (
            <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
              {currencyFormat(Number(TotalRepaymentAmount))}
              {/* {currencyFormat(Number(loanCost?.totalCost))} */}
            </Typography>
          )}
        </Stack>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default LoanDetails;
