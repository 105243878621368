import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext/AuthContext";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../context/CaseContext/CaseContext";
import { getCase, searchCustomers } from "../api/crm";
import {getUrlParam} from "../utils";

const useFetchingInitialData = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const { caseInfoState } = useCaseInfoContext();

  const fetchCaseInfo = useCallback(() => {
    setLoading(true);
    if (user?.customerReference || getUrlParam('CustomerReference'))
      searchCustomers({
        CustomerReference: user?.customerReference ?? getUrlParam('CustomerReference'),
      })
        .then((response) => {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              ...response?.data?.d?.Records?.[0],
            },
          });
          const promises = response?.data?.d?.Records?.[0]?.CustomerCases?.map(
            (item: any) => {
              return getCase({
                CaseReference:
                  item?.CaseRef ||
                  caseInfoState?.CustomerDetails?.ApplicantID ||
                  "CA000072",
              })
                .then((response) => {
                  if (Object.keys(response?.data?.d?.CaseApplicant).length)
                    return response?.data?.d?.CaseApplicant;
                })
                .catch((err) => {
                  console.log(
                    err?.data?.d?.EnquiryResult?.Message ||
                      err?.message ||
                      "Something went wrong"
                  );
                });
            }
          );
          Promise.all(promises)
            .then((result) => {
              const filteredResults = result.filter(
                (item) => typeof item === "object"
              );
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.FETCHED_CASELIST,
                payload: filteredResults,
              });
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                payload: {
                  ApplicantID:
                    filteredResults[filteredResults.length - 1]?.ApplicantID,
                  CustomerReference:
                    filteredResults[filteredResults.length - 1]
                      ?.CustomerReference,
                  [filteredResults[filteredResults.length - 1]?.ApplicantID]: {
                    ...filteredResults[filteredResults.length - 1],
                  },
                },
              });
              setIsFetching(true);
            })
            .catch((err) => console.log("Something went wrong"))
            .finally(() => setLoading(false));
        })
        .catch((err) => {
          console.log(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
    else {
      setIsFetching(true);
      setLoading(false);
    }
  }, [
    caseInfoDispatch,
    caseInfoState?.CustomerDetails?.ApplicantID,
    user?.customerReference,
  ]);

  useEffect(() => {
    if (!caseInfoState?.CustomerDetails?.ApplicantID) fetchCaseInfo();
  }, [fetchCaseInfo, caseInfoState?.CustomerDetails?.ApplicantID]);

  return { isLoading: loading || isFetching };
};

export default useFetchingInitialData;
