import React, { useReducer, useContext, createContext } from "react";

const initialState = {
  user: {},
  accessToken: null,
  refreshToken: null,
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useUpdateAuthContext = () => {
  return useContext(AuthUpdateContext);
};

export const AuthContext = createContext();
export const AuthUpdateContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  state.accessToken = "AAAAAAAAAAAAAAAAAAAAAAAAAAAA";

  return (
    <AuthContext.Provider value={state}>
      <AuthUpdateContext.Provider value={dispatch}>
        {children}
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
};
export default AuthProvider;

export const AUTH_ACTIONS = {
  LOGIN: "LOGIN",
  LOGINTHROUGHTOKEN: "LOGIN_THROUGH_TOKEN",
  GOTTOKEN: "GOT_NEW_TOKEN",
  LOGOUT: "LOGOUT",
  PASSWORDCHANGED: "PASSWORD_CHANGED",
  UPDATECUSTOMERREFERENCE: "UPDATE_CUSTOMER_REFERENCE",
  GOTUPDATEDDETAILS: "GOT_UPDATED_DETAILS",
};

const reducer = (state, action) => {
  const uAuth = JSON.parse(localStorage.getItem("uAuth"));
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      localStorage.setItem(
        "uAuth",
        JSON.stringify({
          ...action.payload,
        })
      );
      return {
        ...action.payload,
      };
    case AUTH_ACTIONS.LOGINTHROUGHTOKEN:
      return {
        ...action.payload,
      };

    case AUTH_ACTIONS.GOTTOKEN:
      localStorage.setItem(
        "uAuth",
        JSON.stringify({
          ...uAuth,
          accessToken: action.payload?.accessToken,
        })
      );
      return {
        ...uAuth,
        accessToken: action.payload?.accessToken,
      };

    case AUTH_ACTIONS.LOGOUT:
      localStorage.removeItem("uAuth");
      return {};

    case AUTH_ACTIONS.PASSWORDCHANGED:
      localStorage.setItem(
        "uAuth",
        JSON.stringify({
          ...uAuth,
        })
      );
      return {
        ...state,
      };

    case AUTH_ACTIONS.UPDATECUSTOMERREFERENCE:
      localStorage.setItem(
        "uAuth",
        JSON.stringify({
          ...uAuth,
          user: {
            ...uAuth.user,
            ...action?.payload,
            customerReference: action?.payload?.customerReference,
          },
        })
      );

      return {
        ...uAuth,
        user: {
          ...uAuth.user,
          ...action?.payload,
          customerReference: action?.payload?.customerReference,
        },
      };

    case AUTH_ACTIONS.GOTUPDATEDDETAILS:
      localStorage.setItem(
        "uAuth",
        JSON.stringify({
          ...uAuth,
          ...action.payload,
        })
      );
      return {
        ...uAuth,
        ...action.payload,
      };
    default:
      return state;
  }
};
