import React from "react";
import { Button, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { CChart } from "@coreui/react-chartjs";
import CustomChart from "../../components/CustomChart/CustomChart";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import CenterColumnContainer from "../../components/Containers";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { Stack } from "@mui/system";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import useADPData from "../../hooks/useADPData";
import {
  getADPData,
  getOpenBankingStatus,
  openBankingHandShakeInvoke,
} from "../../api/crm";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const OpenBanking1 = () => {
  // const spendingAnalysisMetrics = [
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "ServicesM01",
  //   //   label: "Services (This Month vs Previous Month)",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "ServicesM02",
  //   //   // label: "Services Previous Month",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "ShoppingM01",
  //   //   label: "Shopping (This Month vs Previous Month)",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "ShoppingM02",
  //   //   // label: "Shopping Previous Month",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "SalaryRegularIncomeM1",
  //   //   label: "Salary (This Month vs Previous Month)",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "SalaryRegularIncomeM2",
  //   //   // label: "Salary Previous Month",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "UtilitiesM01",
  //   //   label: "Utilities (This Month vs Previous Month)",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "UtilitiesM02",
  //   //   // label: "Utilities Previous Month",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "EntertainmentM01",
  //   //   label: "Entertainment (This Month vs Previous Month)",
  //   //   value: "0",
  //   // },
  //   // {
  //   //   APICall: "CAT 14",
  //   //   description: "EntertainmentM02",
  //   //   // label: "Entertainment Previous Month",
  //   //   value: "0",
  //   // },
  // ];
  const metrics = [
    {
      APICall: "CAT 11",
      description: "DisposibleIncome",
      value: "0",
    },
    // ...spendingAnalysisMetrics,
  ];

  const { CustomerDetails = {} } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const [metricsData, setMetricsData] = React.useState(metrics);
  const [loading, setLoading] = React.useState(true);
  const { getADPDataValueByDescription } = useADPData(
    CustomerDetails?.ApplicantID
  );

  // Services Graph Calls
  const ServicesM01 = getADPDataValueByDescription("CAT 14", "ServicesM01");
  const ServicesM02 = getADPDataValueByDescription("CAT 14", "ServicesM02");
  const ServicesM03 = getADPDataValueByDescription("CAT 14", "ServicesM03");
  const ServicesM04 = getADPDataValueByDescription("CAT 14", "ServicesM04");
  const ServicesM05 = getADPDataValueByDescription("CAT 14", "ServicesM05");
  const ServicesM06 = getADPDataValueByDescription("CAT 14", "ServicesM06");
  const ServicesM07 = getADPDataValueByDescription("CAT 14", "ServicesM07");
  const ServicesM08 = getADPDataValueByDescription("CAT 14", "ServicesM08");
  const ServicesM09 = getADPDataValueByDescription("CAT 14", "ServicesM09");
  const ServicesM10 = getADPDataValueByDescription("CAT 14", "ServicesM10");
  const ServicesM11 = getADPDataValueByDescription("CAT 14", "ServicesM11");
  const ServicesM12 = getADPDataValueByDescription("CAT 14", "ServicesM12");

  const ShoppingM01 = getADPDataValueByDescription("CAT 14", "ShoppingM01");
  const ShoppingM02 = getADPDataValueByDescription("CAT 14", "ShoppingM02");
  const ShoppingM03 = getADPDataValueByDescription("CAT 14", "ShoppingM03");
  const ShoppingM04 = getADPDataValueByDescription("CAT 14", "ShoppingM04");
  const ShoppingM05 = getADPDataValueByDescription("CAT 14", "ShoppingM05");
  const ShoppingM06 = getADPDataValueByDescription("CAT 14", "ShoppingM06");
  const ShoppingM07 = getADPDataValueByDescription("CAT 14", "ShoppingM07");
  const ShoppingM08 = getADPDataValueByDescription("CAT 14", "ShoppingM08");
  const ShoppingM09 = getADPDataValueByDescription("CAT 14", "ShoppingM09");
  const ShoppingM10 = getADPDataValueByDescription("CAT 14", "ShoppingM10");
  const ShoppingM11 = getADPDataValueByDescription("CAT 14", "ShoppingM11");
  const ShoppingM12 = getADPDataValueByDescription("CAT 14", "ShoppingM12");

  const EntertainmentM01 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM01"
  );
  const EntertainmentM02 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM02"
  );
  const EntertainmentM03 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM03"
  );
  const EntertainmentM04 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM04"
  );
  const EntertainmentM05 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM05"
  );
  const EntertainmentM06 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM06"
  );
  const EntertainmentM07 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM07"
  );
  const EntertainmentM08 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM08"
  );
  const EntertainmentM09 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM09"
  );
  const EntertainmentM10 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM10"
  );
  const EntertainmentM11 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM11"
  );
  const EntertainmentM12 = getADPDataValueByDescription(
    "CAT 14",
    "EntertainmentM12"
  );

  const UtilitiesM01 = getADPDataValueByDescription("CAT 14", "UtilitiesM01");
  const UtilitiesM02 = getADPDataValueByDescription("CAT 14", "UtilitiesM02");
  const UtilitiesM03 = getADPDataValueByDescription("CAT 14", "UtilitiesM03");
  const UtilitiesM04 = getADPDataValueByDescription("CAT 14", "UtilitiesM04");
  const UtilitiesM05 = getADPDataValueByDescription("CAT 14", "UtilitiesM05");
  const UtilitiesM06 = getADPDataValueByDescription("CAT 14", "UtilitiesM06");
  const UtilitiesM07 = getADPDataValueByDescription("CAT 14", "UtilitiesM07");
  const UtilitiesM08 = getADPDataValueByDescription("CAT 14", "UtilitiesM08");
  const UtilitiesM09 = getADPDataValueByDescription("CAT 14", "UtilitiesM09");
  const UtilitiesM10 = getADPDataValueByDescription("CAT 14", "UtilitiesM10");
  const UtilitiesM11 = getADPDataValueByDescription("CAT 14", "UtilitiesM11");
  const UtilitiesM12 = getADPDataValueByDescription("CAT 14", "UtilitiesM12");

  const SalaryRegularIncomeM01 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM01"
  );
  const SalaryRegularIncomeM02 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM02"
  );
  const SalaryRegularIncomeM03 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM03"
  );
  const SalaryRegularIncomeM04 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM04"
  );
  const SalaryRegularIncomeM05 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM05"
  );
  const SalaryRegularIncomeM06 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM06"
  );
  const SalaryRegularIncomeM07 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM07"
  );
  const SalaryRegularIncomeM08 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM08"
  );
  const SalaryRegularIncomeM09 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM09"
  );
  const SalaryRegularIncomeM10 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM10"
  );
  const SalaryRegularIncomeM11 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM11"
  );
  const SalaryRegularIncomeM12 = getADPDataValueByDescription(
    "CAT 14",
    "SalaryRegularIncomeM12"
  );

  const IncomeM01 = getADPDataValueByDescription("CAT 14", "IncomeM01");
  const IncomeM02 = getADPDataValueByDescription("CAT 14", "IncomeM02");
  const IncomeM03 = getADPDataValueByDescription("CAT 14", "IncomeM03");
  const IncomeM04 = getADPDataValueByDescription("CAT 14", "IncomeM04");
  const IncomeM05 = getADPDataValueByDescription("CAT 14", "IncomeM05");
  const IncomeM06 = getADPDataValueByDescription("CAT 14", "IncomeM06");
  const IncomeM07 = getADPDataValueByDescription("CAT 14", "IncomeM07");
  const IncomeM08 = getADPDataValueByDescription("CAT 14", "IncomeM08");
  const IncomeM09 = getADPDataValueByDescription("CAT 14", "IncomeM09");
  const IncomeM10 = getADPDataValueByDescription("CAT 14", "IncomeM10");
  const IncomeM11 = getADPDataValueByDescription("CAT 14", "IncomeM11");
  const IncomeM12 = getADPDataValueByDescription("CAT 14", "IncomeM12");

  const navigate = useNavigate();
  // ! To be removed as there is no need for graph on this page.
  const data = [
    {
      label: "Income",
      data: [
        {
          primary: "Jan",
          months: IncomeM12,
        },
        {
          primary: "Feb",
          months: IncomeM11,
        },
        {
          primary: "Mar",
          months: IncomeM10,
        },
        {
          primary: "Apr",
          months: IncomeM09,
        },
        {
          primary: "May",
          months: IncomeM08,
        },
        {
          primary: "Jun",
          months: IncomeM07,
        },
        {
          primary: "Jul",
          months: IncomeM06,
        },
        {
          primary: "Aug",
          months: IncomeM05,
        },
        {
          primary: "Sep",
          months: IncomeM04,
        },
        {
          primary: "Oct",
          months: IncomeM03,
        },
        {
          primary: "Nov",
          months: IncomeM02,
        },
        {
          primary: "Dec",
          months: IncomeM01,
        },
      ],
    },
    // {
    //   label: "Series 2",
    //   data: [
    //     {
    //       primary: "Jan",
    //       months: 15,
    //     },
    //     {
    //       primary: "Feb",
    //       months: 26,
    //     },
    //     {
    //       primary: "Mar",
    //       months: 30,
    //     },
    //     {
    //       primary: "Apr",
    //       months: 29,
    //     },
    //     {
    //       primary: "May",
    //       months: 26,
    //     },
    //     {
    //       primary: "Jun",
    //       months: 22,
    //     },
    //     {
    //       primary: "Jul",
    //       months: 24,
    //     },
    //     {
    //       primary: "Aug",
    //       months: 32,
    //     },
    //     {
    //       primary: "Sep",
    //       months: 39,
    //     },
    //     {
    //       primary: "Oct",
    //       months: 41,
    //     },
    //     {
    //       primary: "Nov",
    //       months: 39,
    //     },
    //     {
    //       primary: "Dec",
    //       months: 37,
    //     },
    //   ],
    // },
    // {
    //   label: "Series 3",
    //   data: [
    //     {
    //       primary: "Jan",
    //       months: 17,
    //     },
    //     {
    //       primary: "Feb",
    //       months: 28,
    //     },
    //     {
    //       primary: "Mar",
    //       months: 32,
    //     },
    //     {
    //       primary: "Apr",
    //       months: 31,
    //     },
    //     {
    //       primary: "May",
    //       months: 28,
    //     },
    //     {
    //       primary: "Jun",
    //       months: 24,
    //     },
    //     {
    //       primary: "Jul",
    //       months: 26,
    //     },
    //     {
    //       primary: "Aug",
    //       months: 34,
    //     },
    //     {
    //       primary: "Sep",
    //       months: 41,
    //     },
    //     {
    //       primary: "Oct",
    //       months: 43,
    //     },
    //     {
    //       primary: "Nov",
    //       months: 41,
    //     },
    //     {
    //       primary: "Dec",
    //       months: 39,
    //     },
    //   ],
    // },
  ];

  const fetchData = React.useCallback(() => {
    console.log("CustomerDetails?.ApplicantID", CustomerDetails?.ApplicantID);
    getADPData({
      CaseReference: CustomerDetails?.ApplicantID,
    })
      .then((responseData) => {
        if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          const getValueFromADPDataList = (
            APICall: string,
            description: string
          ): string => {
            const { ADPDataList } = responseData?.data?.d;
            const { ADPRecords } = ADPDataList.find(
              (list: any) => list.APICall === APICall
            );
            const record = ADPRecords.find(
              (record: any) => record.Description === description
            );

            return record?.Value;
          };
          const metricsData = metrics.map((metric) => ({
            ...metric,
            value: getValueFromADPDataList(metric.APICall, metric.description),
          }));

          setMetricsData(metricsData);
        } else {
          setToastMessage(
            responseData?.data?.d?.EnquiryResult?.Message ||
              "Something went wrong"
          );
        }
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        )
      )
      .finally(() => setLoading(false));
    getOpenBankingStatus({
      pRequest: {
        CustomerCode: CustomerDetails?.CustomerReference,
        // CustomerCode: "C000016", //! For Testing if no any CustomerReference
        BankCode: "",
        ApplicantNumber: "",
      },
    })
      .then((response) => {
        if (response?.data?.d?.Records.length) {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.FETCHED_OPEN_BANKING_STATUS,
            payload: response?.data?.d?.Records,
          });
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              OpenBankingCurrentStatus:
                response?.data?.d?.Records?.[
                  response?.data?.d?.Records.length - 1
                ]?.Status,
            },
          });
        } else setToastMessage("No records found.");
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        )
      );
  }, [CustomerDetails?.ApplicantID, caseInfoDispatch]);

  const getMetricsValue = (APICall: string, description: string) => {
    const value =
      metricsData.find(
        (metric) =>
          metric.APICall === APICall && metric.description === description
      )?.value ?? 0;
    return +value;
  };

  const convertToPercentage = (value: number): number => {
    const TOTAL_BAR_AMOUNT = 10000;
    return (value * 100) / TOTAL_BAR_AMOUNT;
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = () => {
    var windowReference: any = window.open(
      "https://effulgent-yeot-34856a.netlify.app/loader.html",
      "_blank"
    );
    openBankingHandShakeInvoke({
      pRequest: {
        CustomerCode: CustomerDetails?.CustomerReference,
        // CustomerCode: "C000016", //! For Testing if no any CustomerReference
        BankCode: "",
        //! contstant added in ApplicantNumber
        ApplicantNumber: "0",
      },
    })
      .then((responseData: any) => {
        if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          const URL = responseData?.data?.d?.Records[0]?.ClientUrl;
          if (URL) {
            // window.open(URL, "_blank");
            windowReference.location = URL;
            return navigate("/home");
          }
        }
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        )
      );
  };

  return (
    <CenterLayoutContainer pageTitle="OPEN BANKING">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <CenterColumnContainer flex="1">
        <Stack
          direction="column"
          flex="1"
          justifyContent="center"
          width={{ sm: "45%", xs: "100%" }}
        >
          <Typography
            className="!text-xs !mt-6"
            sx={{ fontSize: "12px", mb: 2 }}
          >
            As part of our loan agreement, we require continuous access to your
            open banking connection. Please enable access every 90 days.
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography className="!text-lg !mb-6 !mt-6 !font-semibold">
              Current Status:
            </Typography>
            <Typography
              className="!text-lg !mb-6 !mt-6 !font-semibold"
              color="secondary"
            >
              {CustomerDetails?.OpenBankingCurrentStatus}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClick}
          >
            Re-Connect
          </Button>
          <Box className="flex gap-2 !mb-3 !mt-6">
            <Typography className="!text-lg !font-semibold">Income</Typography>
            <Typography
              className="!text-lg !font-semibold"
              sx={{
                color: (theme) => theme.palette.primary.light,
              }}
            >
              {loading ? (
                <CircularProgress size={18} />
              ) : (
                currencyFormat(getMetricsValue("CAT 11", "DisposibleIncome"))
              )}
            </Typography>
          </Box>
          {/* <CustomChart data={data} /> */}
          {/* <Typography className="!text-lg !mb-6 !mt-6 !font-semibold">
            Spending Analysis
          </Typography> */}
          {/* {spendingAnalysisMetrics.map((spendingAnalysisMetric) => (
            <Box
              key={`${spendingAnalysisMetric.APICall}_${spendingAnalysisMetric.description}`}
              className="flex flex-col !mb-6"
            >
              <Typography className="!text-lg !font-semibold">
                {spendingAnalysisMetric.label}
              </Typography>
              <Typography
                className="!text-lg !font-semibold"
                sx={{
                  color: (theme) => theme.palette.primary.light,
                }}
              >
                {currencyFormat(
                  getMetricsValue(
                    spendingAnalysisMetric.APICall,
                    spendingAnalysisMetric.description
                  )
                )}
              </Typography>
              <LinearProgress
                value={convertToPercentage(
                  getMetricsValue(
                    spendingAnalysisMetric.APICall,
                    spendingAnalysisMetric.description
                  )
                )}
                variant="determinate"
                color="secondary"
                sx={{
                  height: 10,
                  borderRadius: 5,
                  ".MuiLinearProgress-bar": {
                    borderRadius: 5,
                  },
                }}
              />
            </Box>
          ))} */}
          <Typography className="!text-lg !mb-6 !mt-6 !font-semibold">
            12 Month Income Chart
          </Typography>
          <CChart
            type="radar"
            data={{
              labels: [
                "Month 1",
                "Month 2",
                "Month 3",
                "Month 4",
                "Month 5",
                "Month 6",
                "Month 7",
                "Month 8",
                "Month 9",
                "Month 10",
                "Month 11",
                "Month 12",
              ],
              datasets: [
                // {
                //   label: 'Previous Month',
                //   backgroundColor: 'rgba(220, 220, 220, 0.2)',
                //   borderColor: 'rgba(220, 220, 220, 1)',
                //   pointBackgroundColor: 'rgba(220, 220, 220, 1)',
                //   pointBorderColor: '#fff',
                //   // pointHighlightFill: '#fff',
                //   // pointHighlightStroke: 'rgba(220, 220, 220, 1)',
                //   data: [IncomeM01, IncomeM02, IncomeM03, IncomeM04, IncomeM05, IncomeM06, IncomeM07, IncomeM08, IncomeM09, IncomeM10, IncomeM11, IncomeM12,],
                // },
                {
                  label: "Latest Month",
                  backgroundColor: "rgba(151, 187, 205, 0.2)",
                  borderColor: "rgba(151, 187, 205, 1)",
                  pointBackgroundColor: "rgba(151, 187, 205, 1)",
                  pointBorderColor: "#fff",
                  // pointHighlightFill: '#fff',
                  // pointHighlightStroke: 'rgba(151, 187, 205, 1)',
                  data: [
                    IncomeM01,
                    IncomeM02,
                    IncomeM03,
                    IncomeM04,
                    IncomeM05,
                    IncomeM06,
                    IncomeM07,
                    IncomeM08,
                    IncomeM09,
                    IncomeM10,
                    IncomeM11,
                    IncomeM12,
                  ],
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: "#000",
                  },
                },
              },
              scales: {
                r: {
                  grid: {
                    color: "#000",
                  },
                  ticks: {
                    color: "#000",
                  },
                },
              },
            }}
          />
          {/* <LineChart
            xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }]}
            series={[
              {
                data: [
                  IncomeM12,
                  IncomeM11,
                  IncomeM10,
                  IncomeM09,
                  IncomeM08,
                  IncomeM07,
                  IncomeM06,
                  IncomeM05,
                  IncomeM04,
                  IncomeM03,
                  IncomeM02,
                  IncomeM01,
                ],
                area: true,
              },
            ]}
            width={950}
            height={500}
          /> */}
          <Typography className="!text-lg !mb-6 !mt-6 !font-semibold">
            Spending Catagories
          </Typography>
          {/* <LineChart
            xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }]}
            series={[
              {
                data: [
                  UtilitiesM12,
                  UtilitiesM11,
                  UtilitiesM10,
                  UtilitiesM09,
                  UtilitiesM08,
                  UtilitiesM07,
                  UtilitiesM06,
                  UtilitiesM05,
                  UtilitiesM04,
                  UtilitiesM03,
                  UtilitiesM02,
                  UtilitiesM01,
                ],
                area: true,
              },
            ]}
            width={950}
            height={500}
          /> */}
          <CChart
            type="radar"
            data={{
              labels: [
                "Services",
                "Shopping",
                "Utilities",
                "Entertainment",
                // 'Coding',
                // 'Cycling',
                // 'Running',
              ],
              datasets: [
                {
                  label: "Previous Month",
                  backgroundColor: "rgba(220, 220, 220, 0.2)",
                  borderColor: "rgba(220, 220, 220, 1)",
                  pointBackgroundColor: "rgba(220, 220, 220, 1)",
                  pointBorderColor: "#fff",
                  // pointHighlightFill: '#fff',
                  // pointHighlightStroke: 'rgba(220, 220, 220, 1)',
                  data: [
                    ServicesM02,
                    ShoppingM02,
                    UtilitiesM02,
                    EntertainmentM02,
                  ],
                },
                {
                  label: "Latest Month",
                  backgroundColor: "rgba(151, 187, 205, 0.2)",
                  borderColor: "rgba(151, 187, 205, 1)",
                  pointBackgroundColor: "rgba(151, 187, 205, 1)",
                  pointBorderColor: "#fff",
                  // pointHighlightFill: '#fff',
                  // pointHighlightStroke: 'rgba(151, 187, 205, 1)',
                  data: [
                    ServicesM01,
                    ShoppingM01,
                    UtilitiesM01,
                    EntertainmentM01,
                  ],
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: "#000",
                  },
                },
              },
              scales: {
                r: {
                  grid: {
                    color: "#000",
                  },
                  ticks: {
                    color: "#000",
                  },
                },
              },
            }}
          />
          {/* <CustomChart data={data} /> */}
        </Stack>
      </CenterColumnContainer>
      {/* <Typography className="!text-xs !mt-6 !text-center">
        Privacy Policy
      </Typography> */}
      <NeedHelpLink style={{ alignSelf: "center", justifySelf: "flex-end" }} />
    </CenterLayoutContainer>
  );
};

export default OpenBanking1;
