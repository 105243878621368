import React from "react";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { getPostcodeLookup, updateCase } from "../../api/crm";
import IsEmptyOrValueUpdated, {additionalUrlParams, getUrlParam} from "../../utils";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";

const PreviousAddress = () => {
  const {
    PreviousAddressFields,
    CurrentAddressFields = {},
    ...caseInfoState
  } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const lastPreviousAddress =
    location?.state?.from === "/employment"
      ? PreviousAddressFields[PreviousAddressFields?.length - 1]
      : PreviousAddressFields?.length &&
        location?.state?.from !== "/previous-address"
      ? PreviousAddressFields[PreviousAddressFields?.length - 1]
      : !PreviousAddressFields[PreviousAddressFields?.length - 1]
          ?.TimeInMonths &&
        !PreviousAddressFields[PreviousAddressFields?.length - 1]?.TimeInYears
      ? PreviousAddressFields[PreviousAddressFields?.length - 1]
      : {
          PostCode: "",
          HouseNumber: "",
          HouseName: "",
          Address1: "",
          Address2: "",
          Address3: "",
          Address4: "",
          TimeInYears: "",
          TimeInMonths: "",
        };
  const [minMonthAtAddress, setMinMonthAtAddress] = React.useState(
    lastPreviousAddress?.TimeInYears
      ? Number(lastPreviousAddress?.TimeInYears) > 0
        ? 0
        : 1
      : 1
  );
  const initialTextFields = [
    {
      fieldName: "PostCode",
      label: "Postcode",
      defaultValue: lastPreviousAddress?.PostCode,
      type: "autocomplete",
      onOptionClick: (values: any) => {
        if (values)
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_PREVIOUS_ADDRESS,
            payload: {
              PostCode: values?.PostCode,
              HouseNumber: values?.HouseNumber,
              HouseName: values?.HouseName,
              Address1: values?.Address1,
              Address2: values?.Address2,
              Address3: values?.Address3,
              Address4: values?.Address4,
              TimeInYears: "",
              TimeInMonths: "",
            },
          });
      },
      fetchOptions: async (input: any) => {
        try {
          if (input) {
            const response = await getPostcodeLookup({ PostCode: input });
            if (response?.data?.d?.EnquiryResult?.SuccessCode === 0) {
              return response?.data?.d?.Records;
            }
          }
        } catch (error) {
          console.log("Error", error);
        }
      },
    },
    {
      fieldName: "HouseNumber",
      defaultValue: lastPreviousAddress?.HouseNumber,
      label: "House Number",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "HouseName",
      defaultValue: lastPreviousAddress?.HouseName,
      label: "House Name",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address1",
      defaultValue: lastPreviousAddress?.Address1,
      label: "Address 1",
      type: "text",
    },
    {
      fieldName: "Address2",
      defaultValue: lastPreviousAddress?.Address2,
      label: "Address 2",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address3",
      defaultValue: lastPreviousAddress?.Address3,
      label: "Address 3",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address4",
      defaultValue: lastPreviousAddress?.Address4,
      label: "Address 4",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "TimeInYears",
      defaultValue: lastPreviousAddress?.TimeInYears || 0,
      label: "Years at Address",
      type: "number",
      customValidation: getYup.number().required(`This field is required`),
      customChangeHandler: (e: any) => {
        if (e.target.value > 0) {
          setMinMonthAtAddress(0);
        } else {
          setMinMonthAtAddress(1);
        }
      },
    },
    {
      fieldName: "TimeInMonths",
      defaultValue: lastPreviousAddress?.TimeInMonths,
      label: "Months at Address",
      type: "number",
      customValidation: getYup
        .number()
        .min(
          minMonthAtAddress,
          minMonthAtAddress === 0
            ? "Time at address must have 0 or greater than that month."
            : "Please enter a minimum of one month for time at address."
        )
        .max(11, "Time at address must have 11 or less than that month.")
        .required(`This field is required`),
      notValidate:
        lastPreviousAddress?.TimeInMonths &&
        lastPreviousAddress?.TimeInMonths === 0
          ? true
          : false,
    },
  ];
  React.useEffect(() => {
    if (location?.state?.from === "/current-address") {
      return caseInfoDispatch({
        type: CASEINFO_ACTIONS_TYPES.DELETE_PREVIOUS_ADDRESSES,
      });
    }
  }, [caseInfoDispatch, location?.state?.from]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    //   ?
    !PreviousAddressFields.find(
      (item: any) =>
        item?.PostCode === values?.PostCode &&
        item?.Address1 === values?.Address1
    )
      ? caseInfoDispatch({
          type: CASEINFO_ACTIONS_TYPES.ADD_PREVIOUS_ADDRESS,
          payload: values,
        })
      : caseInfoDispatch({
          type: CASEINFO_ACTIONS_TYPES.UPDATE_PREVIOUS_ADDRESS,
          payload: values,
        });
    caseInfoDispatch({
      type: CASEINFO_ACTIONS_TYPES.ADD_TOTAL_TIME_AT_PREVIOUS_ADDRESS,
      payload: {
        TimeInYears: values.TimeInYears,
        TimeInMonths: values.TimeInMonths,
      },
    });
    let totalTime =
      CurrentAddressFields.TimeAtCurrentAddress +
      (CurrentAddressFields.TimeAtPreviousAddress || 0) +
      (Number(values.TimeInYears) * 12 + Number(values.TimeInMonths));

    if (totalTime > 35) {
      if (IsEmptyOrValueUpdated(lastPreviousAddress, values))
        updateCase({
          ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
          CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
          Payload: {
            MainApplicant: {
              PreviousAddresses: !PreviousAddressFields.find(
                (item: any) =>
                  item?.PostCode === values?.PostCode &&
                  item?.HouseName === values?.HouseName
              )
                ? [
                    ...PreviousAddressFields.map((address: any) => ({
                      ...address,
                      Id: null,
                      TimeInYears: address.TimeInYears.toString(),
                      TimeInMonths: address.TimeInMonths.toString(),
                    })),
                    {
                      ...values,
                      Id: null,
                      TimeInYears: values.TimeInYears.toString(),
                      TimeInMonths: values.TimeInMonths.toString(),
                    },
                  ]
                : [
                    ...PreviousAddressFields.map(
                      (address: any, index: number) =>
                        index === PreviousAddressFields.length - 1
                          ? {
                              ...address,
                              Id: null,
                              TimeInYears: address.TimeInYears.toString(),
                              TimeInMonths: address.TimeInMonths.toString(),
                              ...values,
                            }
                          : {
                              ...address,
                              Id: null,
                              TimeInYears: address.TimeInYears.toString(),
                              TimeInMonths: address.TimeInMonths.toString(),
                            }
                    ),
                  ],
            },
          },
        })
          .then((responseData: any) => {
            if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
              return navigate("/employment" + additionalUrlParams(), {
                state: { from: window.location.pathname },
              });
            } else {
              setToastMessage(
                responseData?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      else
        return navigate("/employment" + additionalUrlParams(), {
          state: { from: window.location.pathname },
        });
    } else {
      location.state.from = "/previous-address";
      return resetForm();
    }
  };

    const { isLoading } = useFetchingInitialData();

    React.useEffect(() => {
    }, [caseInfoState]);


    return (
    <ImageLayoutContainer title="Previous Address" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent
        textFields={initialTextFields}
        handleSubmit={handleSubmit}
        additionalText="Please provide your previous address"
      />
    </ImageLayoutContainer>
  );
};

export default PreviousAddress;
