import React from "react";
import { Button, Typography, FormHelperText } from "@mui/material";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import CenterColumnContainer from "../../components/Containers";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { calculateLoanCostFromMonthly, currencyFormat } from "../../utils";
import Stack from "@mui/material/Stack/Stack";
import useADPData from "../../hooks/useADPData";
import { caseRecalculatePaymentPlan, getADPData } from "../../api/crm";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";

const PaymentChanges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");

  const {
    // isLoading,
    // ADPData,
    // getADPDataValueByID,
    getADPDataValueByDescription,
  } = useADPData(CustomerDetails?.ApplicantID);

  // const flexAPR = getADPDataValueByDescription("CAT 11", "APRValue"); //TODO: ADPCall and Description to be changed.
  const flexLowPayment = getADPDataValueByDescription("CAT 14", "FlexPayment");
  const flexHighPayment = getADPDataValueByDescription("CAT 14", "FlexMax");
  const MonthlyPayment = CustomerDetails?.[CustomerDetails?.ApplicantID]?.MonthlyPayment

  const loanAmountMarks = [
    {
      value: flexLowPayment,
      label: flexLowPayment,
    },

    {
      value: flexHighPayment,
      label: flexHighPayment,
    },
  ];
  const formik = useFormik({
    initialValues: {
      loanAmount: Number(location?.state?.caseDetails?.MonthlyPayment),
    },
    onSubmit: (values) => {
      if (CustomerDetails?.[CustomerDetails?.ApplicantID])
        caseRecalculatePaymentPlan({
          CaseRef: CustomerDetails?.ApplicantID,
          ActualDate:
            CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate,
          Amount: values?.loanAmount.toString(),
        })
          .then((responseData) => {
            if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0)
              return navigate("/home");
            else
              return setToastMessage(
                responseData?.data?.d?.Message || "Something went wrong"
              );
          })
          .catch((err) => {
            setToastMessage(
              err?.data?.d?.Message || err?.message || "Something went wrong"
            );
          });
    },
  });

  const [loanCost, setLoanCost] = React.useState(
    calculateLoanCostFromMonthly(
      formik?.values?.loanAmount,
      location?.state?.caseDetails?.Term,
      location?.state?.caseDetails?.InterestYearly
    )
  );

  React.useEffect(() => {
    if (!location?.state?.caseDetails) return navigate("/home");
    setLoanCost(
      calculateLoanCostFromMonthly(
        formik?.values?.loanAmount,
        location?.state?.caseDetails?.Term,
        location?.state?.caseDetails?.InterestYearly
      )
    );
  }, [
    formik?.values?.loanAmount,
    location?.state?.caseDetails,
    location?.state?.caseDetails?.Term,
    navigate,
  ]);

  const fetchADPData = React.useCallback(
    () =>
      getADPData({
        CaseReference: CustomerDetails?.ApplicantID,
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
            console.log("ADPDataList", responseData?.data?.d?.ADPDataList);
          } else {
            setToastMessage(
              responseData?.data?.d?.EnquiryResult?.Message ||
                "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        ),
    [CustomerDetails?.ApplicantID]
  );

  React.useEffect(() => {
    fetchADPData();
  }, [fetchADPData]);

  return (
    <CenterLayoutContainer pageTitle="PAYMENT CHANGES">
     <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        Balance
      </Typography>
      <Typography
        sx={{ fontSize: "32px" }}
        color={(theme) => theme.palette.primary.light}
      >
        {currencyFormat(location?.state?.caseDetails?.TotalRepaymentAmount)}
      </Typography>
      <Stack
          gap={1}
          direction="row"
          alignItems="left"
          className="!mt-6"
        >
          <Typography className="!text-sm">
          {`We think you can afford to pay between £${flexLowPayment} and £${flexHighPayment} per month. Your current monthly payment is £${MonthlyPayment}`}
          </Typography>
          <Typography className="!text-sm">
          This slider allows you to adjust all future payments
          </Typography>
          </Stack>
          <form
            className="flex flex-col flex-0 justify-left p-5"
            onSubmit={formik.handleSubmit}
          >
            <CustomSlider
              className="!mt-12"
              min={14}
              max={70}
              step={1}
              // getAriaLabel={(value: number) => `£${value}`}
              // getAriaValueText={(value: number) => `£${value}`}
              // valueLabelFormat={(value: number) => `£${value}`}
              marks={loanAmountMarks}
              defaultValue={formik.values.loanAmount}
              id="loanAmount"
              name="loanAmount"
              value={formik.values.loanAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Typography className="!text-sm !mt-5">{`Changing you future payments to ${currencyFormat(Number(loanCost?.monthlyRep))} will have the following effect the total cost of your loan and will become ${currencyFormat(Number(loanCost?.totalCost))} `}</Typography>
            <FormHelperText className="!text-red-600">
              {(formik.touched.loanAmount && formik.errors.loanAmount) || " "}
            </FormHelperText>
          </form>
          {/* </Stack> */}
          {/* <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.monthlyRep))}
          </Typography>
          </Stack>
          <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">The total cost of your loan will become</Typography>
          <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.totalCost))}
          </Typography>
          </Stack> */}
          {/* <Stack direction="column" flex="1" className="!mt-2">
          <Typography className="!text-xs">TOTAL COST OF LOAN</Typography>
          <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
            {currencyFormat(Number(loanCost?.totalCost))}
          </Typography>
          </Stack> */}
          {/* <Typography className="!text-xs !mt-2">
            Total Interest: {currencyFormat(Number(loanCost?.interestRate))}
          </Typography> */}
          {/* <Stack direction="column" flex="1">
            <Typography sx={{ fontSize: "12px", mt: 8 }}>
              New Total Cost of Loan
            </Typography>
            <Typography
              className="!text-2xl !mt-2 !font-bold"
              color={(theme) => theme.palette.primary.light}
            >
              {currencyFormat(Number(loanCost?.totalCost))}
            </Typography>
          </Stack> */}
                <Button
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
        onClick={() => formik.handleSubmit()}
      >
        Apply Changes
      </Button>
        {/* </Stack> */}
        {/* <NeedHelpLink /> */}
      {/* </CenterColumnContainer> */}
    </CenterLayoutContainer>
  );
};

export default PaymentChanges;
