import React from "react";
import { useNavigate } from "react-router-dom";
import {additionalUrlParams, getUrlParam} from "../../utils";
import { invokeADPCall, updateCase } from "../../api/crm";
import FormComponent from "../../components/Forms/FormComponent";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
const AcceptInitialDataTerms = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {}, ...caseInfoState } = useCaseInfoContext();
  const textFields = [
    {
      fieldName: "acceptTermsAndConditions",
      label:
        "By ticking this box you are consenting to Custom Credit using your data in this way",
      type: "checkbox",
    },
  ];
  const handleSubmit = () => {

    if(!CustomerDetails.ApplicantID && getUrlParam('ApplicantID')) {
        CustomerDetails.ApplicantID = getUrlParam('ApplicantID');
    }

    const adpCallNames = ["CAT 11"];
    const asuncUpdatedAdpCalls = async (index: number) => {
      const adpCallName = adpCallNames[index];

      if (index === adpCallNames.length || !adpCallName) {
        return navigate("/bank-details" + additionalUrlParams());
      }

      invokeADPCall({
        Payload: {
          AdpCallName: adpCallName,
          CaseRef: CustomerDetails?.ApplicantID,
        },
      }).then(({ data }) => {
        const recordValue = data?.d?.Records?.find((record: any) =>
          record?.FieldName?.includes(adpCallName)
        )?.Value;

        if (CustomerDetails?.ApplicantID)
        updateCase({
          ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
          CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
          Payload: {
            WorkflowStep: "2.7",
            CaseStatus: "Application in Progress",
          },
        })


        if (recordValue === "Decline Live") {
          // update API to change Cases Status
          updateCase({
            ApplicantID: CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
            CustomerReference: CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
            Payload: {
              WorkflowStep: "9",
              CaseStatus: "Declined",
            },
          })
            .then((responseData) => {
              if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
                return navigate("/bank-details2" + additionalUrlParams(), {
                  state: {
                    submitBtnURL: "/home",
                    submitBtnText: "Go to Dashboard",
                  },
                });
            })
            .catch((err) => {
              console.log(err.message);
            });
        }

        const nextIndex = index + 1;
        return asuncUpdatedAdpCalls(nextIndex);
      });
    };

    asuncUpdatedAdpCalls(0);

    // navigate("/bank-details");
  };
  return (
    <ImageLayoutContainer noPadding title="Confirmation">
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
        additionalText="In order to process your application we’ll submit your personal information to credit reference agencies (CRAs) and in return they will give us information about you, such as your financial history. We do this to assess credit worthiness, check your identity and prevent criminal activity."
      />
    </ImageLayoutContainer>
  );
};

export default AcceptInitialDataTerms;
