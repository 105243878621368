import {
  Checkbox,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import React from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";

type CustomCheckboxProps = {
  item: {
    fieldName: string;
    label?: string;
    type: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: React.FocusEventHandler<HTMLButtonElement> | undefined;
    error: boolean | undefined;
    helperText: string;
    options?: { value: string; title: string }[];
  };
};
const CustomCheckbox = (props: CustomCheckboxProps) => {
  const { item } = props;

  const { fieldName, label, value, onChange, onBlur, helperText } = item;
  return (
    <FormControl size="small" className="!w-full">
      <Stack
        direction={{ sm: "row", xs: "column" }}
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          mt: 3,
        }}
      >
        <Checkbox
          color="secondary"
          aria-label="checkbox"
          icon={<RadioButtonUncheckedIcon color="secondary" />}
          checkedIcon={<CheckCircleIcon />}
          id={fieldName}
          name={fieldName}
          checked={Boolean(value)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.value = event.target.checked ? "true" : "";
            onChange(event);
          }}
          onBlur={onBlur}
        />
        <Typography className="!text-xs !p-2">{label}</Typography>
      </Stack>
      <FormHelperText className="!text-red-600">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomCheckbox;
