import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, verifyResetPasswordLink } from "../../api/oauth";
import ImageContainerWithBtns from "../../components/ImageContainer/ImageContainerWithBtns";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { Stack } from "@mui/system";
import { CircularProgress } from "@mui/material";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("id");
  const [toastMessage, setToastMessage] = React.useState("");
  const [successToastMessage, setSuccessToastMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const textFields = [
    {
      fieldName: "password",
      defaultValue: "",
      label: "Password",
      type: "password",
      customValidation: getYup
        .string()
        .min(8, "Password should be more than 8 characters")
        .required("This field is required"),
    },
    {
      fieldName: "confirmPassword",
      defaultValue: "",
      label: "Confirm Password",
      type: "password",
      customValidation: getYup
        .string()
        .oneOf([getYup.ref("password"), null], "Passwords must match")
        .required("This field is required"),
    },
  ];
  const handleSubmit = (values: any) => {
    setLoading(true);
    if (
      values?.password &&
      values?.password === values?.confirmPassword &&
      token
    ) {
      resetPassword({
        email,
        password: values?.password,
      })
        .then((innerResponse) => {
          if (innerResponse?.data?.status && innerResponse?.data?.message) {
            setSuccessToastMessage(
              "Password is changed successfully, please try to login with new password."
            );
            const timeout = setTimeout(() => {
              setLoading(false);
              navigate("/login");
              clearTimeout(timeout);
            }, 1000);
          } else {
            setLoading(false);
            setToastMessage(
              innerResponse?.data?.message || "Something went wrong"
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          return setToastMessage(
            err?.data?.message || err?.message || "Something went wrong"
          );
        });
    } else {
      setLoading(false);
      return setToastMessage("Something went wrong");
    }
  };

  const verifyEmail = React.useCallback(
    async (token: string) => {
      setLoading(true);
      try {
        const response = await verifyResetPasswordLink({
          token,
        });
        if (response?.data?.status && response?.data?.email) {
          setEmail(response?.data?.email);
          setLoading(false);
        } else {
          navigate("/login");
          setLoading(false);
        }
      } catch (err) {
        navigate("/login");
        setLoading(false);
      }
    },
    [navigate]
  );

  React.useEffect(() => {
    if (token) verifyEmail(token);
  }, [token, verifyEmail]);

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token, navigate]);

  React.useEffect(() => {
    const data = localStorage.getItem("uAuth");
    console.log("localStorage.getItem", data);
    if (data) {
      const userData = JSON.parse(data || "");
      if (userData) {
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <ImageContainerWithBtns title="RESET PASSWORD" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <ToastMessage
        message={successToastMessage}
        setMessage={setSuccessToastMessage}
        toastType="success"
      />
      {loading ? (
        <Stack direction="column" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
      )}
    </ImageContainerWithBtns>
  );
};

export default ResetPassword;
