import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const PaymentVerify = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    // navigate("/payment-option");
    navigate("/marketing-opt-ins");
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="Payment Verification"
      handleClick={handleSubmit}
    >
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!text-sm !mt-12">
          Please confirm you have set up your repayment method.
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default PaymentVerify;
