import { Stack } from "@mui/material";
import React from "react";

const CenterColumnContainer = (props: {
  children: React.ReactNode;
  flex?: string;
  justifyContent?: string;
  alignItems?: string;
}) => {
  const { children, flex, justifyContent, alignItems } = props;
  return (
    <Stack
      direction="column"
      flex={flex}
      justifyContent={justifyContent || "center"}
      alignItems={alignItems || "center"}
    >
      {children}
    </Stack>
  );
};

export default CenterColumnContainer;

export const SmallWidthColumnContainer = (props: {
  children: React.ReactNode;
  flex?: string;
  justifyContent?: string;
  alignItems?: string;
  className?: string;
}) => {
  const { children, flex, justifyContent, alignItems, className } = props;
  return (
    <Stack
      direction="column"
      flex={flex}
      justifyContent={justifyContent || "center"}
      alignItems={alignItems}
      className={className}
      padding="0px 22px 0px 22px"
    >
      {children}
    </Stack>
  );
};
