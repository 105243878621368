import Stack from "@mui/material/Stack";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

const DashboardLayout = () => {
  const [sidebarState, setSidebarState] = React.useState(false);
  return (
    <Stack direction="row" flex="1">
      <Sidebar sidebarState={sidebarState} sidebarDispatch={setSidebarState} />
      <Stack
        direction="column"
        flex="1"
        sx={{
          width: { lg: !sidebarState ? `calc(100% - 240px)` : "100%" },
          overflow: "auto",
          minWidth: "357.5px",
        }}
      >
        <Header setSidebarState={setSidebarState} />
        <Stack
          direction="column"
          flex="1"
          sx={{
            padding: { md: "10px 90px 51px 90px", xs: "10px 30px" },
            overflow: "auto",
          }}
        >
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DashboardLayout;
