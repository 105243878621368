import React, { useReducer, useContext, createContext } from "react";

export const useCaseInfoContext = () => {
  return useContext(CaseInfoContext);
};

export const useUpdateCaseInfoContext = () => {
  return useContext(CaseInfoUpdateContext);
};

export const CaseInfoContext = createContext();
export const CaseInfoUpdateContext = createContext();

const CaseInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    PreviousAddressFields: [],
  });

  return (
    <CaseInfoContext.Provider value={state}>
      <CaseInfoUpdateContext.Provider value={dispatch}>
        {children}
      </CaseInfoUpdateContext.Provider>
    </CaseInfoContext.Provider>
  );
};
export default CaseInfoProvider;

export const CASEINFO_ACTIONS_TYPES = {
  ADD_INITIAL_DETAILS: "ADD_CASEINFO_INITIAL_DETAILS",
  ADD_CASE_INDENTIFIERS: "ADD_CASEINFO_CASE_INDENTIFIERS",
  ADD_LOAN_INITIAL_DETAILS: "ADD_CASEINFO_LOAN_INITIAL_DETAILS",
  ADD_CURRENT_ADDRESS: "ADD_CASEINFO_CURRENT_ADDRESS",
  ADD_PREVIOUS_ADDRESS: "ADD_CASEINFO_PREVIOUS_ADDRESS",
  ADD_EMPLOYMENT: "ADD_CASEINFO_EMPLOYMENT_DETAILS",
  ADD_VERIFICATION: "ADD_CASEINFO_VERIFICATION_DETAILS",
  ADD_MARKETING_OPT_IN: "ADD_CASEINFO_MARKETING_OPT_IN",
  ADD_FINANCES1: "ADD_CASEINFO_FINANCES1",
  ADD_FINANCES2: "ADD_CASEINFO_FINANCES2",
  ADD_BANK_DETAILS: "ADD_CASEINFO_BANK_DETAILS",
  ADD_TOTAL_TIME_AT_PREVIOUS_ADDRESS:
    "ADD_CASEINFO_TOTAL_TIME_AT_PREVIOUS_ADDRESS",
  ADD_OPEN_BANKING: "ADD_CASEINFO_OPEN_BANKING",
  UPDATE_PREVIOUS_ADDRESS: "UPDATE_CASEINFO_PREVIOUS_ADDRESS",
  UPDATE_CASEINFO: "UPDATE_CASEINFO",
  DELETE_PREVIOUS_ADDRESSES: "DELETE_PREVIOUS_ADDRESSES",
  FETCHED_CASELIST: "FETCHED_CASELIST",
  UPDATE_CASELIST_CASE: "UPDATE_CASELIST_CASE",
  FETCHED_CASEINFO: "FETCHED_CASEINFO",
  FETCHED_OPEN_BANKING_STATUS: "FETCHED_OPEN_BANKING_STATUS",
  REMOVE_DETAILS: "REMOVE_CASEINFO_DETAILS",
  REMOVE_CASE_DETAILS: "REMOVE_CASE_DETAILS",
};

const reducer = (state, action) => {
  let newPayload = {};
  switch (action.type) {
    case CASEINFO_ACTIONS_TYPES.ADD_INITIAL_DETAILS:
      newPayload = {
        ...action.payload,
      };
      delete newPayload.password;
      delete newPayload.confirmPassword;
      return {
        ...state,
        CustomerDetails: {
          ...newPayload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS:
      // console.log('ADD_CASE_INDENTIFIERS----');
      // console.log( { ...state?.CustomerDetails, ...action?.payload, } );
      // console.log('ADD_CASE_INDENTIFIERS----');
      return {
        ...state,
        CustomerDetails: {
          ...state?.CustomerDetails,
          ...action?.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_LOAN_INITIAL_DETAILS:
      return {
        ...state,
        LoanInitialDetailsFields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_CURRENT_ADDRESS:
      return {
        ...state,
        CurrentAddressFields: {
          ...action.payload,
          TimeAtCurrentAddress:
            Number(action.payload.TimeInYears) * 12 +
            Number(action.payload.TimeInMonths),
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_PREVIOUS_ADDRESS:
      return {
        ...state,
        PreviousAddressFields: [
          ...state.PreviousAddressFields,
          {
            ...action.payload,
          },
        ],
      };

    case CASEINFO_ACTIONS_TYPES.ADD_EMPLOYMENT:
      return {
        ...state,
        EmploymentFields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_VERIFICATION:
      return {
        ...state,
        VerificationFields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_MARKETING_OPT_IN:
      return {
        ...state,
        MarketingOptInsFields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_FINANCES1:
      return {
        ...state,
        Finances1Fields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_FINANCES2:
      return {
        ...state,
        Finances2Fields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_BANK_DETAILS:
      return {
        ...state,
        BankDetailsFields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_TOTAL_TIME_AT_PREVIOUS_ADDRESS:
      return {
        ...state,
        CurrentAddressFields: {
          ...state.CurrentAddressFields,
          TimeAtPreviousAddress: state?.CurrentAddressFields
            ?.TimeAtPreviousAddress
            ? state.CurrentAddressFields?.TimeAtPreviousAddress +
              Number(action.payload.TimeInYears) * 12 +
              Number(action.payload.TimeInMonths)
            : action.payload.TimeInYears * 12 +
              Number(action.payload.TimeInMonths),
        },
      };

    case CASEINFO_ACTIONS_TYPES.ADD_OPEN_BANKING:
      return {
        ...state,
        OpenBanking2Fields: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.UPDATE_PREVIOUS_ADDRESS:
      let updatedArr = state.PreviousAddressFields;
      updatedArr[updatedArr.length - 1] = {
        ...action.payload,
      };

      return {
        ...state,
        PreviousAddressFields: updatedArr,
      };

    case CASEINFO_ACTIONS_TYPES.UPDATE_CASEINFO:
      return {
        ...state,
        CaseInfo: {
          ...state.CaseInfo,
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.DELETE_PREVIOUS_ADDRESSES:
      return {
        ...state,
        PreviousAddressFields: [],
        CurrentAddressFields: {
          ...state.CurrentAddressFields,
          TimeAtPreviousAddress: 0,
        },
      };

    case CASEINFO_ACTIONS_TYPES.FETCHED_CASELIST:
      return {
        ...state,
        CaseList: action.payload,
      };

    case CASEINFO_ACTIONS_TYPES.UPDATE_CASELIST_CASE:
      return {
        ...state,
        CaseList: state?.CaseList?.length
          ? state?.CaseList?.map((Case) =>
              Case.ApplicantID === action.payload.ApplicantID
                ? action.payload
                : Case
            )
          : [action.payload],
        CustomerDetails: {
          ...state.CustomerDetails,
          [action.payload.ApplicantID]: action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.FETCHED_CASEINFO:
      return {
        ...state,
        CaseInfo: {
          ...action.payload,
        },
      };

    case CASEINFO_ACTIONS_TYPES.FETCHED_OPEN_BANKING_STATUS:
      return {
        ...state,
        OpenBankingStatus: action.payload,
      };

    case CASEINFO_ACTIONS_TYPES.REMOVE_DETAILS:
      return {};

    case CASEINFO_ACTIONS_TYPES.REMOVE_CASE_DETAILS:
      return {
        ...state,
        CustomerDetails: {
          ...state.CustomerDetails,
          ApplicantID: undefined,
          CustomerReference: undefined,
          [state.CustomerDetails?.ApplicantID]: {},
        },
      };

    default:
      return state;
  }
};
