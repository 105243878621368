import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormComponent from "../../components/Forms/FormComponent";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import {
  createDocumentToPdf,
  updateCase,
  updateCaseLockPaymentPlan,
} from "../../api/crm";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import moment from "moment";

const LoanAgreementDoc = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const selectedCase = CustomerDetails?.[CustomerDetails?.ApplicantID];
  const textFields = [
    {
      fieldName: "acceptLoanAgreement",
      label:
        "I have understood and want to continue",
      type: "checkbox",
    },
  ];

  const handleSubmit = (values: any) => {
    // Add one month to the current date
    const nextMonthDate = new Date();
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

    const datesPayload = {
      AgreementStartDate: moment().format("DD-MM-YYYY"),
      PaymentPlanDetail: {
        FirstPaymentDate: moment(nextMonthDate).format("YYYY-MM-DD"),
      },
    };

    if (CustomerDetails?.ApplicantID)
      updateCase({
        ApplicantID: CustomerDetails?.ApplicantID,
        CustomerReference: CustomerDetails?.CustomerReference,
        Payload: {
          WorkflowStep: "4.5",
          CaseStatus: "Pending",
          ...datesPayload,
        },
      })
        .then((responseData) => {
          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.UPDATE_CASELIST_CASE,
              payload: {
                ...selectedCase,
                PaymentPlanDetail: {
                  ...selectedCase.PaymentPlanDetail,
                  FirstPaymentDate:
                    datesPayload.PaymentPlanDetail.FirstPaymentDate,
                },
              },
            });
            return updateCaseLockPaymentPlan({
              ApplicantID: CustomerDetails?.ApplicantID,
            }).then((res) => {
              if (res?.data?.d?.EnquiryResult?.SuccessCode === 0)
                return navigate("/payment-options");
            });
          }
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };

  // const url = "https://arxiv.org/pdf/quant-ph/0410100.pdf";

  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchPDFURL = () =>
      createDocumentToPdf({
        ApplicantID: CustomerDetails?.ApplicantID,
        LetterCode: "Custom Credit Consumer Credit Agreement Document",
      })
        .then((response) => {
          if (response?.data?.d?.ReturnUrl) {
            setUrl(response?.data?.d?.ReturnUrl);
          } else {
            setToastMessage(
              response?.data?.d?.Result?.Message || "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.Result?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
    if (CustomerDetails?.ApplicantID) fetchPDFURL();
  }, [CustomerDetails?.ApplicantID]);

  return (
    <ImageLayoutContainer noPadding title="CONTRACT">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer
        flex="1"
        className="!mt-12 !w-full"
        justifyContent="flex-start"
      >
        <Stack
          direction="column"
          flex="1"
          sx={{
            p: "20px 17px",
            background: "rgba(217, 217, 217, 0.29)",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ height: { sm: "390px" }, flex: "1", width: "100%" }}
          >
            <object
              data={url}
              type="application/pdf"
              height="390px"
              width="100%"
            ></object>
            <Link href={url} target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                sx={{
                  width: { sm: 248, xs: "100%" },
                  mt: 3,
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              >
                Open PDF
              </Button>
            </Link>
          </Box>
        </Stack>
        {/* <Stack direction="column" flex="0.3">
          <Stack direction="column" flex="1">
            <Typography className="!text-center !mt-5">
              I have understood and want to continue
            </Typography>
          </Stack>
        </Stack> */}
      </SmallWidthColumnContainer>
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
      />
    </ImageLayoutContainer>
  );
};

export default LoanAgreementDoc;
