import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Toolbar, Typography, Button } from "@mui/material";

import "./Sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  AUTH_ACTIONS,
  useAuthContext,
  useUpdateAuthContext,
} from "../../context/AuthContext/AuthContext";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import Stack from "@mui/material/Stack";
import { ordoCollectPayment } from "../../api/crm";
import ToastMessage from "../ToastMessage/ToastMessage";
const drawerWidth = 270;

const APP_VERSION =  240823;

type SidebarProps = {
  window?: () => Window;
  sidebarState: boolean;
  sidebarDispatch: Function;
};

const toolbarStyle = {
  backgroundColor: "#F3F3F3",
  fontSize: "12px",
  alignItems: "flex-end",
  p: "0 0 10px 20px !important",
  minHeight: "56px !important",
};

function Sidebar(props: SidebarProps) {
  const { window, sidebarState, sidebarDispatch } = props;
  const navigate = useNavigate();
  const { ...authState } = useAuthContext();
  const authDispatch = useUpdateAuthContext();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const handleDrawerToggle = () => {
    sidebarDispatch((prevState: any) => !prevState);
  };
  const handleSignOut = () => {
    authDispatch({ type: AUTH_ACTIONS.LOGOUT });
    caseInfoDispatch({ type: CASEINFO_ACTIONS_TYPES.REMOVE_DETAILS });
    navigate("/login");
  };
  // const handleClick = () => {
  //   if (CustomerDetails?.[CustomerDetails?.ApplicantID])
  //     ordoCollectPayment({
  //       CaseReference: CustomerDetails?.ApplicantID,
  //       Amount: CustomerDetails?.[CustomerDetails?.ApplicantID]?.NetClaim,
  //     })
  //       .then((responseData) => {
  //         // if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
  //         return navigate("/home");
  //       })
  //       .catch((err) => {
  //         setToastMessage(
  //           err?.data?.d?.EnquiryResult?.Message ||
  //             err?.message ||
  //             "Something went wrong"
  //         );
  //       });
  // };
  const options = [
    {
      text: "DETAILS",
      path: "/update-details",
    },
    // {
    //   text: "EMAIL PAYMENT LINK",
    //   path: "/home",
    //   handleClick,
    // },
    {
      text: "OPEN BANKING",
      path: "/open-banking1",
    },
  ];
  const setting = [
    {
      text: "FAQ",
      path: "https://www.customcredit.co.uk/faq/",
    },
    {
      text: "PRIVACY",
      path: "https://www.customcredit.co.uk/privacy-policy",
    },
    {
      text: "SUPPORT",
      path: "/need-help",
    },
  ];
  const drawer = (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          backgroundColor: "#BC4CA7",
          color: theme.palette.background.default,
          p: "22px",
        })}
        className="font-thin flex justify-between w-full"
      >
        <AccountCircleOutlinedIcon
          onClick={() => navigate("/home")}
          sx={(theme) => ({
            fontSize: 65,
            color: "#F7DDFC",
          })}
        />
        <Typography variant="h6" sx={{ mt: 2, fontSize: "17px" }}>
          {authState?.user?.firstName + " " + authState?.user?.surName}
        </Typography>
        <Typography sx={{ fontSize: "10px", mt: 1 }}>
          Your Account Number
        </Typography>
        <Typography sx={{ fontSize: "13px" }}>
          {authState?.user?.customerReference}
        </Typography>
      </Stack>
      <Divider />
      <Toolbar sx={toolbarStyle}>OPTIONS</Toolbar>

      <List sx={{ p: "0 !important" }}>
        {options.map((data, index) => (
          <Box
            sx={{
              padding: "0 20px",
              color: (theme) => theme.palette.primary.light,
            }}
            key={index}
          >
            <NavLink
              // className={({ isActive }) =>
              //   isActive ? "sidebar__link--active" : ""
              // }
              key={data.text}
              to={data.path}
              // onClick={data?.handleClick}
            >
              <div className={`sidebar__link `}>{data.text}</div>
            </NavLink>
            {index !== options.length - 1 ? <Divider /> : ""}
          </Box>
        ))}
      </List>
      <Toolbar sx={toolbarStyle}>LINKS</Toolbar>
      <List sx={{ p: "0 !important" }}>
        {setting.map((data, index) => (
          <div style={{ padding: "0 20px" }} key={index}>
            {data.text === "SUPPORT" ? (
              <NavLink
                // className={({ isActive }) =>
                //   isActive ? "sidebar__link--active" : ""
                // }
                key={data.text}
                to={data.path}
              >
                <div className={`sidebar__link `}>{data.text}</div>
              </NavLink>
            ) : (
              <a
                // className={({ isActive }) =>
                //   isActive ? "sidebar__link--active" : ""
                // }
                key={data.text}
                href={data.path}
                target={data.text === "SUPPORT" ? "_self" : "_blank"}
                rel="noreferrer"
              >
                <div className={`sidebar__link `}>{data.text}</div>
              </a>
            )}
            {index !== setting.length - 1 ? <Divider /> : ""}
          </div>
        ))}
      </List>
      <Toolbar sx={toolbarStyle}></Toolbar>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        flex="1"
        sx={{ p: "15px" }}
      >
        <Button
          variant="contained"
          onClick={handleSignOut}
          fullWidth
          color="secondary"
          className="sm:!mb-2"
        >
          Sign out
        </Button>
        <div className="flex flex-col items-center">
          <Typography className="!text-center !mt-2" sx={{ fontSize: "10px" }}>
            Custom Credit is a trading name of Custom Credit Limited, a company
            registered in England & Wales (Reg 13288592) and is authorised and
            regulated by the Financial Conduct Authority (FRN 961764)
          </Typography>
          <Typography className="!mt-2" sx={{ fontSize: "10px" }}>
            Version: {APP_VERSION}
          </Typography>
        </div>
      </Stack>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: !sidebarState ? drawerWidth : 0 },
        flexShrink: { sm: 0 },
        transition: "all 300ms",
      }}
      aria-label="Sidebar"
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        className="sidebar"
        container={container}
        variant="temporary"
        open={sidebarState}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderTopRightRadius: "20px"
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        className="sidebar"
        variant="persistent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderTopRightRadius: "20px",
          },
        }}
        open={!sidebarState}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default Sidebar;
