import React from "react";
import {
  Typography,
  FormHelperText,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomSlider from "../../components/CustomSlider/CustomSlider";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import {
  createCase,
  getCase,
  updateCase,
  updateCaseCancellable,
} from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import IsEmptyOrValueUpdated, {
  calculateLoanCost,
  currencyFormat,
} from "../../utils";
import { addCustomerReference } from "../../api/oauth";
import {
  AUTH_ACTIONS,
  useAuthContext,
  useUpdateAuthContext,
} from "../../context/AuthContext/AuthContext";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import Stack from "@mui/material/Stack/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const LoanInitialDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LoanInitialDetailsFields = {}, CustomerDetails = {} } =
    useCaseInfoContext();
  const { ...authState } = useAuthContext();
  const { NetClaim = 5000, Term = 36 } = LoanInitialDetailsFields;
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const authDispatch = useUpdateAuthContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isCalculating, setIsCalculating] = React.useState(true);

  const [MonthlyPayment, setMonthlyPayment] = React.useState("");
  const [RolledUpInterestForTerm, setRolledUpInterestForTerm] =
    React.useState("");
  const [TotalRepaymentAmount, setTotalRepaymentAmount] = React.useState("");

  const loanAmountMarks = [
    {
      value: 1000,
      label: "£1000",
    },

    {
      value: 10000,
      label: "£10000",
    },
  ];
  const termMarks = [
    {
      value: 12,
      label: "12",
    },

    {
      value: 60,
      label: "60",
    },
  ];

  const validationSchema = yup.object({
    NetClaim: yup
      .number()
      .min(1000, "Minimum amount is 1000.")
      .max(10000, "Maximum amount is 10000."),
  });

  const formik = useFormik({
    initialValues: {
      NetClaim: Number(NetClaim),
      Term: Number(Term),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        IsEmptyOrValueUpdated(LoanInitialDetailsFields, values) &&
        CustomerDetails?.ApplicantID &&
        CustomerDetails?.CustomerReference
      )
        updateCase({
          //! hard coded ApplicantID and CustomerReference
          ApplicantID: CustomerDetails?.ApplicantID,
          CustomerReference: CustomerDetails?.CustomerReference,
          Payload: {
            NetClaim: values.NetClaim.toString(),
            Term: values.Term.toString(),
            InterestYearly:
              CustomerDetails?.[CustomerDetails?.ApplicantID]?.InterestYearly,
            WorkflowStep: "2.1",
            CaseStatus: "Application in Progress",
          },
        })
          .then((responseData) => {
            if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_LOAN_INITIAL_DETAILS,
                payload: { ...values },
              });
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                payload: {
                  [CustomerDetails?.[CustomerDetails?.ApplicantID]]: {
                    ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                    ...values,
                  },
                },
              });
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.UPDATE_CASELIST_CASE,
                payload: {
                  ...CustomerDetails?.[CustomerDetails?.ApplicantID],
                  ...values,
                },
              });
              return navigate("/current-address");
            } else {
              setToastMessage(
                responseData?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      else return navigate("/current-address");
    },
  });
  const [loanCost, setLoanCost] = React.useState(
    calculateLoanCost(
      formik?.values?.NetClaim,
      formik?.values?.Term,
      CustomerDetails?.[CustomerDetails?.ApplicantID]?.InterestYearly
    )
  );

  const createNewCase = React.useCallback(() => {
    setLoading(true);
    createCase({
      FirstName: authState?.user?.firstName,
      Surname: authState?.user?.surName,
      Mobile: authState?.user?.mobileNo,
      Email: authState?.user?.email,
    })
      .then((response) => {
        if (response?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          if (!authState?.user?.customerReference?.length) {
            addCustomerReference({
              email: authState?.user?.email,
              // caseId: response?.data?.d?.Value || "CA000163",
              customerReference: response?.data?.d?.OtherValue[0]?.Value,
            })
              .then((resData) => {
                if (resData?.status === 200 && resData?.data?.status) {
                  // console.log("response?.data?.d", response?.data?.d);
                  getCase({
                    CaseReference: response?.data?.d?.Value,
                  })
                    .then((getCaseResponse) => {
                      if (
                        Object?.keys(getCaseResponse?.data?.d?.CaseApplicant)
                          ?.length
                      ) {
                        caseInfoDispatch({
                          type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                          payload: {
                            ApplicantID: response?.data?.d?.Value || "CA000163",
                            CustomerReference:
                              response?.data?.d?.OtherValue[0]?.Value ||
                              "C000005",
                            [response?.data?.d?.Value]:
                              getCaseResponse?.data?.d?.CaseApplicant,
                            // ApplicantID: "CA000219",
                            // CustomerReference: "C000110",
                          },
                        });
                        return authDispatch({
                          type: AUTH_ACTIONS.UPDATECUSTOMERREFERENCE,
                          payload: {
                            // customerReference: resData?.data?.customerReference,
                            customerReference:
                              response?.data?.d?.OtherValue[0]?.Value,
                          },
                        });
                      }
                    })
                    .catch((err) => {
                      setToastMessage(
                        err?.data?.d?.EnquiryResult?.Message ||
                          err?.message ||
                          "Something went wrong"
                      );
                    });
                } else {
                  setToastMessage(
                    resData?.data?.message || "Something went wrong"
                  );
                }
              })
              .catch((err) =>
                setToastMessage(err?.message || "Something went wrong")
              );
          } else {
            getCase({
              CaseReference: response?.data?.d?.Value,
            })
              .then((getCaseResponse) => {
                if (
                  Object.keys(getCaseResponse?.data?.d?.CaseApplicant).length
                ) {
                  return caseInfoDispatch({
                    type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                    payload: {
                      ApplicantID: response?.data?.d?.Value || "CA000163",
                      CustomerReference:
                        response?.data?.d?.OtherValue[0]?.Value || "C000005",
                      [response?.data?.d?.Value]:
                        getCaseResponse?.data?.d?.CaseApplicant,
                      // ApplicantID: "CA000219",
                      // CustomerReference: "C000110",
                    },
                  });
                }
              })
              .catch((err) => {
                setToastMessage(
                  err?.data?.d?.EnquiryResult?.Message ||
                    err?.message ||
                    "Something went wrong"
                );
              });
          }
        } else {
          setToastMessage(
            response?.data?.d?.EnquiryResult?.Message || "Something went wrong"
          );
        }
      })
      .catch((err) =>
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message || "Something went wrong"
        )
      )
      .finally(() => setLoading(false));
  }, [
    authDispatch,
    authState?.user?.customerReference?.length,
    authState?.user?.email,
    authState?.user?.firstName,
    authState?.user?.mobileNo,
    authState?.user?.surName,
  ]);

  React.useEffect(() => {
    if (
      !Object.keys(LoanInitialDetailsFields).length &&
      ![
        "Initial Enquiry",
        "Application in Progress",
        "Pending Decision",
      ].includes(location?.state?.caseDetails?.CaseStatus)
    )
      return createNewCase();
  }, []);

  React.useEffect(() => {
    setMonthlyPayment("");
    setRolledUpInterestForTerm("");
    setTotalRepaymentAmount("");

    if (!formik.isValid || !CustomerDetails?.ApplicantID) return;

    setIsCalculating(true);

    updateCaseCancellable({
      ApplicantID: CustomerDetails?.ApplicantID,
      CustomerReference: CustomerDetails?.CustomerReference,
      Payload: {
        NetClaim: formik?.values?.NetClaim.toString(),
        Term: formik?.values?.Term.toString(),
        InterestYearly:
          CustomerDetails?.[CustomerDetails?.ApplicantID]?.InterestYearly,
      },
    })
      .then((res) => {
        getCase({
          CaseReference: CustomerDetails?.ApplicantID,
        }).then((getCaseResponse) => {
          if (Object.keys(getCaseResponse?.data?.d?.CaseApplicant).length) {
            const MonthlyPayment =
              getCaseResponse?.data?.d?.CaseApplicant?.MonthlyPayment;
            const RolledUpInterestForTerm =
              getCaseResponse?.data?.d?.CaseApplicant?.RolledUpInterestForTerm;
            const TotalRepaymentAmount =
              getCaseResponse?.data?.d?.CaseApplicant?.TotalRepaymentAmount;

            setMonthlyPayment(MonthlyPayment);
            setRolledUpInterestForTerm(RolledUpInterestForTerm);
            setTotalRepaymentAmount(TotalRepaymentAmount);
            setIsCalculating(false);
          }
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // do something
        } else {
          // setIsCalculating(false);
        }
      });
  }, [
    CustomerDetails,
    formik?.values?.NetClaim,
    formik?.values?.Term,
    formik?.isValid,
    setIsCalculating,
  ]);

  return (
    <ImageLayoutContainer
      title="LOAN DETAILS"
      handleClick={() => formik.handleSubmit()}
      noPadding
      goBackRoute="/home"
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      {!loading ? (
        <Stack
          direction="column"
          className="!mt-4"
          flex="1"
          justifyContent="center"
          width={{ sm: "auto", xs: "100%" }}
        >
          <form
            className="flex flex-col flex-1 justify-center p-5 pb-0"
            onSubmit={formik.handleSubmit}
          >
            <Typography
              className="!mt-4 !mb-4"
              sx={{ fontSize: "12px", letterSpacing: "0.19em" }}
            >
              Loan Amount
            </Typography>
            <CustomSlider
              className="!mt-5"
              min={1000}
              max={10000}
              step={10}
              getAriaLabel={(value: number) => `£${value}`}
              getAriaValueText={(value: number) => `£${value}`}
              valueLabelFormat={(value: number) => `£${value}`}
              marks={loanAmountMarks}
              defaultValue={formik.values.NetClaim}
              id="NetClaim"
              name="NetClaim"
              value={formik.values.NetClaim}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* <FormHelperText className="!text-red-600">
              {(formik?.touched?.NetClaim && formik?.errors?.NetClaim) || " "}
            </FormHelperText> */}
            <TextField
              placeholder="Loan Amount"
              label="Loan Amount"
              variant="outlined"
              id="NetClaim"
              className="!mt-2"
              name="NetClaim"
              type="number"
              value={formik.values.NetClaim}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.NetClaim && Boolean(formik.errors.NetClaim)}
              helperText={
                (formik.touched.NetClaim && formik.errors.NetClaim) || " "
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
            <Typography
              className="!mt-4 !mb-4"
              sx={{ fontSize: "12px", letterSpacing: "0.19em" }}
            >
              Term
            </Typography>
            <CustomSlider
              className="!mt-5"
              marks={termMarks}
              min={12}
              max={60}
              step={1}
              defaultValue={formik.values.Term}
              getAriaLabel={(value: number) => `${value}`}
              getAriaValueText={(value: number) => `${value}`}
              id="Term"
              name="Term"
              value={formik.values.Term}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormHelperText className="!text-red-600">
              {(formik.touched.Term && formik.errors.Term) || " "}
            </FormHelperText>
          </form>

          <Stack direction="column" flex="1" className="!mt-2">
            <Typography className="!text-xs">MONTHLY REPAYMENT</Typography>
            {isCalculating ? (
              <CircularProgress className="!mt-3" size={20} />
            ) : (
              <Typography
                className="!text-2xl !mt-2"
                color={(theme) => theme.palette.primary.main}
              >
                {currencyFormat(Number(MonthlyPayment))}
                {/* {currencyFormat(Number(loanCost?.monthlyRep))} */}
              </Typography>
            )}
          </Stack>
          {/* Typical APR Added Fixed */}
          <Stack direction="column" flex="1" className="!mt-2">
            <Typography className="!text-xs">
              REPRESENTATIVE APR (FIXED)
            </Typography>
            <Typography
              className="!text-2xl !mt-2"
              color={(theme) => theme.palette.primary.main}
            >
              24.9%
            </Typography>
          </Stack>
          <Stack direction="column" flex="1" className="!mt-2">
            <Typography className="!text-xs">TOTAL INTEREST</Typography>
            {isCalculating ? (
              <CircularProgress className="!mt-3" size={20} />
            ) : (
              <Typography
                className="!text-2xl !mt-2"
                color={(theme) => theme.palette.primary.main}
              >
                {currencyFormat(Number(RolledUpInterestForTerm))}
                {/* {currencyFormat(Number(loanCost?.interestRate))} */}
              </Typography>
            )}
          </Stack>
          <Stack direction="column" flex="1" className="!mt-2">
            <Typography className="!text-xs">TOTAL COST OF LOAN</Typography>
            {isCalculating ? (
              <CircularProgress className="!mt-3" size={20} />
            ) : (
              <Typography
                className="!text-2xl !mt-2"
                color={(theme) => theme.palette.primary.main}
              >
                {currencyFormat(Number(TotalRepaymentAmount))}
                {/* {currencyFormat(Number(loanCost?.totalCost))} */}
              </Typography>
            )}
          </Stack>
        </Stack>
      ) : (
        <CircularProgress size={24} />
      )}
    </ImageLayoutContainer>
  );
};

export default LoanInitialDetails;
