import Button from "@mui/material/Button";
import React from "react";
import BtnBackground from "../../assets/images/NextBtnBackground.png";

const GradiantButton = (props: {
  handleClick: (values: any) => void;
  btnText?: string;
}) => {
  return (
    <Button
      className="!mt-2"
      variant="contained"
      fullWidth
      onClick={props.handleClick}
      sx={{
        backgroundImage: `url(${BtnBackground})`,
        backgroundSize: "cover",
        height: "4.7rem",
        borderRadius: "0",
      }}
    >
      {props.btnText || "Next"}
    </Button>
  );
};

export default GradiantButton;
