import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import "./App.css";
import { theme } from "./theme";
import Login from "./pages/Login/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import GetStarted from "./pages/GetStarted/GetStarted";
import InitialDetials from "./pages/InitialDetials/InitialDetials";
import ImageLayout from "./components/Layout/ImageLayout";
import routes, { dashboardRoutes } from "./routes";
import CaseInfoProvider from "./context/CaseContext/CaseContext";
import ProtectedRoutes from "./ProtectedRoutes";
import AuthProvider from "./context/AuthContext/AuthContext";
import DashboardLayout from "./components/Layout/DashboardLayout";
import NeedHelp from "./pages/NeedHelp/NeedHelp";
import RequestToResetPassword from "./pages/RequestToResetPassword/RequestToResetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ProtectedCaseRoutes from "./components/ProtectedCaseRoute/ProtectedCaseRoute";
import ReactGA from 'react-ga';

ReactGA.initialize('G-4FW78BLR2S');
ReactGA.pageview(window.location.pathname + window.location.search);


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CaseInfoProvider>
        <AuthProvider>
          <CssBaseline />
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/initial-details" element={<InitialDetials />} />
              <Route path="/need-help" element={<NeedHelp />} />
              <Route
                path="/request-for-reset-password"
                element={<RequestToResetPassword />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route element={<ProtectedRoutes component={ImageLayout} />}>
                {routes.map((route) => (
                  <Route
                    key={route?.path}
                    path={route?.path}
                    element={<ProtectedCaseRoutes {...route} />}
                  />
                ))}
              </Route>
              <Route element={<ProtectedRoutes component={DashboardLayout} />}>
                {dashboardRoutes.map((route) => (
                  <Route
                    key={route?.path}
                    path={route?.path}
                    element={<route.element />}
                  />
                ))}
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>
        </AuthProvider>
      </CaseInfoProvider>
    </ThemeProvider>
  );
}

export default App;
