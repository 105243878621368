import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import ImageContainerWithBtns from "../../components/ImageContainer/ImageContainerWithBtns";
import { formStyle } from "../../utils";

const NeedHelp = () => {
  return (
    <>
      <ImageContainerWithBtns
        title="Contact Us"
        // handleClick={() => navigate("/initial-details")}
        noPadding
      >
        <Box sx={{ ...formStyle, padding: "0 22px 0 22px" }}>
          <Stack direction="column" flex="1" className="!mt-12">
            <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
              If you need any further help with your application please contact
              us on the details below
            </Typography>
            {/* <a href="tel:0800 802 1466"> */}
            <Button variant="contained" className="!mt-5" fullWidth>
              Call us on 0800 802 1466
            </Button>
            {/* </a> */}
            <a href="mailto:hello@customcredit.co.uk">
              <Button variant="contained" className="!mt-5" fullWidth>
                Email
              </Button>
            </a>
            <a
              href="https://www.customcredit.co.uk/faq/"
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="contained" className="!mt-5" fullWidth>
                Support
              </Button>
            </a>
          </Stack>
        </Box>
      </ImageContainerWithBtns>
    </>
  );
};

export default NeedHelp;
