import React from "react";
import { Box, Typography, Stack } from "@mui/material";
// import ImageContainer from "../../components/ImageContainer/ImageContainer";
import { useNavigate } from "react-router-dom";
import { formStyle } from "../../utils";
import ImageContainerWithBtns from "../../components/ImageContainer/ImageContainerWithBtns";

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <ImageContainerWithBtns
      title="APPLY"
      handleClick={() => navigate("/initial-details")}
      noPadding
    >
      <Box sx={{ ...formStyle, padding: "0 22px 0 22px" }}>
        <Stack direction="column" flex="1" className="!mt-12">
          <Typography className="!text-xs !w-5/6">
            A flexible loan to meet your needs and works with changes to your
            income and any unexpected expenses
          </Typography>
          <Typography className="!mt-2 !text-xs !w-5/6">
            Loans of £1,000 to £10,000 Loan duration one to five years
          </Typography>
          <Typography className="!mt-2 !text-xs !w-5/6">
            Representative APR 24.9% (fixed)
          </Typography>
          <Typography className="!mt-2 !text-xs !w-5/6">
            See if you qualify in as little as three minutes with no impact to
            your credit score
          </Typography>
        </Stack>
      </Box>
    </ImageContainerWithBtns>
  );
};

export default GetStarted;
