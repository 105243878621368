import React from "react";
import { Stack, Typography } from "@mui/material";
import BackButton from "../../components/BackButton/BackButton";
type CenterLayoutContainerPropType = {
  children: React.ReactNode;
  pageTitle?: string | React.ReactNode;
  goBackRoute?: string;
  justifyContent?: string;
  alignItems?: string;
};
const CenterLayoutContainer = (props: CenterLayoutContainerPropType) => {
  const { pageTitle, children } = props;
  return (
    <Stack
      direction="column"
      flex="1"
      justifyContent={props?.justifyContent}
      alignItems={props?.alignItems}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={props?.alignItems}
        width="100%"
      >
        <BackButton className="!mb-4 " goBackRoute={props?.goBackRoute} />
        <Typography
          variant="h6"
          color={(theme) => theme.palette.primary.main}
          sx={{ textAlign: "center" }}
        >
          {pageTitle}
        </Typography>
        <BackButton
          className="!mb-4 "
          goBackRoute={props?.goBackRoute}
          style={{ visibility: "hidden" }}
        />
      </Stack>
      {children}
    </Stack>
  );
};

export default CenterLayoutContainer;
