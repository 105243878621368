import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NeedHelpLink = (props: { style?: any }) => {
  const navigate = useNavigate();
  return (
    <Typography
      color="secondary"
      className="!mt-4"
      onClick={() => navigate("/need-help")}
      sx={{ cursor: "pointer", ...props?.style }}
      variant="h6"
    >
      Need Help?
    </Typography>
  );
};

export default NeedHelpLink;
