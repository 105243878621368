import React from "react";
import { Button, Stack, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import { ordoCollectPayment } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { currencyFormat } from "../../utils";

const MakePaymentOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const currentMonthly = CustomerDetails?.[CustomerDetails?.ApplicantID]?.MonthlyPayment
  const handleSubmit = () => {
    if (CustomerDetails?.[CustomerDetails?.ApplicantID])
      ordoCollectPayment({
        CaseReference: CustomerDetails?.ApplicantID,
        Amount: CustomerDetails?.[CustomerDetails?.ApplicantID]?.MonthlyPayment,
      })
        .then((responseData) => {
          if (responseData?.data?.d?.EnquiryResult.SuccessCode === 0)
            return navigate("/home");
          else
            setToastMessage(
              responseData?.data?.d?.EnquiryResult?.Message ||
                "Something went wrong"
            );
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };
  React.useEffect(() => {
    if (!location?.state?.caseDetails) return navigate("/home");
  }, [location?.state?.caseDetails, navigate]);
  return (
    <CenterLayoutContainer pageTitle="MAKE PAYMENT">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <Typography
        sx={{
          mt: 6,
          fontFamily: "Bio",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "27px",
        }}
      >
        Balance
      </Typography>
      <Typography
        sx={{ fontSize: "32px" }}
        color={(theme) => theme.palette.primary.light}
      >
        {currencyFormat(location?.state?.caseDetails?.TotalRepaymentAmount)}
      </Typography>

      {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate ? (
        <Stack
          gap={1}
          direction="row"
          alignItems="left"
          className="!mt-6"
        >
          <Typography className="!text-sm">Your next payment date is</Typography>
          <Typography
            color={(theme) => theme.palette.secondary.main}
            className="!text-sm"
          >
            {CustomerDetails?.[CustomerDetails?.ApplicantID]?.NextPaymentDate}
          </Typography>
          <Typography className="!text-sm">but you can pay via Open Banking Smart Pay now.</Typography>
        </Stack>
      ) : (
        ""
      )}
      {/* {CustomerDetails?.[CustomerDetails?.ApplicantID]?.Term ? (
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          className="!mt-6 !mb-14"
        >
          <Typography className="!text-xs">Current Monthly Amount</Typography>
          <Typography
            color={(theme) => theme.palette.secondary.main}
            className="!text-xs"
          >
            {CustomerDetails?.[CustomerDetails?.ApplicantID]?.Term}
          </Typography>
        </Stack>
      ) : (
        ""
      )} */}
      <Typography className="!text-sm">{`Your current monthly payment is £${currentMonthly}`}</Typography>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Pay Current Monthly Payment
      </Button>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          width: { sm: 275, xs: "100%" },
          mt: 3,
          justifySelf: "flex-end",
        }}
      >
        Pay Other Amount
      </Button>
      {/* <NeedHelpLink style={{ alignSelf: "center", justifySelf: "flex-end" }} /> */}
    </CenterLayoutContainer>
  );
};

export default MakePaymentOptions;
