import { Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getCase, searchCustomers } from "../../api/crm";
import CustomTable from "../../components/CustomTable/CustomTable";
import NeedHelpLink from "../../components/NeedHelpLink/NeedHelpLink";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import {
  currencyFormat,
  findNextPaymentDate,
  getEligibilityForHolidays,
} from "../../utils/index";

const Dashboard = () => {
  const navigate = useNavigate();
  const { CaseList = [], ...caseInfoState } = useCaseInfoContext();
  const { user } = useAuthContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const [isFetched, setIsFetched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const TotalLoanBalance = CaseList?.reduce(
    (a: any, b: any) => a + b?.TotalRepaymentAmount,
    0
  );
  const tableData = CaseList?.map((item: any) => {
      const dateCreated =
        item?.DateCreated.indexOf("-") === -1 &&
        new Date(+item?.DateCreated.replace(/\D/g, ""));

      const endDate =
        dateCreated &&
        moment(dateCreated.setMonth(dateCreated.getMonth() + 30)).format(
          "DD-MM-YYYY"
        );
      return {
        ...item,
        ApplicantID: item?.ApplicantID,
        NetClaim: item?.NetClaim,
        Term: item?.Term,
        CustomerReference: item?.CustomerReference,
        caseId: item?.ApplicantID,
        term: `${item?.Term} Months`,
        status: item?.CaseStatus,
        endDate: endDate || "21-03-2028",
        loanBalance: currencyFormat(item?.TotalRepaymentAmount),
        CaseStatus: item?.CaseStatus,
      };
    }),
    tableHeader = [
      { name: "Case ID", field: "caseId" },
      { name: "Term", field: "term" },
      { name: "Status", field: "status" },
      // { name: "End Date", field: "endDate" },
      { name: "Loan Balance", field: "loanBalance" },
    ];

  const fetchCaseInfo = React.useCallback(() => {
    setLoading(true);
    if (user?.customerReference)
      searchCustomers({
        CustomerReference: user?.customerReference,
      })
        .then((response) => {
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
            payload: {
              ...response?.data?.d?.Records?.[0],
            },
          });
          const promises = response?.data?.d?.Records?.[0]?.CustomerCases?.map(
            (item: any) => {
              return getCase({
                CaseReference:
                  item?.CaseRef ||
                  caseInfoState?.CustomerDetails?.ApplicantID ||
                  "CA000072",
              })
                .then((response) => {
                  if (Object.keys(response?.data?.d?.CaseApplicant).length)
                    return response?.data?.d?.CaseApplicant;
                })
                .catch((err) => {
                  setToastMessage(
                    err?.data?.d?.EnquiryResult?.Message ||
                      err?.message ||
                      "Something went wrong"
                  );
                });
            }
          );
          Promise.all(promises)
            .then((result) => {
              const filteredResults = result.filter(
                (item) => typeof item === "object"
              );
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.FETCHED_CASELIST,
                payload: filteredResults,
              });
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
                payload: {
                  ApplicantID:
                    filteredResults[filteredResults.length - 1]?.ApplicantID,
                  CustomerReference:
                    filteredResults[filteredResults.length - 1]
                      ?.CustomerReference,
                  [filteredResults[filteredResults.length - 1]?.ApplicantID]: {
                    ...filteredResults[filteredResults.length - 1],
                  },
                },
              });
              setIsFetched(true);
            })
            .catch((err) => setToastMessage("Something went wrong"))
            .finally(() => setLoading(false));
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
    else {
      setIsFetched(true);
      setLoading(false);
    }
  }, [
    caseInfoDispatch,
    caseInfoState?.CustomerDetails?.ApplicantID,
    user?.customerReference,
  ]);

  React.useEffect(() => {
    if (!isFetched) fetchCaseInfo();
  }, [fetchCaseInfo, isFetched]);

  // React.useEffect(() => {
  //   if (caseInfoState?.CustomerDetails)
  //     caseInfoDispatch({ type: CASEINFO_ACTIONS_TYPES.REMOVE_CASE_DETAILS });
  // }, []);

  const handleClickOnCase = (
    applicantID: string,
    customerReference: string,
    casedetails: any
  ) => {
    const NextPaymentDate = findNextPaymentDate(
      casedetails?.PaymentPlanDetail?.PaymentPlanDetails
    );
    caseInfoDispatch({
      type: CASEINFO_ACTIONS_TYPES.ADD_CASE_INDENTIFIERS,
      payload: {
        ApplicantID: applicantID,
        CustomerReference: customerReference,
        [applicantID]: {
          ...casedetails,
          IsEligibleForHolidays: getEligibilityForHolidays(casedetails),
          NextPaymentDate,
        },
      },
    });
  };

  return (
    <Stack direction="column" flex="1">
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap="8px"
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Bio",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "22px",
            lineHeight: "27px",
          }}
        >
          Welcome back!
          <br /> Great to see you again.
        </Typography>
        {/* <Button
          variant="contained"
          onClick={() => navigate("/loan-initial-details")}
        >
          Apply for a new Loan
        </Button> */}
      </Stack>
      <Typography className="!text-sm !font-medium" sx={{ mt: 6 }}>
        Total Loan(s) Balance
      </Typography>
      <Typography
        sx={{
          fontSize: "24px",
          color: (theme) => theme.palette.primary.light,
          fontWeight: 700,
        }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          currencyFormat(TotalLoanBalance)
        )}
      </Typography>

      {!loading && CaseList.length ? (
        <CustomTable
          tableData={tableData}
          tableHeader={tableHeader}
          handleClickOnCase={handleClickOnCase}
        />
      ) : (
        ""
      )}

      <Stack
        direction="column"
        flex="1"
        justifyContent="flex-end"
        alignItems="center"
      >
        <NeedHelpLink />
      </Stack>
    </Stack>
  );
};

export default Dashboard;
