import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import IsEmptyOrValueUpdated, {additionalUrlParams, getUrlParam} from "../../utils";
import { updateCase } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MarketingOptIns = () => {
  const navigate = useNavigate();
  const { MarketingOptInsFields = {}, ...caseInfoState } = useCaseInfoContext();
  const { Options = [] } = MarketingOptInsFields;
  const [selectoption, setSelectoption] = React.useState<string[]>(Options);
  const [error, setError] = React.useState<string>("");
  const [toastMessage, setToastMessage] = React.useState("");

  const caseInfoDispatch = useUpdateCaseInfoContext();
  const optionsList = ["Phone", "Email", "SMS", "Post"];
  const handleSubmit = (values?: any) => {

    if (selectoption.length) {
      setError("");
      if (
        IsEmptyOrValueUpdated(MarketingOptInsFields, { Options: selectoption })
      )
        updateCase({
          ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID ?? getUrlParam('ApplicantID'),
          CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? getUrlParam('CustomerReference'),
          Payload: {
            DynamicFieldDetail: [
              {
                FieldNo: 18,
                FieldValue: selectoption?.join(","),
              },
            ],
          },
        })
          .then((responseData: any) => {
            if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
              caseInfoDispatch({
                type: CASEINFO_ACTIONS_TYPES.ADD_MARKETING_OPT_IN,
                payload: { Options: selectoption },
              });
              navigate("/home");
            } else {
              setToastMessage(
                responseData?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      navigate("/home");
    } else {
      navigate("/home");
      // setError("This field is required");
    }
  };

  return (
    <ImageLayoutContainer
      title="MARKETING"
      noPadding
      handleClick={() => handleSubmit()}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />

      <form
        className="flex flex-col flex-1 w-full mt-12"
        onSubmit={handleSubmit}
        style={{ padding: "0px 22px 0px 22px" }}
      >
        <Typography className="!w-5/6" sx={{ fontSize: "12px" }}>
          Almost all set up now. Please choose your preferred marketing options below. Choose as many as you like (or none at all) and click the 'Next' button"
        </Typography>
        <FormControl>
          <InputLabel id="options">Opt Ins</InputLabel>
          <Select
            inputProps={{
              className: "!h-2 !w-full",
            }}
            labelId="options"
            id="options-checkbox"
            multiple
            value={selectoption}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              if (value) {
                setSelectoption(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? value.split(",") : value
                );
                setError("");
              }
              // if (!value.length) {
              //   setError("This field is required");
              // }
            }}
            input={<OutlinedInput label="Options" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {optionsList.map((item) => (
              <MenuItem key={item} value={item} sx={{ width: "100%" }}>
                <Checkbox checked={selectoption.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText className="!text-red-600">
          {(error && !selectoption.length && error) || " "}
        </FormHelperText>
      </form>
    </ImageLayoutContainer>
  );
};

export default MarketingOptIns;
