import React, { useEffect, useState } from "react";
import { Box, Button, Link, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { createDocumentToPdf, updateCase } from "../../api/crm";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";

const PreAgreementDoc = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");

  const handleSubmit = (values: any) => {
    if (CustomerDetails?.ApplicantID)
      updateCase({
        ApplicantID: CustomerDetails?.ApplicantID,
        CustomerReference: CustomerDetails?.CustomerReference,
        Payload: {
          "WorkflowStep": "4.3",
          CaseStatus: "Pending Customer Signature",
        },
      })
        .then((responseData) => {
          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
            return navigate("/flex-explained");
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };

  // const url = "https://arxiv.org/pdf/quant-ph/0410100.pdf";

  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchPDFURL = () =>
      createDocumentToPdf({
        ApplicantID: CustomerDetails?.ApplicantID,
        LetterCode: "Custom Credit Pre Contract Information",
      })
        .then((response) => {
          if (response?.data?.d?.ReturnUrl) {
            setUrl(response?.data?.d?.ReturnUrl);
          } else {
            setToastMessage(
              response?.data?.d?.Result?.Message || "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.Result?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
    if (CustomerDetails?.ApplicantID) fetchPDFURL();
  }, [CustomerDetails?.ApplicantID]);

  return (
    <ImageLayoutContainer
      noPadding
      title="PRE CONTRACT"
      handleClick={handleSubmit}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer
        flex="1"
        className="!mt-12 !w-full"
        justifyContent="flex-start"
      >
        <Stack
          direction="column"
          flex="1"
          sx={{
            p: "20px 17px",
            background: "rgba(217, 217, 217, 0.29)",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ height: { sm: "390px" }, flex: "1", width: "100%" }}
          >
            <object
              data={url}
              type="application/pdf"
              height="390px"
              width="100%"
            ></object>
            <Link href={url} target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                sx={{
                  width: { sm: 248, xs: "100%" },
                  mt: 3,
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              >
                Open PDF
              </Button>
            </Link>
          </Box>
        </Stack>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default PreAgreementDoc;
