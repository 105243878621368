import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { currencyFormat } from "../../utils";

const months = [
  {
    label: "JAN",
  },
  {
    label: "FEB",
  },
  {
    label: "MAR",
  },
  {
    label: "APR",
  },
  {
    label: "MAY",
  },
  {
    label: "JUN",
  },
  {
    label: "JUL",
  },
  {
    label: "AUG",
  },
  {
    label: "SEP",
  },
  {
    label: "OCT",
  },
  {
    label: "NOV",
  },
  {
    label: "DEC",
  },
  {
    label: "ALL",
  },
];

const MonthTabs: React.FC<{
  transactions: any;
  getDataForSelectedMonth: (month: string) => void;
}> = ({ transactions, getDataForSelectedMonth }) => {
  const [activeTab, setActiveTab] = React.useState("ALL");
  useEffect(() => {
    const selectedDiv = document.getElementById(activeTab);
    if (selectedDiv)
      setTimeout(
        () =>
          selectedDiv.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
          }),
        100
      );
  }, [activeTab]);

  const handleTabClick = (month: string) => {
    setActiveTab(month);
    getDataForSelectedMonth(month);
  };

  return (
    <Stack
      direction="column"
      //   overflow="auto"
      maxWidth={{
        sm: "calc(84vw - 240px)",
        // sm: "30%",
        xs: "84vw",
      }}
      // className="something"
      sx={{ mt: 3, width: { sm: "70%" } }}
    >
      <Stack direction="row" gap="2px" overflow="auto">
        {months.map((item) => (
          <Box
            key={item.label}
            id={item.label}
            className="!pt-6 !pb-6 !pl-8 !pr-8 !text-xs "
            sx={(theme) => ({
              backgroundColor:
                activeTab === item.label
                  ? "rgba(255, 255, 255, 0.2)"
                  : theme.palette.primary.main,
              color: activeTab === item.label ? "black" : "white",
            })}
            onClick={() => handleTabClick(item.label)}
          >
            {item.label}
          </Box>
        ))}
      </Stack>
      <Stack direction="column">
        {transactions?.map((item: any) => (
          <Stack
            direction="row"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction={{ sm: "row", xs: "column" }}
              sx={{
                gap: { sm: 3 },
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                padding: "24px 0 24px 20px",
              }}
              flex="1.5"
            >
              <Typography className="!text-xs">
                {item?.TransactionCode}
              </Typography>
              <Typography className="!text-xs">Payment</Typography>
              <Typography className="!text-xs">
                {item?.TransactionDate?.split(" ")[0]}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              flex="1"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: { sm: "20px 20px 20px 0", xs: "32px 20px 32px 0" },
              }}
            >
              <Typography className="!text-xl">
                {currencyFormat(item?.GrossAmount)}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default MonthTabs;
