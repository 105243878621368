import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import {
  accessPaysuiteCreateContract,
  accessPaysuiteCreateCustomer,
  accessPaysuiteIsStartDateValidatForContract,
  isAccessPaysuiteContractCreated,
  isAccessPaysuiteCustomerCreated,
  ordoVRPCreateMandate,
} from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import FormComponent from "../../components/Forms/FormComponent";
import useADPData from "../../hooks/useADPData";
import moment from "moment";

const PaymentOptions = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const { ApplicantID } = CustomerDetails;
  const selectedCase = CustomerDetails?.[CustomerDetails?.ApplicantID];
  const { getADPDataValueByDescription } = useADPData(
    CustomerDetails?.ApplicantID
  );

  const flexHighPayment = getADPDataValueByDescription("CAT 14", "FlexMax");

  const [toastMessage, setToastMessage] = React.useState("");

  const handleSubmit = (values: any) => {
    const handleDirectDebitPayment = async () => {
      try {
        // Checks if start date is valid for creating a contract
        const isStartDateValid =
          await accessPaysuiteIsStartDateValidatForContract({
            StartDate: moment(
              selectedCase.PaymentPlanDetail.FirstPaymentDate
            ).format("DD-MM-YYYY"),
          });

        if (!isStartDateValid.data.d.Valid)
          throw new Error(isStartDateValid?.data?.d?.ErrorMessage);

        // Checks if customer was already created
        const customerData = await isAccessPaysuiteCustomerCreated({
          ApplicantID,
        }).then((res) => res.data.d);

        const isCustomerExist = customerData.IsCreated;

        // Create a customer if not exists
        if (!isCustomerExist) {
          const createCustomer = await accessPaysuiteCreateCustomer({
            ApplicantID,
            Customer: customerData.Customer,
          }).then((res) => res.data.d);

          if (!createCustomer) throw new Error(createCustomer?.Message);
        }

        // Checks if a contract was already created
        const contractData = await isAccessPaysuiteContractCreated({
          ApplicantID,
        }).then((res) => res.data.d);

        const isContractExist = contractData.IsCreated;

        // Create a contract, it not exists
        if (isContractExist) {
          return navigate("/payment-verify");
        } else {
          const createContract = await accessPaysuiteCreateContract({
            ApplicantID,
            ScheduleId: contractData?.Contract?.ScheduleId,
          }).then((res) => res.data.d);

          if (createContract.IsCreated) {
            return navigate("/payment-verify");
          }

          throw new Error(createContract?.Message);
        }
      } catch (err: any) {
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        );
      }
    };

    const handleVRPPayment = async () => {
      var windowReference: any = window.open();
      try {
        const mandateData = await ordoVRPCreateMandate({
          MandateData: {
            CaseReference: ApplicantID,
            MaximumIndividualAmount: flexHighPayment, //flexHighPayment
            PeriodTypeLimitAmount: flexHighPayment, //flexHighPayment
          },
        }).then((res) => res.data);

        const isMandateCreated =
          mandateData?.d?.EnquiryResult.SuccessCode === 0;

        if (isMandateCreated && mandateData.d.AuthUrl) {
          const URL = mandateData.d.AuthUrl;
          if (URL) {
            // window.open(URL, "_blank");
            windowReference.location = URL;
            return navigate("/payment-verify");
          }
        }

        throw new Error(
          mandateData?.d?.Message || mandateData?.d?.EnquiryResult?.Message
        );
      } catch (err: any) {
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        );
      }
    };

    if (selectedCase) {
      switch (values.PaymentType) {
        case "Direct Debit":
          return handleDirectDebitPayment();
        case "VRP":
          return handleVRPPayment();
        default:
          return setToastMessage("Invlaid payment option selected.");
      }
    }
  };

  const textFields = [
    {
      fieldName: "PaymentType",
      defaultValue: "",
      label: "Payment Type",
      type: "select",
      options: [
        {
          value: "VRP",
          title: "VRP",
        },
        {
          value: "Direct Debit",
          title: "Direct Debit",
        },
      ],
      additionalElement: (
        <>
          <Typography className="!w-5/6 !text-xs !mb-4">
            Please select payment option
          </Typography>
        </>
      ),
    },
  ];
  return (
    <ImageLayoutContainer title="PAYMENT OPTIONS" noPadding>
      <Stack
        direction="column"
        className="!mt-12 !mb-0"
        justifyContent="flex-start"
        sx={{ p: " 0 22px 0 22px" }}
      >
        {/* <Typography className="!text-sm">Payment Method</Typography> */}
        <Typography className="!text-sm w-4/5 !mt-3">
          The money will be transferred to the sort code and account number of
          the primary account you have provided.
        </Typography>
        <Typography className="!text-sm w-4/5 !mt-3">
          VRP is a new type of payment that helps us be more flexible with your
          payments. It also doesn't incur any costs to you if the payment fails.
        </Typography>
      </Stack>
      <FormComponent textFields={textFields} handleSubmit={handleSubmit} />
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
    </ImageLayoutContainer>
  );
};

export default PaymentOptions;
