import React from "react";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";

const FailedCreditChecks = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submitBtnURL = location?.state?.submitBtnURL
      ? location.state.submitBtnURL
      : "/home",
    submitBtnText = location?.state?.submitBtnURL
      ? location.state.submitBtnText
      : null;

  const handleSubmit = () => {
    navigate(submitBtnURL);
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="We’re Sorry"
      handleClick={handleSubmit}
      btnText={submitBtnText}
    >
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!text-xs !mt-12 !w-5/6">
          Unfortunately, we have been unable to accept your application for a Custom Credit loan at this time.
        </Typography>
        <Typography className="!text-xs !mt-4 !w-5/6">
          We’ve taken this decision based upon a range of factors including the details you have provided and information received from the credit reference agencies.
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default FailedCreditChecks;