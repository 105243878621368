import React from "react";
import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

type CustomTableProps = {
  tableData: any;
  tableHeader: any;
  handleClickOnCase: (
    applicantID: string,
    customerReference: string,
    casedetails: {
      ApplicantID: string;
      CustomerReference: string;
      NetClaim: number;
      Term: number;
    }
  ) => void;
};

const CustomTable = (props: CustomTableProps) => {
  const { tableData, tableHeader, handleClickOnCase } = props;
  const getRedirectURLFromStatus = (item: any) => {
    switch (item?.CaseStatus) {
      case "Initial Enquiry":
        return "/loan-initial-details";
      case "Application in Progress":
        return "/current-address";
      case "Pending Decision":
        return "/open-banking3";
      case "Finalise Loan":
        return "/welcome-after-open-banking";
      // Both "Loan Refused by Custom Credit" & "Declined" will redirect to "/failed-credit-checks".
      case "Loan Refused by Custom Credit":
      case "Declined":
        return "/failed-credit-checks";
      case "Awaiting Customer Acceptance":
        return "/pre-agreement-doc";
      case "Pending Customer Signature":
        return "/loan-agreement-doc";
      case "Payment in process":
        return "/disburse-payment";
      case "ADP Checks":
        return "/open-banking3";
      case "":
        return "";

      default:
        return `/case/${item?.[tableHeader[0]?.field]}`;
    }
  };
  return (
    <Stack
      direction="column"
      gap="8px"
      overflow="auto"
      maxWidth="84vw"
      sx={{ mt: 3, width: { sm: "70%" } }}
    >
      {tableData.map((item: any, index: number) => (
        <Link
          to={getRedirectURLFromStatus(item)}
          key={`/case/${item?.[tableHeader[0]?.field]}`}
          state={{ caseDetails: item }}
          onClick={() =>
            handleClickOnCase(item?.ApplicantID, item?.CustomerReference, {
              ...item,
              ApplicantID: item?.ApplicantID,
              CustomerReference: item?.CustomerReference,
              NetClaim: item?.NetClaim,
              Term: item?.Term,
            })
          }
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            padding="10px 0 10px 0"
            key={index}
            borderRadius="8px"
            color="#fff"
            sx={{
              minWidth: { xs: 0, sm: "700" },
              background: (theme) => theme.palette.secondary.main,
            }}
          >
            {tableHeader.map((title: any, headerIndex: number) => (
              <Box key={headerIndex}>
                <Box
                  flexDirection="column"
                  sx={{ display: { lg: "flex", xs: "none" } }}
                >
                  <Typography sx={{ fontSize: "11px" }}>
                    {title?.name}
                  </Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                    {item?.[title?.field]}
                  </Typography>
                </Box>
                <Box
                  flexDirection="column"
                  sx={{ display: { lg: "none", xs: "flex" } }}
                >
                  {headerIndex === 0 ||
                  headerIndex === tableHeader.length - 1 ? (
                    <>
                      <Typography sx={{ fontSize: "11px" }}>
                        {title?.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                        {item?.[title?.field]}
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            ))}
            <CircleIcon
              sx={{
                width: "11px",
                height: "11px",
                color: "#D9D9D9",
                display: { xs: "block", lg: "none" },
              }}
            />
          </Stack>
        </Link>
      ))}
    </Stack>
  );
};

export default CustomTable;
