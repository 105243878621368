import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { updateCase } from "../../api/crm";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import {getUrlParam} from "../../utils";

const Congratulations = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const handleSubmit = (values: any) => {

    if(!CustomerDetails.ApplicantID && getUrlParam('ApplicantID')) {
        CustomerDetails.ApplicantID = getUrlParam('ApplicantID');
        CustomerDetails.CustomerReference = getUrlParam('CustomerReference');
    }

    if (CustomerDetails?.ApplicantID)
    updateCase({
      ApplicantID: CustomerDetails?.ApplicantID,
      CustomerReference: CustomerDetails?.CustomerReference,
      Payload: {
        "WorkflowStep": "4.4",
        CaseStatus: "Pending Customer Signature",
      },
    })
      .then((responseData) => {
        if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
          return navigate("/loan-agreement-doc");
      })
      .catch((err) => {
        setToastMessage(
          err?.data?.d?.EnquiryResult?.Message ||
            err?.message ||
            "Something went wrong"
        );
      });
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="Congratulations"
      handleClick={handleSubmit}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!text-sm w-4/5 !mt-12 ">
          Congratulations on being approved for a Custom Credit loan.
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default Congratulations;
