import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import CCLogo from "../../assets/images/CCLogo.png";
import image from "../../assets/images/Bubbles.svg";
import { Box, Stack, Typography } from "@mui/material";
import GradiantBG from "../../assets/images/GradiantBG.png";
import { getLeftSideData } from "../../utils";

const Layout = () => {
  const location = useLocation();
  const pageRoute = location.pathname;
  const leftSideData = getLeftSideData(pageRoute);
  const outerContainerStyle = {
      flex: "1",
      justifyContent: "center",
      alignItems: "center",
    },
    imageContainerStyle = {
      display: { xs: "none", sm: "block", xl: "block", lg: "block" },
      flex: "1.9",
    };
  return (
    <Stack direction="row" sx={{ ...outerContainerStyle }}>
      <Box sx={{ ...imageContainerStyle }}>
        <div
          className="object-cover h-screen w-full"
          style={{
            backgroundImage: `url(${GradiantBG})`,
            backgroundSize: "cover",
            mixBlendMode: "normal",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="27px"
            padding="16px 25px"
          >
            <img
              src={CCLogo}
              alt="cc-logo"
              className="w-16 h-16"
              style={{
                filter: "drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.5))",
              }}
            />
            <Typography variant="h1" color="white">
              Custom Credit
            </Typography>
          </Stack>
          <Stack flex="1" justifyContent="center" alignItems="center">
            {leftSideData?.imageURL ? (
              <img
                width="50%"
                height="auto"
                src={leftSideData.imageURL}
                alt="Image"
              />
            ) : (
              <Typography
                variant="h2"
                color="white"
                className="leftSideMessage"
              >
                {leftSideData?.message}
              </Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="27px"
            padding="16px 25px"
            visibility="hidden"
          >
            <img
              src={CCLogo}
              alt="cc-logo"
              className="w-16 h-16"
              style={{
                filter: "drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.5))",
              }}
            />
            <Typography variant="h1" color="white">
              Custom Credit
            </Typography>
          </Stack>
        </div>
      </Box>
      <img
        src={image}
        alt="bubbles"
        style={{ position: "absolute", top: "53px", right: "0px", zIndex: -1 }}
      />
      <Outlet />
    </Stack>
  );
};

export default Layout;
