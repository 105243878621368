import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { additionalUrlParams } from "../../utils";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import useADPData from "../../hooks/useADPData";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";

const FlexExplained = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/congratulations" + additionalUrlParams());
  };

  const { CustomerDetails = {} } = useCaseInfoContext();

  const {
    // isLoading,
    // ADPData,
    // getADPDataValueByID,
    getADPDataValueByDescription,
  } = useADPData(CustomerDetails?.ApplicantID);

  // const flexAPR = getADPDataValueByDescription("CAT 11", "APRValue"); //TODO: ADPCall and Description to be changed.
  const flexLowPayment = getADPDataValueByDescription("CAT 14", "FlexPayment");
  const flexHighPayment = getADPDataValueByDescription("CAT 14", "FlexMax");
  const term = Number(CustomerDetails?.[CustomerDetails?.ApplicantID]?.Term);
  const loanAmountTotal = Number(
    CustomerDetails?.[CustomerDetails?.ApplicantID]?.TotalRepaymentAmount
  );
  const yearlyInterest = Number(
    CustomerDetails?.[CustomerDetails?.ApplicantID]?.InterestYearly
  );
  const monthlyRepayment = Number(
    CustomerDetails?.[CustomerDetails?.ApplicantID]?.PaymentPlanDetail.Repayment
  );

  return (
    <ImageLayoutContainer
      noPadding
      title="FLEX EXPLAINED"
      handleClick={handleSubmit}
    >
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        {/* <Typography className="!mt-12 !text-xs !w-5/6">
          {`Your Custom Credit loan Monthly Payment of
          £${monthlyRepayment} is flexible.`}
        </Typography> */}
        <Typography className="!mt-4 !text-xs !w-3/4">
          {`Each month you can pay within your affordability range i.e. as much as £${flexHighPayment} or as low as £${flexLowPayment}`}
        </Typography>
        <Typography className="!mt-4 !text-xs !w-5/6">
          {`You can pay more than the Monthly Payment and this will make the loan cheaper overall.`}
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default FlexExplained;
