import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      // main: "#E478F7",
      main: "#C54EC1",
      dark: "#790252",
      light: "#E478F7",
    },
    secondary: {
      // main: "#7BD0D4",
      main: "#01C6D1",
    },
    background: {
      // default: "#F7DDFC",
      default: "#FFF",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Urbanist",
      fontWeight: "500",
      fontSize: "15px",
      letterSpacing: "0.15em",
    },
    h1: {
      fontFamily: "Earth Orbiter Bold",
      fontWeight: "400",
      fontSize: "20px",
      letterSpacing: "0.15em",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: "Urbanist",
      fontWeight: "500",
      fontSize: "22px",
      letterSpacing: "0.15em",
      fontStyle: "normal",
    },
    h6: {
      fontFamily: "Earth Orbiter Bold",
      fontWeight: "400",
      letterSpacing: "0.15em",
      fontStyle: "normal",
      lineHeight: "18px",
      fontSize: "20px",
    },
    subtitle1: {
      fontFamily: "Earth Orbiter Bold",
      fontWeight: "400",
      letterSpacing: "0.15em",
      fontStyle: "normal",
      lineHeight: "22px",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          width: "248px",
          // textAlign: "center",
          background: "#F7F8F9",
          fontFamily: "Urbanist",
          borderRadius: "8px",
        },

        root: {
          "&.MuiInputBase-root": {
            borderRadius: "8px",
            border: "1px solid #E8ECF4",
            background: "#F7F8F9",
            // borderColor: "#E8ECF4",
            // borderWidth: "1px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          borderRadius: "8px",
          fontFamily: "Urbanist",
          fontStyle: "normal",
          fontSize: "15px",
          letterSpacing: "0.01em",
          textTransform: "capitalize",
          padding: "11px 16px",
        },
      },
    },
  },
});
