import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { fieldStatuses } from "../FormComponent";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
type TextFieldProps = {
  item: {
    fieldName: string;
    label?: string;
    type: string;
    value: string;
    onChange:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    onBlur:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    error: boolean | undefined;
    helperText: string;
    handleBlur?:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    isVerified?: string;
    options?: { value: string; title: string }[];
    extraProps?: any;
  };
};
const CustomTextField = (props: TextFieldProps) => {
  const { item } = props;
  const {
    fieldName,
    label,
    type,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    extraProps = {},
  } = item;
  const [isPassVisible, setIsPassVisible] = React.useState(false);
  return (
    <TextField
      inputProps={{
        className: `${type === "textarea" ? "!text-left" : " "} !w-full`,
        ...extraProps,
      }}
      fullWidth
      InputProps={
        item?.isVerified === fieldStatuses.verified
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <VerifiedIcon color="success" />
                </InputAdornment>
              ),
            }
          : item?.isVerified === fieldStatuses.notVerified
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <NewReleasesIcon color="error" />
                </InputAdornment>
              ),
            }
          : type === "password"
          ? {
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setIsPassVisible((preValue) => !preValue)}
                  sx={{ cursor: "pointer" }}
                >
                  {!isPassVisible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputAdornment>
              ),
            }
          : {}
      }
      placeholder={label ? label : fieldName.toUpperCase()}
      variant="outlined"
      id={fieldName}
      name={fieldName}
      type={
        type !== "textarea"
          ? type === "password"
            ? isPassVisible
              ? "text"
              : "password"
            : type
          : undefined
      }
      multiline={type === "textarea" ? true : false}
      rows={type === "textarea" ? 4 : undefined}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      onKeyPress={
        type === "number"
          ? (e: any) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
          : (e) => {}
      }
    />
  );
};

export default CustomTextField;
