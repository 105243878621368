import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./context/AuthContext/AuthContext";

const ProtectedRoutes = ({ component: Component, ...restProps }) => {
  const location = useLocation();
  const authState = useContext(AuthContext);
  if (!authState?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <Component {...restProps} />;
};

export default ProtectedRoutes;
