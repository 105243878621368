import React from "react";
import { AxisBandOptions, AxisOptions, Chart } from "react-charts";
import { Box } from "@mui/material";

type DataType = { primary: string; months: number };

type CustomChartProps = {
  data: { label: string; data: DataType[] }[];
};

const CustomChart = (props: CustomChartProps) => {
  const { data } = props;
  const axisTitles = Object.keys(data?.[0]?.data?.[0]);
  const primaryAxis = React.useMemo<AxisBandOptions<DataType>>(
    () => ({
      getValue: (datum) => (datum as any)[axisTitles[0]], //? datum[axisTitles[0]] statement in type script
    }),
    [axisTitles]
  );
  const secondaryAxes = React.useMemo<AxisOptions<DataType>[]>(
    () => [
      {
        getValue: (datum) => (datum as any)[axisTitles[1]],
        elementType: "line",
      },
    ],
    [axisTitles]
  );

  const getSeriesStyle = React.useCallback(
    () => ({
      // fill: "#E478F7",
      color: "#E478F7",
      strokeWidth: "4px",
    }),
    []
  );
  return (
    <Box sx={{ width: "100%", height: { sm: "20rem", xs: "50%" } }}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          tooltip: false,
          getSeriesStyle: getSeriesStyle,
        }}
      />
    </Box>
  );
};

export default CustomChart;
