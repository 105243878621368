import React, { useRef } from "react";
import FormComponent, { getYup } from "../../components/Forms/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { getPostcodeLookup, updateCase, updateCustomer } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import IsEmptyOrValueUpdated, {
    additionalUrlParams, getUrlParam,
} from "../../utils";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import moment from "moment";
import { TextFieldProps, Typography } from "@mui/material";
import { differenceInYears } from "date-fns";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";

const CurrentAddress = () => {
  const { CurrentAddressFields = {}, ...caseInfoState } = useCaseInfoContext();
  let {
    // DateOfBirth = "",
    // UkVerification = "",
    // PostCode = "",
    // HouseNumber = "",
    // HouseName = "",
    // Address1 = "",
    // Address2 = "",
    // Address3 = "",
    // Address4 = "",
    // TimeInYears = "",
    // TimeInMonths = "",
  } = CurrentAddressFields;
  const dateRef = useRef(null);
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const [DateOfBirth, setDateOfBirth] = React.useState("");
  const [UkVerification, setUkVerification] = React.useState("");
  const [PostCode, setPostCode] = React.useState("");
  const [HouseNumber, setHouseNumber] = React.useState("");
  const [HouseName, setHouseName] = React.useState("");
  const [Address1, setAddress1] = React.useState("");
  const [Address2, setAddress2] = React.useState("");
  const [Address3, setAddress3] = React.useState("");
  const [Address4, setAddress4] = React.useState("");
  const [TimeInYears, setTimeInYears] = React.useState("");
  const [TimeInMonths, setTimeInMonths] = React.useState("");
  const [minMonthAtAddress, setMinMonthAtAddress] = React.useState(
    TimeInYears ? (Number(TimeInYears) > 0 ? 0 : 1) : 1
  );
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = async (values: any) => {
    if (
      IsEmptyOrValueUpdated(CurrentAddressFields, {
        ...CurrentAddressFields,
        ...values,
        Id: null,
        TimeInYears: values.TimeInYears.toString(),
        TimeInMonths: values.TimeInMonths.toString(),
      })
    ) {
      // Update Customer details before updating the case.
      const queryParameters = new URLSearchParams(window.location.search)
      const updatedCustomer = await updateCustomer({
        IsUpdateCaseCustomer: true,
        CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? queryParameters.get('CustomerReference'),
        DateOfBirth: moment(values?.DateOfBirth).format("MM-DD-YYYY"),
        CustomerAddress: {
          ...values,
          TimeInYears: values.TimeInYears.toString(),
          TimeInMonths: values.TimeInMonths.toString(),
        },
        Email: "",
        Mobile: "",
      });

      // If no user data updated return error
      if (!updatedCustomer.data) return;
      updateCase({
        ApplicantID: caseInfoState?.CustomerDetails?.ApplicantID  ?? queryParameters.get('ApplicantID'),
        CustomerReference: caseInfoState?.CustomerDetails?.CustomerReference ?? queryParameters.get('CustomerReference'),
        Payload: {
          MainApplicant: {
            DateOfBirth: moment(values?.DateOfBirth).format("MM-DD-YYYY"),
            CurrentAddress: {
              Id: null,
              ...values,
              TimeInYears: values.TimeInYears.toString(),
              TimeInMonths: values.TimeInMonths.toString(),
            },
          },
          WorkflowStep: "2.4",
          CaseStatus: "Application in Progress",
          DynamicFieldDetail: [
            {
              FieldNo: 17,
              FieldValue: values?.UkVerification,
            },
          ],
        },
      })
        .then((responseData) => {
          if (
            responseData?.data?.d?.UpdateResult?.SuccessCode === 0 &&
            responseData?.data?.d?.UpdateResult?.Message === null
          ) {
            caseInfoDispatch({
              type: CASEINFO_ACTIONS_TYPES.ADD_CURRENT_ADDRESS,
              payload: {
                ...CurrentAddressFields,
                ...values,
                Id: null,
                TimeInYears: values.TimeInYears.toString(),
                TimeInMonths: values.TimeInMonths.toString(),
              },
            });
            return Number(values.TimeInYears) * 12 +
              Number(values.TimeInMonths) <
              36
              ? navigate("/previous-address" + additionalUrlParams(), {
                  state: { from: location.pathname },
                })
              : navigate("/employment" + additionalUrlParams(), {
                  state: { from: location.pathname },
                });
          } else {
            setToastMessage(
              responseData?.data?.d?.UpdateResult?.Message ||
                "Something went wrong"
            );
          }
        })
        .catch((err) =>
          setToastMessage(
            err?.data?.d?.UpdateResult?.Message ||
              err?.message ||
              "Something went wrong"
          )
        );
    } else
      return Number(values.TimeInYears) * 12 + Number(values.TimeInMonths) < 36
        ? navigate("/previous-address" + additionalUrlParams(), {
            state: { from: location.pathname },
          })
        : navigate("/employment" + additionalUrlParams(), {
            state: { from: location.pathname },
          });
  };
  const forcedUpdateContextValues = (e: any) => {
    let value = e.target.value;

    caseInfoDispatch({
      type: CASEINFO_ACTIONS_TYPES.ADD_CURRENT_ADDRESS,
      payload: {
        ...CurrentAddressFields,
        DateOfBirth: dateRef.current,
        [e.target.name]: value,
      },
    });
  };

  const textFields = [
    {
      fieldName: "DateOfBirth",
      defaultValue: DateOfBirth,
      label: "Date of Birth",
      type: "date",
      customValidation: getYup
        .string()
        .required("This field is required")
        .test("dob", "You need to be at least 18 years old to apply", (value) =>
          value ? differenceInYears(new Date(), new Date(value)) >= 18 : false
        ),
      // ref: dateRef,
      customChangeHandler: (e: any) => (dateRef.current = e.target.value),
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Please enter your date of birth
          </Typography>
        </>
      ),
    },
    {
      fieldName: "UkVerification",
      defaultValue: UkVerification,
      label: "Yes/No",
      type: "select",
      options: [
        {
          value: "Yes",
          title: "Yes",
        },
        {
          value: "No",
          title: "No",
        },
      ],
      customChangeHandler: (e: any) => forcedUpdateContextValues(e),
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Are you a UK resident?
          </Typography>
        </>
      ),
      customValidation: getYup.string().required("This field is required"),
    },
    {
      fieldName: "PostCode",
      defaultValue: PostCode,
      label: "Postcode",
      type: "autocomplete",
      onOptionClick: (values: any) => {
        if (values)
          caseInfoDispatch({
            type: CASEINFO_ACTIONS_TYPES.ADD_CURRENT_ADDRESS,
            payload: {
              ...CurrentAddressFields,
              PostCode: values?.PostCode,
              HouseNumber: values?.HouseNumber,
              HouseName: values?.HouseName,
              Address1: values?.Address1,
              Address2: values?.Address2,
              Address3: values?.Address3,
              Address4: values?.Address4,
              DateOfBirth: dateRef.current,
              UkVerification: CurrentAddressFields?.UkVerification,
            },
          });
      },
      fetchOptions: async (input: any) => {
        try {
          if (input) {
            const response = await getPostcodeLookup({ PostCode: input });
            if (response?.data?.d?.EnquiryResult?.SuccessCode === 0) {
              return response?.data?.d?.Records;
            }
          }
        } catch (error) {
          console.log("Error", error);
        }
      },
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Please enter your current address
          </Typography>
        </>
      ),
    },
    {
      fieldName: "HouseNumber",
      defaultValue: HouseNumber,
      label: "House Number",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "HouseName",
      defaultValue: HouseName,
      label: "House Name",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address1",
      defaultValue: Address1,
      label: "Address 1",
      type: "text",
    },
    {
      fieldName: "Address2",
      defaultValue: Address2,
      label: "Address 2",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address3",
      defaultValue: Address3,
      label: "Address 3",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "Address4",
      defaultValue: Address4,
      label: "Address 4",
      type: "text",
      notValidate: true,
    },
    {
      fieldName: "TimeInYears",
      defaultValue: Number(TimeInYears),
      label: "Years at Address",
      type: "number",
      customValidation: getYup.number().required(`This field is required`),
      customChangeHandler: (e: any) => {
        if (e.target.value > 0) {
          setMinMonthAtAddress(0);
        } else {
          setMinMonthAtAddress(1);
        }
      },
      additionalElement: (
        <>
          <Typography className="!w-5/6" sx={{ fontSize: "12px", mb: 2 }}>
            Please enter your time at address
          </Typography>
        </>
      ),
    },
    {
      fieldName: "TimeInMonths",
      defaultValue: Number(TimeInMonths),
      label: "Months at Address",
      type: "number",
      customValidation: getYup
        .number()
        .min(
          minMonthAtAddress,
          minMonthAtAddress === 0
            ? "Time at address must have 0 or greater than that month."
            : "Please enter a minimum of one month for time at address."
        )
        .max(11, "Time at address must have 11 or less than that month.")
        .required(`This field is required`),
      notValidate: TimeInMonths && Number(TimeInMonths) === 0 ? true : false,
    },
  ];

  const { isLoading } = useFetchingInitialData();

    React.useEffect(() => {
        getUrlParam('a');
    }, []);


    React.useEffect(() => {
        console.log(caseInfoState);

        // parse DoB
        let _DoB = "";
        if(caseInfoState?.CustomerDetails?.DateOfBirth) {
            let unix_timestamp = caseInfoState?.CustomerDetails?.DateOfBirth.replace('/Date(', '').replace(')/', '');
            let m = moment.unix(unix_timestamp / 1000);
            _DoB = m.format('MM-DD-YYYY');
        }

        setDateOfBirth(_DoB || "");
        setUkVerification(caseInfoState?.CaseList?.[0]?.DynamicFieldDetail?.UkVerification || "");
        setPostCode(caseInfoState?.CustomerDetails?.CustomerAddress?.PostCode || "");
        setHouseNumber(caseInfoState?.CustomerDetails?.CustomerAddress?.HouseNumber || "");
        setHouseName(caseInfoState?.CustomerDetails?.CustomerAddress?.HouseName || "");
        setAddress1(caseInfoState?.CustomerDetails?.CustomerAddress?.Address1 || "");
        setAddress2(caseInfoState?.CustomerDetails?.CustomerAddress?.Address2 || "");
        setAddress3(caseInfoState?.CustomerDetails?.CustomerAddress?.Address3 || "");
        setAddress4(caseInfoState?.CustomerDetails?.CustomerAddress?.Address4 || "");
        setTimeInYears(caseInfoState?.CaseList?.[0]?.MainApplicant?.CurrentAddress?.TimeInYears || "");
        setTimeInMonths(caseInfoState?.CaseList?.[0]?.MainApplicant?.CurrentAddress?.TimeInMonths || "");
    }, [caseInfoState]);

  return (
    <ImageLayoutContainer title="Personal Details" noPadding>
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
        additionalText={
          <>
            {/* <Typography sx={{ fontSize: "12px", mb: 2 }}>
              Please provide your personal
              details.
            </Typography> */}
          </>
        }
      />
    </ImageLayoutContainer>
  );
};

export default CurrentAddress;
