import React, { FocusEvent, useCallback } from "react";
import FormComponent, {
  fieldStatuses,
  getYup,
} from "../../components/Forms/FormComponent";
import { useNavigate } from "react-router-dom";
import CenterLayoutContainer from "../../components/CenterLayoutContainer/CenterLayoutContainer";
import { updateCustomer, verifyEmail, verifyMobile } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import {
  CASEINFO_ACTIONS_TYPES,
  useCaseInfoContext,
  useUpdateCaseInfoContext,
} from "../../context/CaseContext/CaseContext";
import { getNewAccessToken, updateDetails } from "../../api/oauth";
import IsEmptyOrValueUpdated from "../../utils";
import {
  AUTH_ACTIONS,
  useUpdateAuthContext,
} from "../../context/AuthContext/AuthContext";
import { FormikHelpers } from "formik";
import useFetchingInitialData from "../../hooks/useFetchingInitialData";

const UpdateDetails = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = React.useState("");
  const [isMobileNoVerified, setIsMobileNoVerified] = React.useState(
    fieldStatuses.empty
  );
  const [isEmailVerified, setIsEmailVerified] = React.useState(
    fieldStatuses.empty
  );

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const { CustomerDetails = {} } = useCaseInfoContext();
  const authDispatch = useUpdateAuthContext();
  const caseInfoDispatch = useUpdateCaseInfoContext();
  const { isLoading } = useFetchingInitialData();

  const getToken = useCallback(() => {
    const userData = JSON.parse(localStorage?.getItem("uAuth") || "{}");
    getNewAccessToken(userData?.refreshToken)
      .then((response) => {
        if (response?.data?.accessToken) {
          authDispatch({
            type: AUTH_ACTIONS.GOTTOKEN,
            payload: response?.data,
          });
          navigate("/home");
          return setToastMessage("Your token was expired, please try again");
        }
      })
      .catch((err) => {
        authDispatch({ type: AUTH_ACTIONS.LOGOUT });
        caseInfoDispatch({ type: CASEINFO_ACTIONS_TYPES.REMOVE_DETAILS });
        setToastMessage(err?.message || "Something went wrong");
        return navigate("/login");
      });
  }, [authDispatch, caseInfoDispatch, navigate]);

  const emailORMobileUpdated = (
    values: any,
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => {
    const { setErrors } = formikHelpers;

    if (
      isEmailVerified === fieldStatuses.verified &&
      isMobileNoVerified === fieldStatuses.verified
    ) {
      if (values?.password && values?.password === values?.rePassword) {
        updateCustomer({
          CustomerReference: CustomerDetails?.CustomerReference || "C000005",
          Email: values?.email,
          Mobile: values?.mobile,
        })
          .then((response) => {
            if (response?.data?.d?.UpdateResult?.SuccessCode === 0) {
              updateDetails({
                email: values?.email,
                password: values?.password,
              })
                .then((innerResponse) => {
                  if (innerResponse?.data?.accessToken) {
                    authDispatch({
                      type: AUTH_ACTIONS.GOTUPDATEDDETAILS,
                      payload: innerResponse?.data,
                    });
                    navigate("/home");
                  } else {
                    setToastMessage(
                      innerResponse?.data?.message || "Something went wrong"
                    );
                  }
                })
                .catch((err) => {
                  if (err?.message === "Token is expired.") {
                    getToken();
                    return;
                  }
                  return setToastMessage(
                    err?.data?.message || err?.message || "Something went wrong"
                  );
                });
            } else {
              setToastMessage(
                response?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      } else {
        updateCustomer({
          CustomerReference: CustomerDetails?.CustomerReference || "C000005",
          Email: values?.email,
          Mobile: values?.mobile,
        })
          .then((response) => {
            if (response?.data?.d?.UpdateResult?.SuccessCode === 0) {
              updateDetails({
                email: values?.email,
              })
                .then((innerResponse) => {
                  if (innerResponse?.data?.accessToken) {
                    authDispatch({
                      type: AUTH_ACTIONS.GOTUPDATEDDETAILS,
                      payload: innerResponse?.data,
                    });
                    navigate("/home");
                  } else {
                    setToastMessage(
                      innerResponse?.data?.message || "Something went wrong"
                    );
                  }
                })
                .catch((err) => {
                  if (err?.message === "Token is expired.") {
                    getToken();
                    return;
                  }
                  return setToastMessage(
                    err?.data?.message || err?.message || "Something went wrong"
                  );
                });
            } else {
              setToastMessage(
                response?.data?.d?.UpdateResult?.Message ||
                  "Something went wrong"
              );
            }
          })
          .catch((err) =>
            setToastMessage(
              err?.data?.d?.UpdateResult?.Message ||
                err?.message ||
                "Something went wrong"
            )
          );
      }
    } else {
      if (
        isEmailVerified !== fieldStatuses.verified &&
        isMobileNoVerified !== fieldStatuses.verified
      ) {
        setErrors({
          email:
            isEmailVerified === fieldStatuses.notVerified
              ? "Provided email is invalid"
              : "Please verify your email",
          mobile:
            isMobileNoVerified === fieldStatuses.notVerified
              ? "Provided mobile number is invalid"
              : "Please verify your mobile number",
        });
      } else if (isMobileNoVerified !== fieldStatuses.verified) {
        setErrors({
          mobile:
            isMobileNoVerified === fieldStatuses.notVerified
              ? "Provided mobile number is invalid"
              : "Please verify your mobile number",
        });
      } else if (isEmailVerified !== fieldStatuses.verified) {
        setErrors({
          email:
            isEmailVerified === fieldStatuses.notVerified
              ? "Provided email is invalid"
              : "Please verify your email",
        });
      }
      return;
    }
  };

  const emailORMobileNotUpdated = (values: any) => {
    if (values?.password && values?.password === values?.rePassword) {
      updateDetails({
        password: values?.password,
      })
        .then((innerResponse) => {
          if (innerResponse?.data?.accessToken) {
            authDispatch({
              type: AUTH_ACTIONS.GOTUPDATEDDETAILS,
              payload: innerResponse?.data,
            });
            navigate("/home");
          } else {
            setToastMessage(
              innerResponse?.data?.message || "Something went wrong"
            );
          }
        })
        .catch((err) => {
          if (err?.message === "Token is expired.") {
            getToken();
            return;
          }
          return setToastMessage(
            err?.data?.message || err?.message || "Something went wrong"
          );
        });
    } else if (values?.password && values?.password !== values?.rePassword) {
      setToastMessage("Both password should be same");
    }
  };
  const textFields = [
      {
        fieldName: "mobile",
        label: "Mobile Number",
        defaultValue: CustomerDetails?.Mobile,
        type: "string",
        customValidation: getYup
          .string()
          .required("This field is required")
          .matches(phoneRegExp, "Please enter a valid mobile phone number"),
        handleBlur: (e: FocusEvent<HTMLInputElement>) => {
          console.log(e.target.value);
          verifyMobile({ value: e.target.value.toString() })
            .then((response) => {
              if (
                response?.data?.d?.EnquiryResult?.SuccessCode === 0 &&
                response?.data?.d?.Records?.[0]?.IsValid
              ) {
                setIsMobileNoVerified(fieldStatuses.verified);
              } else setIsMobileNoVerified(fieldStatuses.notVerified);
            })
            .catch((err) =>
              setToastMessage(err?.data?.d?.EnquiryResult?.Message)
            );
        },
        isVerified: isMobileNoVerified,
      },
      {
        fieldName: "email",
        label: "Email",
        defaultValue: CustomerDetails?.Email,
        type: "text",
        handleBlur: (e: FocusEvent<HTMLInputElement>) => {
          console.log(e.target.value);
          verifyEmail({ value: e.target.value })
            .then((response) => {
              if (
                response?.data?.d?.EnquiryResult?.SuccessCode === 0 &&
                response?.data?.d?.Records?.[0]?.IsValid
              ) {
                setIsEmailVerified(fieldStatuses.verified);
              } else setIsEmailVerified(fieldStatuses.notVerified);
            })
            .catch((err) =>
              setToastMessage(err?.data?.d?.EnquiryResult?.Message)
            );
        },
        isVerified: isEmailVerified,
      },
      {
        fieldName: "password",
        label: "Password",
        type: "password",
        customValidation: getYup
          .string()
          .min(8, "password should be more than 8 characters"),
      },
      {
        fieldName: "rePassword",
        label: "Confirm Password",
        type: "password",
        customValidation: getYup
          .string()
          .oneOf([getYup.ref("password"), null], "Passwords must match"),
      },
    ],
    handleSubmit = (
      values: any,
      formikHelpers: FormikHelpers<{ [x: string]: string }>
    ) => {
      if (
        IsEmptyOrValueUpdated(
          { email: CustomerDetails?.Email, mobile: CustomerDetails?.Telephone },
          { email: values?.email, mobile: values?.mobile }
        )
      ) {
        emailORMobileUpdated(values, formikHelpers);
      } else {
        emailORMobileNotUpdated(values);
      }
    };
  return (
    <CenterLayoutContainer
      pageTitle={
        <>
          UPDATE YOUR
          <br />
          DETAILS
        </>
      }
      alignItems="center"
      goBackRoute="/home"
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
        wantCenterLayout
        submitBtnLabel="Update"
      />
    </CenterLayoutContainer>
  );
};

export default UpdateDetails;
