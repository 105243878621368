import React from "react";
import { getADPData } from "../api/crm";

function useADPData(ApplicantID = "") {
  const [ADPData, setADPData] = React.useState<any>();
  const [isLoading, setisLoading] = React.useState(false);
  const [lastFetched, setLastFetched] = React.useState("");

  const fetchADPData = React.useCallback(() => {
    if (isLoading || lastFetched === ApplicantID) return;
    setisLoading(true);
    setLastFetched(ApplicantID);
    return (
      ApplicantID &&
      getADPData({
        CaseReference: ApplicantID,
      }).then((responseData) => {
        if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          const ADPData = responseData?.data?.d?.ADPDataList.reduce(
            (acc: object, ADPDataListItem: any) => ({
              ...acc,
              [ADPDataListItem.APICall]: ADPDataListItem.ADPRecords,
            }),
            {}
          );
          setADPData(ADPData);
          setisLoading(false);
        }
      })
    );
  }, [ApplicantID, isLoading, lastFetched]);

  React.useEffect(() => {
    fetchADPData();
  }, [fetchADPData]);

  const getADPDataValueByID = (APICall = "", ID = "") => {
    const value =
      ADPData?.[APICall]?.find((element: any) => element.ID === ID)?.Value ||
      "";
    return value;
  };

  const getADPDataValueByDescription = (APICall = "", Description = "") => {
    const value =
      ADPData?.[APICall]?.find(
        (element: any) => element.Description === Description
      )?.Value || "";
    return value;
  };

  return {
    isLoading,
    ADPData,
    getADPDataValueByID,
    getADPDataValueByDescription,
  };
}

export default useADPData;
