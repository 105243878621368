import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {additionalUrlParams, getUrlParam} from "../../utils";
import { SmallWidthColumnContainer } from "../../components/Containers";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
import { updateCase } from "../../api/crm";
import ToastMessage from "../../components/ToastMessage/ToastMessage";

const OpenBanking = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {} } = useCaseInfoContext();
  const [toastMessage, setToastMessage] = React.useState("");
  const handleSubmit = (values: any) => {

    if(!CustomerDetails.ApplicantID && getUrlParam('ApplicantID')) {
        CustomerDetails.ApplicantID = getUrlParam('ApplicantID');
        CustomerDetails.CustomerReference = getUrlParam('CustomerReference');
    }

    if (CustomerDetails?.ApplicantID)
      updateCase({
        ApplicantID: CustomerDetails?.ApplicantID,
        CustomerReference: CustomerDetails?.CustomerReference,
        Payload: {
          WorkflowStep: "3",
          CaseStatus: "Pending Decision",
        },
      })
        .then((responseData) => {
          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0)
            return navigate("/open-banking2" + additionalUrlParams());
        })
        .catch((err) => {
          setToastMessage(
            err?.data?.d?.EnquiryResult?.Message ||
              err?.message ||
              "Something went wrong"
          );
        });
  };
  return (
    <ImageLayoutContainer
      noPadding
      title="OPEN BANKING"
      handleClick={handleSubmit}
    >
      <ToastMessage message={toastMessage} setMessage={setToastMessage} />
      <SmallWidthColumnContainer flex="1" justifyContent="flex-start">
        <Typography className="!mt-6 !text-xs !w-3/4">
          Please authorise open banking connection to your primary current
          account.
        </Typography>
        <Typography className="!mt-4 !text-xs !w-5/6">
          This is the account into which you typically get paid and your
          principle bills are paid out of.
        </Typography>
        <Typography className="!mt-4 !text-xs !w-5/6">
          Open Banking regulations require connections to be authorised every 90
          days.
        </Typography>
        <Typography className="!mt-4 !text-xs !w-5/6">
          Continuing will take you to your provider to complete authorisation.
        </Typography>
      </SmallWidthColumnContainer>
    </ImageLayoutContainer>
  );
};

export default OpenBanking;
