import React from "react";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CCLogo from "../../assets/images/CCLogo.png";
import image from "../../assets/images/Bubbles.svg";
import Stack from "@mui/material/Stack";

type HeaderProps = {
  setSidebarState: Function;
};

const Header = (props: HeaderProps) => {
  const { setSidebarState } = props;
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: { sm: "10px 50px 10px 50px", xs: "10px 10px" } }}
    >
      <IconButton
        onClick={() => setSidebarState((prevState: any) => !prevState)}
      >
        <MenuIcon
          className="justify-self-start self-center"
          sx={{ fontSize: { sm: "55.08px", xs: "40px" } }}
          color="primary"
        />
      </IconButton>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        flex="1"
        sx={{
          width: { sm: "124px", xs: "100px" },
          height: { sm: "124px", xs: "100px" },
        }}
      >
        {" "}
        <Box
          component="img"
          src={CCLogo}
          className="justify-self-center self-center"
          sx={{
            width: { sm: "124px", xs: "100px" },
            height: { sm: "124px", xs: "100px" },
          }}
        />
      </Stack>
      <IconButton sx={{ visibility: "hidden" }}>
        <MenuIcon
          className="justify-self-start self-center"
          sx={{ fontSize: { sm: "55.08px", xs: "40px" } }}
          color="primary"
        />
      </IconButton>
      <img
        src={image}
        alt="bubbles"
        style={{ position: "absolute", top: "0px", right: "0px", zIndex: -1 }}
      />
    </Stack>
  );
};

export default Header;
